import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Tab, Tabs, Dropdown } from "react-bootstrap";
import { openIframesScreens } from "~/containers/SidebarContainer/util";
import { useSelector } from "react-redux";

export default function NavTabs({
  titleMenu,
  menus,
  activeSelected,
  containMenu,
  setIsLoading,
  t,
}) {
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const history = useHistory();
  const [tabKey, initTabKey] = useState(titleMenu);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 && configuration && configuration.mobileReady);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768 && configuration && configuration.mobileReady);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClickTab = (e) => {
    console.log("e", e, isMobile);
    setIsLoading(true);
    var iframe = document.getElementById("portalrh");
    var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    var elements = iframeDoc.getElementsByTagName("li");
    for (var i = 0; i < elements.length; i++) {
      var value = elements[i].firstElementChild.attributes[1].value;
      if (value.includes(e)) {
        elements[i].firstElementChild.click();
      } else {
        const href = elements[i].firstElementChild.getAttribute("href");
        const link = iframeDoc.querySelector('a[href="' + href + '"]');
        if (href.includes(e)) {
          !isMobile && history.goBack();
          setTimeout(() => {
            link.click();
            !isMobile && openIframesScreens();
          }, 400);
          setIsLoading(false);
          return;
        }
      }
    }
    setTimeout(() => {
      !isMobile && openIframesScreens();
      setIsLoading(false);
    }, 400);
    !isMobile && history.goBack();
  };

  return (
    <div className={`navbarTabs ${isMobile && containMenu && Object.keys(menus).length > 1 ? "mobile" : ""}`}>
      <div className="nav title-menuTabs">
        {isMobile && containMenu && Object.keys(menus).length > 1 ? (
          <div className="dropdown-container">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {t(`${tabKey}`)}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdownMenuNavTabsMobile">
                {Object.keys(menus).map((key) => (
                  <Dropdown.Item
                    key={menus[key][0].id}
                    eventKey={menus[key][0].accao}
                    onClick={() => {
                      handleClickTab(menus[key][0].accao);
                      initTabKey(menus[key][0].accao);
                    }}
                  >
                    {t(`portalrh.NavBar.${menus[key][0].designacao}`)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <>
            {containMenu && Object.keys(menus).length > 0 ? (
              <>
                {Object.keys(menus).map((key) => (
                  <Tabs
                    key={menus[key][0].id}
                    defaultActiveKey={activeSelected}
                    onSelect={(e) => {
                      handleClickTab(e);
                      initTabKey(e);
                    }}
                  >
                    <Tab
                      key={menus[key][0].id}
                      eventKey={menus[key][0].accao}
                      title={t(`portalrh.NavBar.${menus[key][0].designacao}`)}
                    />
                  </Tabs>
                ))}{" "}
              </>
            ) : (
              <Tabs activeKey={tabKey} onSelect={(e) => initTabKey(e)}>
                <Tab eventKey={tabKey} title={titleMenu} />
              </Tabs>
            )}
          </>
        )}
      </div>
    </div>
  );
}