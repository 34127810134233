export const getPeriodicidadeListDropdown = (t) => {
  return [
    {
      label: t("portalrh.emailTemplate.dataTemplate.none"),
      value: "0",
      selected: false,
    },
    {
      label: t("portalrh.emailTemplate.dataTemplate.mensal"),
      value: "1",
      selected: false,
    },
    {
      label: t("portalrh.emailTemplate.dataTemplate.anual"),
      value: "2",
      selected: false,
    },
  ];
};

export const getRegraListDropdown = (t) => {
  return [
    {
      label: t("portalrh.emailTemplate.dataTemplate.notSend"),
      value: "0",
      selected: false,
    },
    {
      label: t("portalrh.emailTemplate.dataTemplate.onFlow"),
      value: "1",
      selected: false,
    },
  ];
};
