import React from "react";
import { withNamespaces } from "react-i18next";

const MobileCard = ({
  row,
  buildProcessadoColumn,
  cellContent,
  rowIndex,
  t,
}) => {
  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString() : "----";
  };

  const formatTime = (time) => {
    return time
      ? new Date(time).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
      : "----";
  };

  return (
    <div
      className="card_container"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem",
        marginBottom: "1rem",
        backgroundColor: "whitesmoke",
        borderRadius: "12px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Left Column */}
      <div style={{ flex: 1 }}>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#333",
            marginBottom: "0.5rem",
          }}
        >
          {row.entidade || "----"}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "bold", color: "#333" }}>
          {t("portalrh.horasExtra.dataEfectivacao")}:{" "}
          {formatDate(row.dataEfectivacao)}
        </div>
        <div style={{ fontSize: "12px", fontWeight: "bold", color: "#333" }}>
          {t("portalrh.horasExtra.dataCriacao")}: {formatDate(row.dtCri)}
        </div>
        <div style={{ fontSize: "12px", color: "#888" }}>
          {formatTime(row.hrInicio)} | {formatTime(row.hrFim)}
        </div>
      </div>

      {/* Middle Column
      <div style={{ flex: 1, textAlign: "center" }}>
        <div style={{ fontSize: "12px", color: "#888" }}>
          Primeira Hora: {row.horasA || "----"}
        </div>
        <div style={{ fontSize: "12px", color: "#888" }}>
          Segunda Hora e Seguintes: {row.horasB || "----"}
        </div>
        <div style={{ fontSize: "12px", color: "#888" }}>
          Descanso Complementar: {row.horasC || "----"}
        </div>
        <div style={{ fontSize: "12px", color: "#888" }}>
          Descanso Obrigatório: {row.horasD || "----"}
        </div>
        <div style={{ fontSize: "12px", color: "#888" }}>
          Descanso Feriado: {row.horasD2 || "----"}
        </div>
        <div style={{ fontSize: "12px", color: "#888" }}>
          Primeira Hora Nocturna: {row.horasE || "----"}
        </div>
        <div style={{ fontSize: "12px", color: "#888" }}>
          Horas Nocturnas Seguintes: {row.horasE2 || "----"}
        </div>
        <div style={{ fontSize: "12px", color: "#888" }}>
          H. Extras Além Limite: {row.horasF || "----"}
        </div>
      </div> */}

      {/* Right Column */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexShrink: 0,
          gap: "0.5rem",
        }}
      >
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#f5a623",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {buildProcessadoColumn(cellContent, row, rowIndex)}
        </div>
        <div style={{ fontSize: "12px", color: "#888" }}>
          {row.mesProcessamento || "----"}
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(MobileCard);
