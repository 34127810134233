import http from "~/utils/config/http";
import { put } from "redux-saga/effects";
import { setPortalRhMenu, setAllPortalRhMenus } from "./actionTypes";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";

export function* findPortalRhMenu({ isMobile }) {
  try {
    const resp = yield http.get(`api/menu/find-by-user`, PORTALRH_MIDDLEWARE);
    let filteredData = resp.data;

    // if (isMobile) {
    //   filteredData = resp.data.filter(
    //     (item) => item.externo === 1 || item.accao === null || item.accao === "" 
    //   );
    // }
    yield put(setPortalRhMenu(filteredData, isMobile));
    yield put(setAllPortalRhMenus(filteredData));
  } catch (e) {
    yield put(setPortalRhMenu([]));
    yield put(setAllPortalRhMenus([]));
  }
}
