import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Card, Form, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/icons.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { pagination } from "~/components/pagination/currentSizePerPage/5";
import Dropdown from "~/components/Dropdown";
import InputDate from "~/pages/Easypay/components/InputDate";
import { useForm } from "react-hook-form";
import * as luxon from "luxon";
import uuid from "uuid/v1";
import moment from "moment";
import {
  getGroupUsers,
  getTipoAusencias,
  getRegistoFaltas,
  exportTxt,
  getTipoAusenciasIDONIC,
} from "~/store/ducks/portalrh/actionTypes";
import { list } from "~/pages/Easypay/testData";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
const devLogConsole = require("~/utils/devLog");
const NewSearchTable = ({ t, manager }) => {
  const [currentDataInicio, setCurrentDataInicio] = useState("");
  const [currentDataFim, setCurrentDataFim] = useState("");
  const [exportRegistoFalta, setExportRegistoFalta] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});
  const [listToExport, setListToExport] = useState([]);
  const [absenceValueSelected, setAbsenceValueSelected] = useState("");
  const [usersListSelected, setusersListSelected] = useState([]);
  const [tipoSelected, settipoSelected] = useState("");
  const [validated, setValidated] = useState(false);
  const [validatedDropdowns, setValidatedDropdowns] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [dropdownInvalid, setDropdownInvalid] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTipoAusencias());
    const typeData = "AT"
    dispatch(getGroupUsers(typeData));
    //dispatch(getTipoAusenciasIDONIC());
  }, []);

  useEffect(() => {}, [tipoAusencias]);

  useEffect(() => {}, [groupUsers?.lenght]);

  const {
    absenceLogs,
    tipoAusencias,
    groupUsers,
    registoFaltas,
    tipoAusenciasIDONIC,
  } = useSelector((state) => state.portalrhReducer);

  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(registoFaltas);
  }, [registoFaltas]);

  function dataFormatter(cell) {
    let aux = cell;
    if (cell !== null) {
      aux = cell?.split("T")[0];
    }
    return <div>{aux}</div>;
  }
  const formatDate = (cell, row, rowIndex, formatExtraData) => {
    if (cell) {
      const date = moment(cell, "YYYY-MM-DD HH:mm:ss.S").format("DD-MM-YYYY");

      return date;
    }
  };

  const handleCheckboxChange = (e, row, formatExtraData) => {
    setCheckboxes({
      ...checkboxes,
      [row.id]: e.target.checked,
    });
  };

  const handleAllCheckboxesChange = (event) => {
    setSelectAll(event.target.checked);
    setCheckboxes(
      currentList.reduce((result, row) => {
        result[row.id] = event.target.checked;
        return result;
      }, {})
    );
  };

  useEffect(() => {
    let trueKeys = [];

    if (Object.keys(checkboxes).length > 0) {
      for (const key in checkboxes) {
        if (checkboxes[key]) {
          trueKeys.push(Number(key));
        } else if (!checkboxes[key]) {
          trueKeys = trueKeys.filter((e) => e !== false);
        }
      }
    }

    if (currentList.length > 0 && trueKeys.length > 0) {
      let newListToExport = [];
      trueKeys.forEach((key) => {
        currentList.forEach((row) => {
          devLogConsole(+row.id, key);
          if (key === +row.id) {
            newListToExport.push(row);
          }
        });
      });
      setListToExport(newListToExport);
    }
  }, [checkboxes, currentList]);

  const checkToExport = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Form.Check
        type="checkbox"
        label=""
        id={rowIndex}
        checked={formatExtraData.value[row.id] || false}
        onChange={(e) => handleCheckboxChange(e, row, formatExtraData)}
      />
    );
  };
  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "lepNum",
      text: t("portalrh.abscenses.assLepNum"),
      sort: true,
      headerStyle: { width: "fit-content", textAlign: "center" },
      style: { textAlign: "center" },
    },
    {
      dataField: "nome",
      text: t("portalrh.abscenses.pssNome"),
      sort: false,
      headerStyle: { width: "fit-content", textAlign: "center" },
      style: { textAlign: "center" },
    },
    {
      dataField: "tipo",
      text: t("portalrh.abscenses.tipo"),
      sort: false,
      headerStyle: { width: "fit-content", textAlign: "center" },
      style: { textAlign: "center" },
    },
    {
      dataField: "cvnCode",
      text: t("portalrh.abscenses.cvnCode"),
      sort: false,
      headerStyle: { width: "fit-content", textAlign: "center" },
      style: { textAlign: "center" },
    },

    {
      dataField: "cvnDesignacao",
      text: t("portalrh.abscenses.cvnDesignacao"),
      sort: true,
      headerStyle: { width: "fit-content", textAlign: "center" },
      style: { textAlign: "center" },
    },
    {
      dataField: "dtInicio",
      text: t("portalrh.abscenses.hrInicio"),
      sort: false,
      headerStyle: { width: "fit-content", textAlign: "center" },
      formatter: formatDate,
      style: { textAlign: "center" },
    },
    {
      dataField: "dtFim",
      text: t("portalrh.abscenses.hrFim"),
      sort: false,
      headerStyle: { width: "fit-content", textAlign: "center" },
      formatter: formatDate,
      style: { textAlign: "center" },
    },
    {
      dataField: "numDias",
      text: t("portalrh.abscenses.numDias"),
      sort: false,
      headerStyle: { width: "fit-content", textAlign: "center" },
      style: { textAlign: "center" },
    },
    // {
    //   dataField: "horaInicio",
    //   text: "Hora início",
    //   sort: true,
    //   headerStyle: { width: "fit-content" },
    // },
    // {
    //   dataField: "horaFim",
    //   text: "Hora fim",
    //   sort: false,
    //   headerStyle: { width: "fit-content" },
    // },
    {
      dataField: "numHoras",
      text: t("portalrh.abscenses.numDiasDesc"),
      sort: false,
      headerStyle: { width: "fit-content", textAlign: "center" },
      style: { textAlign: "center" },
    },
    {
      dataField: "exportar",
      text: t("portalrh.abscenses.exportar"),
      sort: false,
      headerStyle: { width: "fit-content", textAlign: "center" },
      formatter: checkToExport,
      formatExtraData: {
        value: checkboxes,
      },
      style: { textAlign: "center" },
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return (
          <div
            style={{
              display: "inline-flex",
              flexDirection: "row-reverse",
              gap: "0.6rem",
            }}
          >
            <Form.Check
              type="checkbox"
              label=""
              id="selectAll"
              checked={selectAll}
              onChange={handleAllCheckboxesChange}
              disabled={currentList.length === 0}
            />
            {column.text}
            {sortElement}
            {filterElement}
          </div>
        );
      },
    },
  ];

  function buildTipoAusenciaList() {
    var newArray = [];
    for (var i = 0; i < tipoAusencias?.length; i++) {
      newArray.push({
        label: tipoAusencias[i].cvnDesignacao,
        value: tipoAusencias[i].cvnDesignacao,
        selected: false,
      });
    }

    return newArray;
  }

  function buildGrouUserList() {
    var newArray = [];
    newArray.push({
      label: t("portalrh.abscenses.all"),
      value: t("portalrh.abscenses.all"),
      selected: false,
    });
    for (var i = 0; i < groupUsers?.length; i++) {
      newArray.push({
        label: groupUsers[i]?.grupo?.designacao,
        value: groupUsers[i]?.grupo?.designacao,
        selected: false,
      });
    }

    return newArray;
  }

  function buildTipoAusenciaIDONICList() {
    var newArray = [];
    for (var i = 0; i < tipoAusenciasIDONIC?.length; i++) {
      newArray.push({
        label: tipoAusenciasIDONIC[i]?.descricao,
        value: tipoAusenciasIDONIC[i]?.codigo,
        selected: false,
      });
    }
    return newArray;
  }

  const tipoList = [
    { label: t("portalrh.abscenses.faltas"), value: "Faltas", selected: false },
    { label: t("portalrh.abscenses.ferias"), value: "Férias", selected: false },
    {
      label: t("portalrh.abscenses.hExtra"),
      value: "HorasExtra",
      selected: false,
    },
    {
      label: t("portalrh.abscenses.bHoras"),
      value: "BolsaHoras",
      selected: false,
    },
  ];

  function handleDropdownSelect(name, submitValue) {
    setCurrentList([]);
    setSelectAll(false);
    setCheckboxes({})
    if (name === "grupo") {
      setField("grupo", submitValue);
      let newArray = [];
      if (submitValue === t("portalrh.abscenses.all")) {
        groupUsers.forEach((groupUser) => {
          const userNumFuncionarioList =
            groupUser?.userList?.map((user) => user?.numFuncionario) || [];
          newArray.push(...userNumFuncionarioList);
        });
      } else {
        for (let i = 0; i < groupUsers.length; i++) {
          if (submitValue === groupUsers[i]?.grupo?.designacao) {
            const userNumFuncionarioList =
              groupUsers[i]?.userList?.map((user) => user?.numFuncionario) ||
              [];
            newArray.push(...userNumFuncionarioList);
          }
        }
      }
      setusersListSelected(newArray);
    }
    if (name === "tipoAusencia") {
      setAbsenceValueSelected(submitValue);
      setField("tipoAusencia", submitValue);
    }
    if (name === "tipo") {
      settipoSelected(submitValue);
      setField("tipo", submitValue);
    }
  }

  function searchRegistoFaltas() {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setDropdownInvalid(true);
    } else {
      setDropdownInvalid(false);
      dispatch(
        getRegistoFaltas({
          dateinicio: currentDataInicio,
          dateFim: currentDataFim,
          ids: usersListSelected,
          codigoIDONIC: absenceValueSelected,
          tipo: tipoSelected,
        })
      );
    }
  }

  function exportTxtFunction() {
    if (listToExport.length > 0) {
      listToExport.forEach((e) => {
        e.codigo = absenceValueSelected;
      });

      dispatch(exportTxt(listToExport));
    }
  }
  async function getCSS(url) {
    const response = await fetch(url);
    const css = await response.text();
    return css;
  }

  const printRegistries = async () => {
    // Cria um novo iframe
    const iframe = document.createElement("iframe");

    // Configura o iframe para ser invisível
    iframe.style.visibility = "hidden";
    iframe.style.position = "fixed";
    iframe.style.right = "0";
    iframe.style.bottom = "0";

    // Adiciona o iframe ao corpo do documento
    document.body.appendChild(iframe);

    // Obtém o conteúdo da tabela
    const exportTable = document.querySelector(
      "#document-table-exportabsence"
    ).outerHTML;

    // Obtém o CSS
    const bootstrapCSS = await getCSS(
      "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
    );

    // Escreve o conteúdo da tabela e o CSS no iframe
    iframe.contentDocument.write(`
      <html>
        <head>
          <title>Print table</title>
          <style>
            ${bootstrapCSS}
          </style>
        </head>
        <body>
          ${exportTable}
        </body>
      </html>
    `);
    iframe.contentDocument.close();

    // Imprime o conteúdo do iframe
    iframe.contentWindow.print();

    // Remove o iframe do corpo do documento após a impressão
    iframe.parentNode.removeChild(iframe);
  };

  function handleDateSubmit(dates) {
    setField("dates", dates);
    if (
      dates[0] !== null &&
      dates[0] !== undefined &&
      dates[1] !== null &&
      dates[1] !== undefined
    ) {
      const date1 = new Date(dates[0]);
      const date2 = new Date(dates[1]);
      const dia = date1.getDate().toString().padStart(2, "0");
      const mes = (date1.getMonth() + 1).toString().padStart(2, "0");
      const ano = date1.getFullYear();
      const dia2 = date2.getDate().toString().padStart(2, "0");
      const mes2 = (date2.getMonth() + 1).toString().padStart(2, "0");
      const ano2 = date2.getFullYear();

      const dataFormatadaInicio = `${dia}/${mes}/${ano}`;
      const dataFormatadaFim = `${dia2}/${mes2}/${ano2}`;

      setCurrentDataFim(dataFormatadaFim);
      setCurrentDataInicio(dataFormatadaInicio);
    }
  }

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const validateForm = () => {
    const { tipo, tipoAusencia, grupo, dates } = form;
    const newErrors = {};
    if (!tipo || tipo === "") {
      newErrors.tipo = t("portalrh.abscenses.validateForm.typeOpt");
    }

    // if (!tipoAusencia || tipoAusencia === "") {
    //   newErrors.tipoAusencia = "É necessário escolher uma opção";
    // }

    if (!grupo || grupo === "") {
      newErrors.grupo = t("portalrh.abscenses.validateForm.groupOpt");
    }

    if (!dates || dates.lenght === 0) {
      newErrors.dates = t("portalrh.abscenses.validateForm.datesOpt");
    }

    return newErrors;
  };

  const handleDropdownClassName = (varError, boolean) => {
    if (boolean) {
      return varError === "" || varError === undefined
        ? "dropdown-border-invalid"
        : "";
    }
  };
  return (
    <>
      <Form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "32px",
          }}
        >
          <Col lg="4">
            <Form.Group controlId="validationCustom01">
              <InputDate
                text={""}
                errorMessage={t(
                  "portalrh.abscenses.validateForm.requiredField"
                )}
                type="created_at"
                placeholder=""
                required
                handleSubmit={(dates) => handleDateSubmit(dates)}
              />
            </Form.Group>
            <div
              style={{
                paddingLeft: "20px",
                width: "100%",
                fontSize: "80%",
                color: "#dc3545",
              }}
            >
              {errors.dates}
            </div>
          </Col>
        </div>
        <div
          style={{
            display: "flex",
            paddingTop: "20px",
          }}
        >
          <Col lg="4">
            <Form.Label style={{ paddingLeft: "10px" }}>
              {t("portalrh.abscenses.tipo")}
            </Form.Label>
            <Dropdown
              style={{ borderColor: "red !important" }}
              placeholder={t("portalrh.abscenses.validateForm.requiredField")}
              emptyDefault={true}
              list={tipoList}
              // className={validateDropdowns(absenceValueSelected, true)}
              required={true}
              handleSubmit={(submitValue) =>
                handleDropdownSelect("tipo", submitValue)
              }
              // className="dropdown-margin dropdown-border-invalid"
            />
            <div
              style={{
                paddingLeft: "20px",
                width: "100%",
                fontSize: "80%",
                color: "#dc3545",
              }}
            >
              {errors.tipo}
            </div>
          </Col>
          {/* <Col lg="4">
            <Form.Label style={{ paddingLeft: "10px" }}>
              Tipo de Ausência
            </Form.Label>
            <Dropdown
              placeholder="Selecione a opção"
              emptyDefault={true}
              required={true}
              list={buildTipoAusenciaList()}
              handleSubmit={(submitValue) =>
                handleDropdownSelect("tipoAusencia", submitValue)
              }
            />
            <div
              style={{
                paddingLeft: "20px",
                width: "100%",
                fontSize: "80%",
                color: "#dc3545",
              }}
            >
              {errors.tipoAusencia}
            </div>
          </Col> */}
          <Col lg="4">
            <Form.Label style={{ paddingLeft: "10px" }}>
              {t("portalrh.abscenses.grupos")}
            </Form.Label>
            <Dropdown
              placeholder={t("portalrh.abscenses.validateForm.selectOpt")}
              emptyDefault={true}
              required={true}
              list={buildGrouUserList()}
              handleSubmit={(submitValue) =>
                handleDropdownSelect("grupo", submitValue)
              }
            />
            <div
              style={{
                paddingLeft: "20px",
                width: "100%",
                fontSize: "80%",
                color: "#dc3545",
              }}
            >
              {errors.grupo}
            </div>
          </Col>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "20px",
          }}
        >
          {/* <Col lg="4">
            <Form.Label style={{ paddingLeft: "10px" }}>Grupos</Form.Label>
            <Dropdown
              placeholder="Selecione a opção"
              emptyDefault={true}
              required={true}
              list={buildGrouUserList()}
              handleSubmit={(submitValue) =>
                handleDropdownSelect("grupo", submitValue)
              }
            />
            <div
              style={{
                paddingLeft: "20px",
                width: "100%",
                fontSize: "80%",
                color: "#dc3545",
              }}
            >
              {errors.grupo}
            </div>
          </Col> */}
        </div>

        <div className="custom-table withoutOverflow">
          <ToolkitProvider
            keyField="id"
            data={currentList}
            columns={columns}
            search={{ searchFormatted: true }}
          >
            {(props) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "0.5rem",
                      marginLeft: "10px",
                      display: "flex",
                      paddingTop: "50px",
                      paddingLeft: "5px",
                    }}
                  >
                    {true && (
                      <SearchBar
                        {...props.searchProps}
                        placeholder={t("general.search")}
                      />
                    )}
                    <div style={{ paddingLeft: "15px" }} />
                  </div>
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  id="document-table"
                  responsive
                  bootstrap4
                  pagination={paginationFactory(pagination)}
                  noDataIndication={t("datacapture.general.dataNotFound")}
                  //rowEvents={rowEvents}
                  pageSize={5}
                  hover
                  striped
                />
                <BootstrapTable
                  {...props.baseProps}
                  id="document-table-exportabsence"
                  responsive
                  bootstrap4
                  noDataIndication={t("datacapture.general.dataNotFound")}
                  hover
                  striped
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        {/* </div> */}
        <div
          style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
        >
          <div style={{ display: "flex" }}>
            <SimpleButton
              text={t("portalrh.abscenses.imprimir")}
              variant={"liftworld-button-primary"}
              onClick={() => printRegistries()}
            />
            <div style={{ paddingLeft: "15px" }}>
              <SimpleButton
                text={t("portalrh.abscenses.exportar")}
                variant={"liftworld-button-primary"}
                onClick={() => exportTxtFunction()}
              />
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <SimpleButton
            text={t("portalrh.abscenses.pesquisa")}
            variant={"liftworld-button-primary"}
            onClick={() => searchRegistoFaltas()}
          />
        </div>
      </Form>
    </>
  );
};
export default withNamespaces()(NewSearchTable);
