import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";
import { Modal, Button, Form, Col } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import DatePicker from "~/components/DatePicker";
import Alert from "@mui/material/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/pt-br";
import { ptBR, enUS, esES } from "@mui/x-date-pickers/locales";

const DateTemplate = (props) => {
  const {
    createDateMessageVariableButtons,
    setFocus,
    closeCreateModal,
    handleDropdownClassName,
    validated,
    subjectRef,
    messageRef,
    periodicidadeListDropdown,
    handlePeriodicidadeSelect,
    periodicidade,
    periodicidadeError,
    datasListDropdown,
    handleDatasSelect,
    datas,
    datasError,
    numberDays,
    setNumberDays,
    resetDropdownPeriodicidade,
    setResetDropdownPeriodicidade,
    dateOwnSubjectRef,
    dateOwnMessageRef,
    dateOthersSubjectRef,
    dateOthersMessageRef,
    subjectOwn,
    messageOwn,
    subjectOthers,
    messageOthers,
    setSubjectOwn,
    setMessageOwn,
    setSubjectOthers,
    setMessageOthers,
    dateForPeriodicidade,
    setDateForPeriodicidade,
    asterisk,
    selectedEmailTemplate,
    handleSubmit,
    toDelete = false,
    notificationNot,
    emailNotification,
    handleNotificationType,
    regraListDropdown,
    regraError,
    handleRegraSelect,
    resetDropdownRegra,
    setResetDropdownRegra,
    regra,
    t,
  } = props;

  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { errorMsgVariable } = useSelector((state) => state.udwReducer);

  const [localeTranslations, setlocaleTranslations] = useState("en");

  useEffect(() => {
    if (userSettings && userSettings.language != undefined) {
      if (userSettings.language.trim() == "pt") setlocaleTranslations("pt-br");
      if (userSettings.language.trim() == "es") setlocaleTranslations("es");
      if (userSettings.language.trim() == "en") setlocaleTranslations("en");
    }
  }, [userSettings]);

  const getViews = () => {
    if (periodicidade === "anual") {
      return ["day", "month"];
    } else if (periodicidade === "mensal") {
      return ["day"];
    }
    return ["day", "month", "year"];
  };

  const getLocaleText = () => {
    let lang = enUS;
    if (userSettings?.language.trim() === "pt") {
      lang = ptBR;
    }
    if (userSettings?.language.trim() === "es") {
      lang = esES;
    }
    return lang.components.MuiLocalizationProvider.defaultProps.localeText;
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={(e) => handleSubmit(e, toDelete)}
    >
      <Modal.Body>
        <Form.Row>
          <Col sm={3}>
            <Form.Label style={{ marginTop: "7px" }} className="default-label">
              {t("portalrh.emailTemplate.dataTemplate.datas")}: {asterisk}
            </Form.Label>
          </Col>
          <Col sm={9}>
            <Dropdown
              list={datasListDropdown()}
              className={handleDropdownClassName(datasError)}
              handleSubmit={handleDatasSelect}
              defaultValue={
                selectedEmailTemplate && selectedEmailTemplate?.emailMessageDTO.datas
              }
              flowId={datas}
              required
              disabled={toDelete}
              readOnly={toDelete}
              order={false}
            />
            {datasError && validated ? (
              <p className="dropdown-text-invalid">
                {t("portalrh.emailTemplate.dataTemplate.datasError")}
              </p>
            ) : (
              <p></p>
            )}
          </Col>
          {errorMsgVariable && (
            <Alert
              severity="warning"
              style={{ width: "100%", marginBottom: "1rem" }}
            >
              {t("portalrh.emailTemplate.dataTemplate.errorVars")}
            </Alert>
          )}
        </Form.Row>
        <Form.Row>
          {datas ? (
            <>
              <Col sm={3}>
                <Form.Label
                  style={{ marginTop: "7px" }}
                  className="default-label"
                >
                  {t("portalrh.emailTemplate.dataTemplate.periodicidade")}:{" "}
                  {asterisk}
                </Form.Label>
              </Col>
              <Col sm={9}>
                <Dropdown
                  list={periodicidadeListDropdown}
                  className={handleDropdownClassName(periodicidadeError)}
                  handleSubmit={handlePeriodicidadeSelect}
                  required
                  resetDropdown={resetDropdownPeriodicidade}
                  setResetDropdown={setResetDropdownPeriodicidade}
                  defaultValue={
                    selectedEmailTemplate &&
                    selectedEmailTemplate?.emailMessageDTO.periodicidade
                  }
                  flowId={periodicidade}
                  disabled={toDelete}
                  readOnly={toDelete}
                />
                {periodicidadeError && validated ? (
                  <p className="dropdown-text-invalid">
                    {t(
                      "portalrh.emailTemplate.dataTemplate.periodicidadeError"
                    )}
                  </p>
                ) : null}
              </Col>
            </>
          ) : (
            ""
          )}
        </Form.Row>
        <Form.Row>
          {periodicidade && (
            <>
              {" "}
              <Col sm={3}>
                <Form.Label
                  style={{ marginTop: "7px" }}
                  className="default-label"
                >
                  {t("portalrh.emailTemplate.dataTemplate.days")}: {asterisk}
                </Form.Label>
              </Col>
              <Col sm={9}>
                <Form.Control
                  style={{ marginTop: "7px" }}
                  autoComplete="off"
                  type="number"
                  value={numberDays}
                  onChange={(e) => {
                    setNumberDays(e.target.value);
                  }}
                  disabled={toDelete}
                  readOnly={toDelete}
                />
              </Col>
            </>
          )}
        </Form.Row>
        {/* <Form.Row>
          {periodicidade && periodicidade !== "none" && (
            <>
              <Col sm={3}>
                <Form.Label
                  style={{ marginTop: "7px" }}
                  className="default-label"
                >
                  {t("portalrh.emailTemplate.dataTemplate.date")}: {asterisk}
                </Form.Label>
              </Col>
              <Col sm={9} style={{ marginTop: "7px" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={localeTranslations}
                  localeText={getLocaleText()}
                >
                  <DatePicker
                    className="dateTimePickerClass"
                    label={""}
                    views={getViews()}
                    value={dateForPeriodicidade}
                    onChange={(e) => setDateForPeriodicidade(e)}
                    disabled={toDelete}
                    readOnly={toDelete}
                    DateTimeFormat={Intl.DateTimeFormat}
                  />
                </LocalizationProvider>
              </Col>
            </>
          )}
        </Form.Row> */}
        <Form.Row>
          {periodicidade && (
            <>
              {" "}
              <Col sm={3}>
                <Form.Label
                  style={{ marginTop: "7px" }}
                  className="default-label"
                >
                  {t("portalrh.emailTemplate.dataTemplate.tipoNotificacao")}:{" "}
                  {asterisk}
                </Form.Label>
              </Col>
              <Col sm={9} style={{ marginTop: "7px" }}>
                <Form.Check
                  type="checkbox"
                  label={t(
                    "portalrh.emailTemplate.dataTemplate.notificacaoNotification"
                  )}
                  value="notificacao"
                  onChange={(e) => handleNotificationType(e.target.value)}
                  disabled={toDelete}
                  readOnly={toDelete}
                  inline
                  checked={notificationNot}
                />
                <Form.Check
                  type="checkbox"
                  label={t(
                    "portalrh.emailTemplate.dataTemplate.emailNotification"
                  )}
                  value="email"
                  onChange={(e) => handleNotificationType(e.target.value)}
                  disabled={toDelete}
                  readOnly={toDelete}
                  inline
                  checked={emailNotification}
                />
              </Col>
            </>
          )}
        </Form.Row>
        <Form.Row style={{ marginTop: "2rem" }}>
          <Accordion style={{ width: "100%", backgroundColor: "whitesmoke" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography component="span">
                {t("portalrh.emailTemplate.dataTemplate.templateOwn")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Form.Row>
                <Form.Label className="default-label">
                  {t("portalrh.emailTemplate.dataTemplate.subject")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  placeholder={t("portalrh.emailTemplate.dataTemplate.subject")}
                  value={subjectOwn}
                  onChange={(e) => setSubjectOwn(e.target.value)}
                  onFocus={() => setFocus("subjectOwn")}
                  ref={dateOwnSubjectRef}
                  disabled={toDelete}
                  readOnly={toDelete}
                />
                <Form.Control.Feedback type="invalid">
                  {t("portalrh.emailTemplate.warnnings.textMessageMissing")}
                </Form.Control.Feedback>
              </Form.Row>
              <Form.Row>
                <Form.Label className="default-label">
                  {t("portalrh.emailTemplate.message")} {asterisk}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder={t("portalrh.emailTemplate.message")}
                  value={messageOwn}
                  onChange={(e) => setMessageOwn(e.target.value)}
                  onFocus={() => setFocus("messageOwn")}
                  ref={dateOwnMessageRef}
                  required
                  disabled={toDelete}
                  readOnly={toDelete}
                />
                <Form.Control.Feedback type="invalid">
                  {t("portalrh.emailTemplate.warnnings.textMessageMissing")}
                </Form.Control.Feedback>
              </Form.Row>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ width: "100%", backgroundColor: "whitesmoke" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography component="span">
                {t("portalrh.emailTemplate.dataTemplate.templateOther")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Form.Row>
                {periodicidade && (
                  <>
                    <Col sm={3}>
                      <Form.Label
                        style={{ marginTop: "7px" }}
                        className="default-label"
                      >
                        {t("portalrh.emailTemplate.dataTemplate.regra")}:{" "}
                        {asterisk}
                      </Form.Label>
                    </Col>
                    <Col sm={9} style={{ marginTop: "7px" }}>
                      <Dropdown
                        list={regraListDropdown}
                        className={handleDropdownClassName(regraError)}
                        handleSubmit={handleRegraSelect}
                        required
                        resetDropdown={resetDropdownRegra}
                        setResetDropdown={setResetDropdownRegra}
                        defaultValue={
                          selectedEmailTemplate && selectedEmailTemplate?.emailMessageDTO.regra
                        }
                        flowId={regra}
                        disabled={toDelete}
                        readOnly={toDelete}
                      />
                    </Col>
                  </>
                )}
              </Form.Row>
              <Form.Row>
                <Form.Label className="default-label">
                  {t("portalrh.emailTemplate.dataTemplate.subject")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  placeholder={t("portalrh.emailTemplate.dataTemplate.subject")}
                  value={subjectOthers}
                  onChange={(e) => setSubjectOthers(e.target.value)}
                  onFocus={() => setFocus("subjectOthers")}
                  ref={dateOthersSubjectRef}
                  disabled={toDelete}
                  readOnly={toDelete}
                />
                <Form.Control.Feedback type="invalid">
                  {t("portalrh.emailTemplate.warnnings.textMessageMissing")}
                </Form.Control.Feedback>
              </Form.Row>
              <Form.Row>
                <Form.Label className="default-label">
                  {t("portalrh.emailTemplate.message")} {asterisk}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder={t("portalrh.emailTemplate.message")}
                  value={messageOthers}
                  onChange={(e) => setMessageOthers(e.target.value)}
                  onFocus={() => setFocus("messageOthers")}
                  ref={dateOthersMessageRef}
                  required
                  disabled={toDelete}
                  readOnly={toDelete}
                />
                <Form.Control.Feedback type="invalid">
                  {t("portalrh.emailTemplate.warnnings.textMessageMissing")}
                </Form.Control.Feedback>
              </Form.Row>
            </AccordionDetails>
          </Accordion>
        </Form.Row>
        <br></br>
        {!toDelete && (
          <>
            {" "}
            <Form.Label className="default-label">
              {t("portalrh.emailTemplate.messageVariables.title")}
            </Form.Label>
            <Form.Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {createDateMessageVariableButtons(subjectRef, messageRef)}
            </Form.Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {toDelete && (
          <p style={{ color: "black", textAlign: "left", flex: 1 }}>
            {t("portalrh.emailTemplate.removeWarning")}
          </p>
        )}
        <Button variant="secondary" className="btn" onClick={closeCreateModal}>
          {t("general.cancel")}
        </Button>
        <Button className="card-button" type="submit">
          {selectedEmailTemplate
            ? toDelete
              ? t("general.remove")
              : t("general.save")
            : t("general.add")}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default withNamespaces()(DateTemplate);
