import React, { useCallback, useEffect, useState, useRef } from "react";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";
import "../../RhCollaborator/RhCollaborator.scss";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
  PrintContextConsumer,
} from "react-bootstrap-table2-toolkit";
import { useSelector, useDispatch } from "react-redux";
import {
  getCollaborators,
  getConsumoSaldo,
  getMarcacaoFerias,
  getAllMarcacaoFerias,
  postMarcacaoFerias,
  cancelDayOffRequest,
  setUpdateVacations,
  approveDeleteRemoveMarcacaoFerias,
  setMarcacaoFerias,
  postExportVacations,
  setExportVacations,
  cleanIsLoading,
} from "~/store/ducks/portalrh/actionTypes";
import InputLabel from "@mui/material/InputLabel";
import { v4 as uuidv4 } from "uuid";
import { withNamespaces } from "react-i18next";
import DatePicker from "~/components/DatePicker";
import {
  Button as ButtonBoot,
  Row,
  Col,
  Form,
  Modal,
  ListGroup,
  Table,
  Alert,
} from "react-bootstrap";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";
import "~/assets/css/scheduler.css";
import { Dropdown as DropDownBoot } from "react-bootstrap";
import moment from "moment";
import { navigate } from "./ColorVariables";
import SpinnerNew from "~/components/SpinnerNew";
import { Button, Stack, Tabs, Tab } from "@mui/material";
import { grey } from "@mui/material/colors";
import { TextField } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import {
  FaCalendarDay,
  FaCalendarAlt,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaTrash,
  FaClock,
  FaCheckCircle,
  FaMinusCircle,
  FaTimesCircle,
  FaEye,
  FaFileExport,
} from "react-icons/fa";
import {
  MdExpandMore,
  MdExpandLess,
  MdOutlineRemoveCircleOutline,
} from "react-icons/md";
import { CgCalendarToday } from "react-icons/cg";
import { HiMiniCalendarDays } from "react-icons/hi2";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  setErrorPostMarcacaoFeriasConfirm,
  setExportVals,
} from "~/store/ducks/portalrh/actionTypes";
import { GoPlusCircle, GoTrash } from "react-icons/go";
import Year from "./Year";

import uuid from "uuid/v1";

import { Popover } from "@material-ui/core";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import EventItem from "./EventItem";
import Situation from "./Situation";
import { handlePrint } from "~/utils";
import CalendarReact from "react-calendar";
import "react-calendar/dist/Calendar.css";

const AlertMaterial = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SchedulerComponent = ({
  portalRhMenus,
  designacao,
  path,
  t,
  isEditable,
  showCalendarAndTable = true,
  loadEventsStart,
  setLoadEventsStart,
  selectedUser,
  setSelectedUser,
  grupos,
  setgrupos,
  activeButton,
  setActiveButton,
  hideChangeView,
  infoDataWorkHour,
  dataSelectedRequest,
  showAllGroups = false,
  exportBtn,
  diasParaAprovar,
  infoTypeRequest = "",
  infoEmployee = "",
}) => {
  const { user } = useSelector((state) => state.globalReducer);
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { iflowpid } = useSelector((state) => state.processesReducer);
  const { rhbolsaHorasConfiguration } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );
  const {
    collaborators,
    marcacaoFerias,
    isLoadingMarcacaoFerias,
    marcacaoFeriasmsg,
    marcacaoFeriasmsgConfirm,
    gruposTrabalhoRemoto,
    trabalhoRemotoInfoSaldo,
    exportMsg,
    isLoadingExport,
    marcacaoFeriasCalendario,
    consumoSaldo,
    exportVacations,
    isToUpdateVacations,
  } = useSelector((state) => state.portalrhReducer);
  const { rhbolsaHorasUsersGroup } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );

  const { organization } = useSelector((state) => state.organizationsReducer);

  const [menuSideBar, setMenuSideBar] = useState(null);
  const [selectedLocale, setSelectedLocale] = useState(userSettings && userSettings.language.trim());
  const [forceUpdate, setForceUpdate] = useState(0);
  const [events, setEvents] = useState([]);
  const [localizer, setLocalizer] = useState(momentLocalizer(moment));
  const [dateSelected, setDateSelected] = useState(new Date());
  const [loadNewDateSelected, setLoadNewDateSelected] = useState(new Date());
  const [workdays_hours, setworkdays_hours] = useState({});
  const [defaultView, setDefaultView] = useState("year");
  const [showToast, setShowToast] = useState(false);
  const [showToastMSG, setShowToastMSG] = useState(false);
  const [toastText, settoastText] = useState("");
  const [showModalWithDaysMarked, setShowModalWithDaysMarked] = useState(false);
  const [showModalWithDaysMarkedConfirm, setShowModalWithDaysMarkedConfirm] =
    useState(false);

  const [isTabsVisible, setIsTabsVisible] = useState(true);

  const [openExport, setOpenExport] = useState(false);
  const [messageTranslated, setmessageTranslated] = useState("");
  const [openExportError, setOpenExportError] = useState(false);
  const [rangesState, setrangesState] = useState({});

  const [selectedGroup, setSelectedGroup] = useState([]);
  const [criteriaGroup, setCriteriaGroup] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersColor, setSelectedUsersColor] = useState({});
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectAll, setSelectAll] = useState(showAllGroups);
  const [displayedUsers, setDisplayedUsers] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popupEvent, setPopupEvent] = useState(null);

  const [showModalCheckDays, setShowModalCheckDays] = useState(false);
  const [showModalCriteria, setShowModalCriteria] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [removeall, setRemoveAll] = useState(false);
  const [removeSomeDays, setRemoveSomeDays] = useState({});
  const [blockIntervalSelections, setBlockIntervalSelections] = useState(false);
  const [showModalWithDaysToRemove, setShowModalWithDaysToRemove] =
    useState(false);
  const [dateToCompare, setDateToCompare] = useState(undefined);
  const [singleDaySelection, setSingleDaySelection] = useState("single");
  const [confirmRemoveDaysSelected, setConfirmRemoveDaysSelected] =
    useState(false);

  const [endAt, setEndAt] = useState("");
  const [startAt, setStartAt] = useState("");
  const [starEndtMonth, setstarEndtMonth] = useState({
    start: "",
    end: "",
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAllList, setShowAllList] = useState(true);

  const [eventsTemp, setEventsTemp] = useState([]);
  const [emplyeeEvents, setEmplyeeEvents] = useState([]);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 && configuration && configuration.mobileReady
  );
  const [load, setLoad] = useState(false);

  const [showCalendar, setShowCalendar] = useState(false);

  const handleDateChange = (newDate) => {
    setDateSelected(newDate);
    setShowCalendar(false);
    getDatesFromDays(newDate);
  };

  const open = Boolean(anchorEl);

  const [key, setKey] = useState(designacao);

  const dispatch = useDispatch();

  const userNamePortalRH = collaborators.find((collaborator) => {
    return user.legacyUsers.some((legacyUser) => {
      return (
        legacyUser.username === collaborator.username &&
        legacyUser.legacyApplication.label === "portalrh"
      );
    });
  });

  useEffect(() => {
    if (diasParaAprovar && diasParaAprovar.dates.length > 0) {
      const firstDate = new Date(diasParaAprovar.dates[0]);
      setDateSelected(firstDate);
    }
  }, [diasParaAprovar]);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(
        window.innerWidth < 768 && configuration && configuration.mobileReady
      );
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) setIsTabsVisible(false);
  }, [isMobile]);

  useEffect(() => {
    if (Object.keys(removeSomeDays).length > 0) {
      const eventsDays = events.filter(
        (event) => event.idToSelect === removeSomeDays.idToSelect
      );
      setEventsTemp(eventsDays);
      setShowModalWithDaysToRemove(true);
      setDateToCompare(removeSomeDays.start);
    }
  }, [removeSomeDays]);

  const [calendarData, setCalendarData] = useState([]);
  const componentRef = useRef();

  useEffect(() => {
    if (Object.keys(exportVacations).length > 0) {
      const data = generateCalendarData(exportVacations);
      setCalendarData(data);
      dispatch(setExportVacations({}));
    }
  }, [exportVacations]);

  useEffect(() => {
    if (calendarData.length > 0) {
      handlePrint(componentRef.current, setLoad, true);
      dispatch(cleanIsLoading());
    }
  }, [calendarData]);

  const generateCalendarData = (vacations) => {
    const months = moment.months();
    const daysInMonth = (month, year) => new Date(year, month, 0).getDate();
    const year = loadNewDateSelected.getFullYear();
    const calendar = [];

    for (let month = 0; month < 12; month++) {
      const days = daysInMonth(month + 1, year);
      const monthData = [];

      Object.values(vacations).forEach((vacation) => {
        if (vacation.user && vacation.user.nome) {
          let fullName = vacation.user.nome;

          // Check if the name is in email format
          if (fullName.includes("@")) {
            fullName = fullName.split("@")[0].replace(".", " ");
          }

          const nameParts = fullName.split(" ");
          const shortName = `${nameParts[0]} ${
            nameParts[nameParts.length - 1]
          }`;

          const personData = {
            name: shortName,
            days: Array.from({ length: days }, (_, day) => {
              const date = `${year}-${String(month + 1).padStart(
                2,
                "0"
              )}-${String(day + 1).padStart(2, "0")}`;

              const isHoliday = vacation.feriados.some((v) => v.data === date);
              const isVacationApproved = vacation.feriasAprovadas.some((v) =>
                v.startsWith(date)
              );
              const isVacationGozado = vacation.feriasGozadas.some((v) =>
                v.startsWith(date)
              );
              const isDayOff = vacation.folgas.includes(date);

              return {
                date,
                isHoliday,
                isVacationApproved,
                isVacationGozado,
                isDayOff,
              };
            }),
          };
          monthData.push(personData);
        }
      });

      calendar.push({
        month: months[month],
        days: monthData,
      });
    }

    return calendar;
  };

  useEffect(() => {
    dispatch(setMarcacaoFerias({}));
    setEvents([]);
    setShowToastMSG(false);
    setOpenExport(false);
    setmessageTranslated("");
    setOpenExportError(false);
    setShowModalWithDaysMarked(false);
    setShowModalWithDaysMarkedConfirm(false);
    setShowModalWithDaysMarkedConfirm(false);
    setDisplayedUsers([]);
    setEmplyeeEvents([]);
    const { start, end } = getMonthRange(dateSelected);
    rangeChange({ start, end });
    setstarEndtMonth({
      start: moment(dateSelected).startOf("month").startOf("week"),
      end: moment(dateSelected).endOf("month").endOf("week"),
    });
    dispatch(getCollaborators());
    dispatch(getConsumoSaldo());
    portalRhMenus.map((menu) => {
      if (menu.designacao == designacao) {
        setMenuSideBar(menu);
      }
    });
  }, []);

  useEffect(() => {
    if (defaultView == "month") {
      setSingleDaySelection("single");
      setBlockIntervalSelections(true);
    } else {
      setBlockIntervalSelections(false);
    }
  }, [defaultView]);

  useEffect(() => {
    const eventsDays = events.filter(
      (event) =>
        event.type === "feriasmarked" ||
        event.type === "hover" ||
        event.type === "intervals"
    );
    if (eventsDays.length <= 0) {
      setRemoveAll(false);
    } else {
      setRemoveAll(true);
    }
  }, [events]);

  useEffect(() => {
    if (exportMsg !== "") {
      let messagetrans;
      if (exportMsg === "exportedScreen") {
        messagetrans = t("portalrh.workDays.export.exportedScreen");
      } else if (exportMsg === "exportedCriteria") {
        messagetrans = t("portalrh.workDays.export.exportedCriteria");
      }
      if (exportMsg.includes("exportedYear")) {
        let splited = exportMsg.split("::");
        messagetrans =
          t(`portalrh.workDays.export.${splited[0]}`) + "_" + splited[1];
      }
      setmessageTranslated(messagetrans);
      setOpenExport(true);
    }
  }, [exportMsg]);

  useEffect(() => {
    const updateSelectedGroupAndUsers = () => {
      const grupo = gruposTrabalhoRemoto.find((grupo) =>
        grupo.userList.some((usuario) => usuario.id === infoDataWorkHour)
      );
      if (grupo) {
        setShowAllList(false);
        setSelectedGroup([grupo.grupo.id]);
        setSelectedUsers(grupo.userList.map((user) => user.numFuncionario));
      }
    };

    const updateForEmptySelectedGroup = () => {
      const allUsers = gruposTrabalhoRemoto.flatMap((group) => group.userList);
      setDisplayedUsers(allUsers);
      setSelectedUsers(allUsers.map((user) => user.numFuncionario));
      setSelectAll(true);
    };

    if (Object.keys(gruposTrabalhoRemoto).length > 0) {
      if (infoDataWorkHour !== undefined) {
        updateSelectedGroupAndUsers();
      } else if (selectedGroup.length === 0) {
        updateForEmptySelectedGroup();
      }
    }

    if (dataSelectedRequest !== undefined) {
      const date =
        dataSelectedRequest.length > 0
          ? new Date(dataSelectedRequest[0].data)
          : new Date();
      getDatesFromDays(date);
    }
  }, [gruposTrabalhoRemoto, infoDataWorkHour]);

  const updateMarcacaoFerias = () => {
    callUpdateMarcacaoFerias();

    const payload = {
      usrId: userNamePortalRH.id,
      entidade: userNamePortalRH.codEntidade,
      numFunc: userNamePortalRH.funcionarioNum,
      ano: loadNewDateSelected.getFullYear(),
    };

    if (isEditable) dispatch(getMarcacaoFerias(payload));
  };

  useEffect(() => {
    if (userNamePortalRH !== undefined && dateToCompare === undefined) {
      updateMarcacaoFerias();
    }
  }, [loadNewDateSelected, userNamePortalRH, collaborators]);

  useEffect(() => {
    if (
      userNamePortalRH !== undefined &&
      dateToCompare === undefined &&
      isToUpdateVacations
    ) {
      updateMarcacaoFerias();
      dispatch(setUpdateVacations(false));
    }
  }, [isToUpdateVacations]);

  const callUpdateMarcacaoFerias = () => {
    const year = loadNewDateSelected.getFullYear();
    if (isEditable) {
      dispatch(
        getMarcacaoFerias({
          usrId: userNamePortalRH.id,
          entidade: userNamePortalRH.codEntidade,
          numFunc: userNamePortalRH.funcionarioNum,
          ano: year,
        })
      );
      // A chamada comentada para dispatch(getInfoSaldoTrabalhoRemoto) permanece inalterada
    } else {
      const lepNums =
        selectedGroup.length > 0
          ? selectedGroup.flatMap(
              (groupId) =>
                gruposTrabalhoRemoto
                  .find((group) => group.grupo.id === groupId)
                  ?.userList.map((user) => user.numFuncionario) || []
            )
          : displayedUsers.map((user) => user.numFuncionario);

      dispatch(
        getAllMarcacaoFerias({
          lepNum: lepNums,
          ano: year,
          employee: infoEmployee,
        })
      );
    }
  };

  const handleGetExportVacations = () => {
    const year = loadNewDateSelected.getFullYear();
    const entidades = [];

    gruposTrabalhoRemoto.forEach((group) => {
      if (selectedGroup.includes(group.grupo.id)) {
        group.userList.forEach((user) => {
          if (selectedUsers.includes(user.numFuncionario)) {
            entidades.push({
              entidade: user.empCode,
              numeroFuncionario: user.numFuncionario,
            });
          }
        });
      }
    });

    const payload = {
      ano: year,
      funcionarioDTO: entidades,
    };

    dispatch(postExportVacations(payload));
  };

  useEffect(() => {
    setForceUpdate((prevKey) => prevKey + 1);
    if (Object.keys(marcacaoFerias).length > 0) {
      setworkdays_hours(marcacaoFerias);
    } else {
      setworkdays_hours({});
    }
  }, [marcacaoFerias]);

  useEffect(() => {
    // if (Object.keys(workdays_hours).length > 0) {
    const supportedLanguages = ["es", "pt"];
    if (userSettings != undefined) {
      if (supportedLanguages.includes(selectedLocale)) {
        require(`moment/locale/${selectedLocale}.js`);
        moment.locale(selectedLocale);
        moment.updateLocale(selectedLocale, {
          week: {
            dow: 0,
          },
        });
        setLocalizer(momentLocalizer(moment));
      } else {
        moment.locale("en");
        setLocalizer(momentLocalizer(moment));
      }
      setSelectedLocale(userSettings.language.trim());
      //if (userNamePortalRH != undefined) getDatesFromDays(dateSelected);
    }
    // }
  }, [userSettings.language, selectedLocale, workdays_hours]);

  useEffect(() => {
    if (Object.keys(marcacaoFeriasmsg).length > 0) {
      setShowModalWithDaysMarkedConfirm(true);
      setShowModalWithDaysMarked(false);
    } else {
      setShowModalWithDaysMarkedConfirm(false);
      setShowModalWithDaysMarked(false);
    }
  }, [marcacaoFeriasmsg]);

  useEffect(() => {
    if (marcacaoFeriasmsgConfirm != "") {
      setShowToastMSG(true);
      settoastText(marcacaoFeriasmsgConfirm);
      callUpdateMarcacaoFerias();
    } else {
      setShowToastMSG(false);
      settoastText("");
    }
  }, [marcacaoFeriasmsgConfirm]);

  const handleCloseShowToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowToast(false);
    settoastText("");
  };

  const handleCloseShowToastMSG = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setErrorPostMarcacaoFeriasConfirm(""));
    setShowToastMSG(false);
    settoastText("");
  };

  localizer.formats.yearHeaderFormat = "YYYY";

  function convertToEvents(data, type, color) {
    return data.map((item) => {
      const date = moment(item.data);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      return {
        id: uuidv4(),
        title: item.obs,
        start: startLocal,
        end: endLocal,
        allDay: "true",
        type: type,
        color: color,
      };
    });
  }

  function convertToEventsFolgas(data, type, color) {
    return data.map((item) => {
      const date = moment(item);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      return {
        id: uuidv4(),
        title: t("portalrh.workDays.folgas"),
        start: startLocal,
        end: endLocal,
        type: type,
        color: color,
      };
    });
  }

  function convertToEventsDiasMarcados(
    data,
    type,
    color,
    marcacao,
    daytoRemove,
    feriasPendentesMap
  ) {
    // Criar um conjunto de datas do feriasPendentesMap para verificação rápida
    const feriasPendentesSet = new Set();
    if (typeof feriasPendentesMap === "object" && feriasPendentesMap !== null) {
      Object.values(feriasPendentesMap).forEach((marc) => {
        if (marc.tipo === "Apagar" && marc.estado === "1") {
          const inicio = moment(marc.FEM_DATA_INICIO);
          const fim = moment(marc.FEM_DATA_FIM);
          // Adicionar todas as datas entre FEM_DATA_INICIO e FEM_DATA_FIM ao conjunto
          for (let m = inicio; m.isSameOrBefore(fim, "day"); m.add(1, "days")) {
            feriasPendentesSet.add(m.format("YYYY-MM-DD"));
          }
        }
      });
    }

    return data
      .map((item) => {
        const date = moment(item);

        date.set({ hour: 8, minute: 0, second: 0 });
        const startUtc = date.clone();
        const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

        const offset = new Date().getTimezoneOffset();
        const startLocal = new Date(
          startUtc.toDate().getTime() - offset * 60 * 1000
        );
        const endLocal = new Date(
          endUtc.toDate().getTime() - offset * 60 * 1000
        );

        let idToSelect = null;
        let marcacaoPedido = {};
        let parte = "0";

        if (Array.isArray(marcacao)) {
          marcacao.forEach((marc) => {
            const inicio = moment(marc.dataInicio);
            const fim = moment(marc.dataFim);

            if (date.isBetween(inicio, fim, "day", "[]")) {
              idToSelect = marc.idpedido;
              marcacaoPedido = marc;
              parte = marc.parteDia;
            }
          });
        }

        // Verificar se a data está no feriasPendentesSet
        if (feriasPendentesSet.has(item)) {
          return null; // Não retornar nada se a data estiver no feriasPendentesMap
        }

        return {
          id: uuidv4(),
          title: t("portalrh.workDays.ferias"),
          start: startLocal,
          end: endLocal,
          type: type,
          color: color,
          idToSelect: idToSelect,
          marcacaoPedidoEvent: marcacaoPedido,
          daytoRemove: daytoRemove,
          parteDia: parte,
          empConsumoSaldo: "0",
        };
      })
      .filter((event) => event !== null); // Filtrar eventos nulos
  }

  function convertToEventsDiasGozados(
    data,
    type,
    color,
    marcacao,
    feriasPendentesMap
  ) {
    const feriasPendentesSet = new Set();
    if (typeof feriasPendentesMap === "object" && feriasPendentesMap !== null) {
      Object.values(feriasPendentesMap).forEach((marc) => {
        if (marc.tipo === "Apagar" && marc.estado === "1") {
          feriasPendentesSet.add(marc.FEM_DATA_INICIO);
        }
      });
    }
    return data
      .map((item) => {
        const date = moment(item);
        date.set({ hour: 8, minute: 0, second: 0 });
        const startUtc = date.clone();
        const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

        const offset = new Date().getTimezoneOffset();
        const startLocal = new Date(
          startUtc.toDate().getTime() - offset * 60 * 1000
        );
        const endLocal = new Date(
          endUtc.toDate().getTime() - offset * 60 * 1000
        );

        let idToSelect = null;
        let marcacaoPedido = {};
        let parte = "0";

        if (Array.isArray(marcacao)) {
          marcacao.forEach((marc) => {
            const inicio = moment(marc.dataInicio);
            const fim = moment(marc.dataFim);

            if (date.isBetween(inicio, fim, "day", "[]")) {
              idToSelect = marc.idpedido;
              marcacaoPedido = marc;
              parte = marc.parteDia;
            }
          });
        }

        // Verificar se a data está no feriasPendentesSet
        if (feriasPendentesSet.has(item)) {
          return null; // Não retornar nada se a data estiver no feriasPendentesMap
        }

        return {
          id: uuidv4(),
          title: t("portalrh.workDays.diasGozados"),
          start: startLocal,
          end: endLocal,
          type: type,
          color: color,
          idToSelect: idToSelect,
          marcacaoPedidoEvent: marcacaoPedido,
          parteDia: parte,
        };
      })
      .filter((event) => event !== null);
  }

  function convertToEventsDiasPendentes(data, type, color, feriasPendentesMap) {
    // Adicionar datas do feriasPendentesMap ao array data se ainda não estiverem presentes
    if (typeof feriasPendentesMap === "object" && feriasPendentesMap !== null) {
      Object.values(feriasPendentesMap).forEach((marc) => {
        if (marc.tipo === "Apagar" && marc.estado === "1") {
          const inicio = moment(marc.FEM_DATA_INICIO);
          const fim = moment(marc.FEM_DATA_FIM);
          // Adicionar todas as datas entre FEM_DATA_INICIO e FEM_DATA_FIM ao array data
          for (let m = inicio; m.isSameOrBefore(fim, "day"); m.add(1, "days")) {
            const dateStr = m.format("YYYY-MM-DD");
            if (!data.includes(dateStr)) {
              data.push(dateStr);
            }
          }
        }
      });
    }

    return data.map((item) => {
      const date = moment(item);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      let idToSelect = null;
      let marcacaoPedido = {};
      let parte = "0";
      let toCancel = false;

      if (
        typeof feriasPendentesMap === "object" &&
        feriasPendentesMap !== null
      ) {
        Object.values(feriasPendentesMap).forEach((marc) => {
          const inicio = moment(marc.FEM_DATA_INICIO);
          const fim = moment(marc.FEM_DATA_FIM);

          if (date.isBetween(inicio, fim, "day", "[]")) {
            idToSelect = marc.pedId;
            marcacaoPedido = marc;
            parte = marc.FEM_PARTE_DIA;
            if (marc.tipo === "Apagar" && marc.estado === "1") {
              toCancel = true;
            } else {
              toCancel = false; // Ensure toCancel is false if conditions are not met
            }
          }
        });
      }
      return {
        id: uuidv4(),
        title: toCancel
          ? t("portalrh.workDays.feriasToCancel")
          : t("portalrh.workDays.feriasPendentes"),
        start: startLocal,
        end: endLocal,
        type: type,
        color: toCancel ? "#F8B4B4" : color,
        idToSelect: idToSelect,
        marcacaoPedidoEvent: marcacaoPedido,
        daytoRemove: true,
        parteDia: parte,
        empConsumoSaldo: "0",
        toCancel: toCancel,
      };
    });
  }
  function convertToEventsDiasMarcadosTeamEmployee(data, type) {
    return data.map((item) => {
      const date = moment(item.data.date);
      const state = parseInt(item.data.state);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );

      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      const color = state
        ? state === 1
          ? "goldenrod"
          : state === 2
          ? "red"
          : state === 3
          ? "green"
          : item.color
        : item.color;
      return {
        id: uuidv4(),
        title: item.userId,
        start: startLocal,
        end: endLocal,
        type: type,
        color: color,
        state: state,
        name: item.nome,
      };
    });
  }

  function convertToEventsDiasMarcadosTeam(data, type) {
    return data.map((item) => {
      const date = moment(item.data.date);
      const state = parseInt(item.data.state);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      const color = item.color;
      return {
        id: uuidv4(),
        title: item.userId,
        start: startLocal,
        end: endLocal,
        type: type,
        color: color,
        state: state,
        name: item.nome,
      };
    });
  }

  function convertToEventsMarkedDays(data, type, color) {
    return data.map((item) => {
      const date = moment(item);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      return {
        id: uuidv4(),
        title: t("portalrh.workDays.selectedDay"),
        start: startLocal,
        end: endLocal,
        type: type,
        color: color,
        checked: true,
        checkedHalfDay: false,
        multi: true,
        parteDia: "0",
        empConsumoSaldo: "0",
      };
    });
  }

  function convertToEventsMarkedDaysToApprove(data, type, color) {
    return data.dates.map((item) => {
      const date = moment(item);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      return {
        id: uuidv4(),
        title: t("portalrh.workDays.selectedDay"),
        start: startLocal,
        end: endLocal,
        type: type,
        color: color,
        checked: true,
        checkedHalfDay: false,
        multi: true,
        parteDia: "0",
        empConsumoSaldo: "0",
      };
    });
  }

  useEffect(() => {
    const updateEvents = () => {
      if (Object.keys(workdays_hours).length > 0) {
        const events = [
          ...convertToEvents(
            workdays_hours?.feriados,
            "feriados",
            "rgb(226, 226, 226)"
          ),
          ...convertToEventsFolgas(workdays_hours?.folgas, "folgas", "#D6D6D6"),
          ...convertToEventsDiasMarcados(
            workdays_hours?.ferias,
            "ferias",
            "#4447A4",
            workdays_hours.marcacoes,
            true
          ),
          ...convertToEventsDiasMarcados(
            workdays_hours?.datasMarcacoes,
            "datasMarcacoes",
            "#188804",
            workdays_hours.marcacoes,
            true,
            workdays_hours.feriasPendentesMap
          ),
          ...convertToEventsDiasGozados(
            workdays_hours?.datasGozados,
            "datasGozados",
            "#C9FFCC",
            workdays_hours.marcacoes,
            true,
            workdays_hours.feriasPendentesMap
          ),
          ...convertToEventsDiasPendentes(
            workdays_hours?.feriasPendentes,
            "feriasPendentes",
            "#E6CD32",
            workdays_hours.feriasPendentesMap
          ),
          ...getDiasParaAprovarEvents(),
        ];
        setEvents(events);
      } else {
        setEvents([]);
      }
    };

    updateEvents();
  }, [workdays_hours, marcacaoFerias]);

  const getDiasParaAprovarEvents = () => {
    if (!diasParaAprovar) return [];
    const type =
      infoTypeRequest !== "Novo" ? "diasAprovarCancelar" : "diasAprovar";
    const color = infoTypeRequest !== "Novo" ? "#F8B4B4" : "rgb(255, 165, 0)";
    return convertToEventsMarkedDaysToApprove(diasParaAprovar, type, color);
  };

  useEffect(() => {
    if (!isEditable) {
      if (selectedUsers.length > 0) {
        filterWorkdaysByUsers();
      } else {
        const events = [
          ...getDiasParaAprovarEvents(),
          ...(emplyeeEvents.length > 0 ? emplyeeEvents : []),
        ];
        setEvents(events);
      }
    }
  }, [selectedUsers, workdays_hours]);

  const handleModalCloseCheckDays = () => {
    setShowModalCheckDays(false);
    setSelectedSlot(null);
  };

  const handleModalCloseCriteria = () => {
    setShowModalCriteria(false);
    setCriteriaGroup("");
    setEndAt("");
    setStartAt("");
  };

  const handleEventAddition = (eventType) => {
    if (eventType === "normal") {
      confirmCheckDay(selectedSlot);
    } else if (eventType === "custom") {
      confirmCheckDay(selectedSlot);
    }
    {
      return;
    }

    setShowModalCheckDays(false);
    setSelectedSlot(null);
  };
  const confirmCheckDay = (slotInfo) => {
    const daysMarked = convertToEventsMarkedDays(
      [slotInfo],
      "feriasmarked",
      "#E6CD32"
    );

    const isEventAlreadyAdded = events.some(
      (event) =>
        event.type === "feriasmarked" &&
        moment(event.start).isSame(daysMarked[0].start, "day")
    );

    if (isEventAlreadyAdded) {
      setEvents((prevEvents) =>
        prevEvents.filter(
          (event) =>
            !(
              event.type === "feriasmarked" &&
              moment(event.start).isSame(daysMarked[0].start, "day")
            )
        )
      );
      setRemoveAll(true);
    } else {
      setEvents((prevEvents) => [...prevEvents, ...daysMarked]);
    }
  };

  const getDatesFromDays = (date) => {
    const currentDate = date;
    setDateSelected(currentDate);

    const { start, end } = getMonthRange(currentDate);
    rangeChange({ start, end });
    setstarEndtMonth({
      start: moment(dateSelected).startOf("month").startOf("week"),
      end: moment(dateSelected).endOf("month").endOf("week"),
    });
    if (currentDate.getFullYear() !== loadNewDateSelected.getFullYear()) {
      setLoadNewDateSelected(currentDate);
    }
    handleRestartMarkup();
  };

  const rangeChange = useCallback(
    (range) => {
      setrangesState(range);
      return {};
    },
    [events]
  );

  const getMonthRange = (selectedDate) => {
    const startOfMonth = moment(selectedDate).startOf("month").toDate();
    const endOfMonth = moment(selectedDate).endOf("month").toDate();
    return { start: startOfMonth, end: endOfMonth };
  };

  const dayPropGetter = useCallback(
    (date) => {
      const eventOnDate = events.filter((event) =>
        moment(event.start).isSame(date, "day")
      );
      const { start, end } = getMonthRange(dateSelected);

      const isOutsideRange = date < start || date > end;
      // const dayOfWeek = moment(date).day();
      // const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
      if (isOutsideRange) {
        return {
          style: {
            backgroundColor: "#F8F8F8",
            color: "#a0aec0",
            pointerEvents: "none",
          },
        };
      }

      // if (isWeekend) {
      //   return {
      //     style: {
      //       backgroundColor: "#E2E2E2",
      //       color: "#666",
      //       pointerEvents: "none",
      //     },
      //   };
      // }
      if (eventOnDate) {
        let style = {
          backgroundColor: "transparent",
          color: "#666",
          pointerEvents: "none",
        };
        eventOnDate.forEach((event) => {
          if (event.type === "feriasPendentes" || event.type === "ferias") {
            style = {
              backgroundColor: "transparent",
              color: "#666",
              pointerEvents: "none",
            };
          } else if (event.type === "feriados") {
            style = {
              backgroundColor: "rgb(226, 226, 226)",
              color: "#666",
              pointerEvents: "none",
            };
          } else if (event.type === "folgas") {
            style = {
              backgroundColor: "rgba(226, 226, 226,0.6)",
              color: "#666",
              pointerEvents: "none",
            };
          } else if (event.type === "diasAprovar") {
            style = {
              backgroundColor: "rgba(255, 165, 0, 0.5)",
              color: "#666",
              pointerEvents: "none",
            };
          } else if (event.type === "diasAprovarCancelar") {
            style = {
              backgroundColor: "#F8B4B4",
              color: "#666",
              pointerEvents: "none",
            };
          }
        });
        return { style };
      }

      return {};
    },
    [events]
  );

  const handleGroupChange = (groupId) => {
    if (groupId === "") {
      const isSelectingAll =
        selectedGroup.length !== gruposTrabalhoRemoto.length;
      const newGroups = isSelectingAll
        ? gruposTrabalhoRemoto.map((group) => group.grupo.id)
        : [];
      const newUsers = isSelectingAll
        ? gruposTrabalhoRemoto.flatMap((group) => group.userList)
        : [];
      setSelectAll(isSelectingAll);
      setSelectedGroup(newGroups);
      setDisplayedUsers(newUsers);
      setSelectedUsers(newUsers.map((user) => user.numFuncionario));
    } else {
      const isGroupSelected = selectedGroup.includes(groupId);
      const newSelectedGroups = isGroupSelected
        ? selectedGroup.filter((id) => id !== groupId)
        : [...selectedGroup, groupId];

      const newUsers = gruposTrabalhoRemoto
        .filter((group) => newSelectedGroups.includes(group.grupo.id))
        .flatMap((group) => group.userList);
      setSelectAll(isGroupSelected);
      setSelectedGroup(newSelectedGroups);
      setDisplayedUsers(newUsers);
      setSelectedUsers(newUsers.map((user) => user.numFuncionario));
    }

    setSelectedTab(0);
    setShowAllList(false);
  };

  const handleUserChange = (userId, vaaa) => {
    if (userId === "selectAll") {
      const allUserIds = displayedUsersList.map((user) => user.numFuncionario);
      setSelectedUsers(selectAll ? [] : allUserIds);
      setSelectAll(!selectAll);
    } else {
      const updatedSelectedUsers = selectedUsers.includes(userId)
        ? selectedUsers.filter((id) => id !== userId)
        : [...selectedUsers, userId];

      setSelectedUsers(updatedSelectedUsers);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    if (displayedUsers.length > 0) {
      const lepNums = [
        ...(selectedGroup.length > 0
          ? selectedGroup.flatMap(
              (groupId) =>
                gruposTrabalhoRemoto
                  .find((group) => group.grupo.id === groupId)
                  ?.userList.map((user) => user.numFuncionario) || []
            )
          : displayedUsers.map((user) => user.numFuncionario)),
        ...(infoEmployee !== "" ? [infoEmployee] : []),
      ];

      if (lepNums.length > 0) {
        const payload = {
          lepNum: lepNums,
          ano: loadNewDateSelected.getFullYear(),
          employee: infoEmployee,
        };
        dispatch(getAllMarcacaoFerias(payload));
      }
    }
  }, [selectedGroup, displayedUsers]);

  const filterWorkdaysByUsers = () => {
    if (workdays_hours?.diasmarcadosTeam) {
      const filteredDiasMarcadosTeam = workdays_hours.diasmarcadosTeam.filter(
        (diasMarcados) =>
          infoEmployee !== ""
            ? diasMarcados.userId !== infoEmployee &&
              selectedUsers.includes(diasMarcados.userId)
            : selectedUsers.includes(diasMarcados.userId)
      );
      // Atualize o estado selectedUsersColor com as cores associadas a cada usuário
      const updatedSelectedUsersColor = { ...selectedUsersColor };

      filteredDiasMarcadosTeam.forEach((diasMarcados) => {
        const { userId, color } = diasMarcados;
        updatedSelectedUsersColor[userId] = color;
      });

      setSelectedUsersColor(updatedSelectedUsersColor);
      const diasMarcadosTeamEvents = convertToEventsDiasMarcadosTeam(
        filteredDiasMarcadosTeam,
        "ferias"
      );
      let diasParaAprovarEvent = [];
      let diasMarcadosTeamPessoaEvents = [];
      if (diasParaAprovar) {
        const filteredDiasMarcadosTeamPessoa =
          workdays_hours.diasmarcadosTeam.filter(
            (diasMarcados) =>
              infoEmployee !== "" && diasMarcados.userId === infoEmployee
          );
        filteredDiasMarcadosTeamPessoa.forEach((diasMarcados) => {
          const { userId, color } = diasMarcados;
          updatedSelectedUsersColor[userId] = color;
        });
        setSelectedUsersColor(updatedSelectedUsersColor);
        diasMarcadosTeamPessoaEvents = convertToEventsDiasMarcadosTeamEmployee(
          filteredDiasMarcadosTeamPessoa,
          "ferias"
        );
        if (infoTypeRequest !== "Novo") {
          diasParaAprovarEvent = convertToEventsMarkedDaysToApprove(
            diasParaAprovar,
            "diasAprovarCancelar",
            "#F8B4B4"
          );
        } else {
          diasParaAprovarEvent = convertToEventsMarkedDaysToApprove(
            diasParaAprovar,
            "diasAprovar",
            "rgb(255, 165, 0)"
          );
        }
      }
      setEmplyeeEvents(diasMarcadosTeamPessoaEvents);
      setEvents([
        ...diasMarcadosTeamEvents,
        ...diasMarcadosTeamPessoaEvents,
        ...diasParaAprovarEvent,
      ]);
    }
  };

  const handleSearchChange = (event) => {
    setSearchFilter(event.target.value);
    setSelectedTab(0);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const allUsers =
    selectedGroup.length > 0
      ? gruposTrabalhoRemoto
          .filter((group) => selectedGroup.includes(group.grupo.id))
          .flatMap((group) => group.userList)
      : displayedUsers;

  const filteredUsers = allUsers.filter((user) =>
    user.nomeCurto?.toLowerCase().includes(searchFilter?.toLowerCase())
  );

  const displayedUsersList =
    selectedTab === 0
      ? filteredUsers
      : selectedGroup.length === 0
      ? allUsers
      : allUsers.filter((user) => selectedUsers.includes(user.numFuncionario));

  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const ITEM_HEIGHT = 100;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const toggleTabsVisibility = () => {
    setIsTabsVisible(!isTabsVisible);
  };

  const listUsersByGroupConstructor = () => {
    if (selectedGroup.length === 0 && showAllList) {
      handleGroupChange("");
    }
    return (
      <div>
        <FormControl sx={{ m: 1, width: "-webkit-fill-available" }}>
          <Row>
            <Col
              md="12"
              lg="7"
              sm="12"
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <DropDownBoot
                show={showDropdown}
                onToggle={() => setShowDropdown(!showDropdown)}
              >
                <DropDownBoot.Toggle
                  id="dropdown-basic"
                  style={{ width: "auto" }}
                >
                  {selectedGroup.length === 0
                    ? t("portalrh.workDays.selectAnGroup")
                    : selectedGroup.length === 1
                    ? (() => {
                        const designacao = gruposTrabalhoRemoto.find(
                          (group) => group.grupo.id === selectedGroup[0]
                        ).grupo.designacao;
                        return designacao.length > 30
                          ? `${designacao.substring(0, 30)}...`
                          : designacao;
                      })()
                    : `${selectedGroup.length} ${t(
                        "portalrh.workDays.selectedGroups"
                      )}`}
                </DropDownBoot.Toggle>

                <DropDownBoot.Menu style={{ minWidth: "300px" }}>
                  <Form>
                    <Form.Check
                      type="checkbox"
                      id={`check-all`}
                      label={t("portalrh.workDays.all")}
                      checked={
                        selectedGroup.length === gruposTrabalhoRemoto.length
                      }
                      onChange={() => handleGroupChange("")}
                      style={{ minWidth: "280px", margin: "10px" }}
                    />
                    {gruposTrabalhoRemoto.map((group) => (
                      <Form.Check
                        key={group.grupo.id}
                        type="checkbox"
                        id={`check-${group.grupo.id}`}
                        label={
                          group.grupo.designacao.length > 30
                            ? `${group.grupo.designacao.substring(0, 30)}...`
                            : group.grupo.designacao
                        }
                        checked={selectedGroup.includes(group.grupo.id)}
                        onChange={() => handleGroupChange(group.grupo.id)}
                        style={{ minWidth: "280px", margin: "10px" }}
                      />
                    ))}
                  </Form>
                </DropDownBoot.Menu>
              </DropDownBoot>
              <ButtonBoot
                variant="outline-primary"
                onClick={handleGetExportVacations}
                className="custom-button"
              >
                {t("portalrh.abscenses.exportar")}
              </ButtonBoot>
            </Col>
          </Row>

          <>
            {isMobile && (
              <div
                className="mobile-tabs-toggle"
                onClick={toggleTabsVisibility}
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  margin: "1rem 0px",
                }}
              >
                {isTabsVisible ? (
                  <MdExpandLess style={{ fontSize: "x-large" }} />
                ) : (
                  <MdExpandMore style={{ fontSize: "x-large" }} />
                )}
                {!isTabsVisible && <span>Colaboradores</span>}
              </div>
            )}
            {selectedGroup.length > 0 && isTabsVisible && (
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#4484f4",
                    color: "#4484f4",
                    borderRadius: "0px 0px 8px 0px",
                  },
                }}
              >
                <Tab label={t("portalrh.workDays.all")} />
                <Tab label={t("portalrh.workDays.selected")} />
              </Tabs>
            )}

            {selectedTab == 0 && isTabsVisible && (
              <>
                <TextField
                  label={t("portalrh.workDays.searchCol")}
                  variant="standard"
                  value={searchFilter}
                  onChange={handleSearchChange}
                  size="small"
                  style={{ margin: "10px 0px", width: "250px" }}
                />
                {searchFilter === "" && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectAll}
                        onChange={() => handleUserChange("selectAll")}
                      />
                    }
                    label={t("portalrh.workDays.selectAll")}
                  />
                )}
              </>
            )}
          </>
        </FormControl>
        {isTabsVisible && (
          <div
            style={{
              overflowY: "auto",
              maxHeight: hideChangeView ? "480px" : "500px",
              margin: selectedTab == 0 ? "0px 10px 10px" : "10px 10px 10px",
              height: hideChangeView ? "480px" : "500px",
            }}
            className="scrollBar-visible-year"
          >
            <FormGroup>
              {displayedUsersList
                .filter(
                  (user) =>
                    !(
                      infoEmployee !== "" &&
                      user.numFuncionario === infoEmployee
                    )
                )
                .map((user) => (
                  <FormControlLabel
                    key={uuidv4()}
                    control={
                      <Checkbox
                        checked={selectedUsers.includes(user.numFuncionario)}
                        onChange={() => handleUserChange(user.numFuncionario)}
                        style={{
                          color: selectedUsers.includes(user.numFuncionario)
                            ? selectedUsersColor[user.numFuncionario]
                            : "inherit",
                        }}
                      />
                    }
                    label={user.nomeCurto}
                  />
                ))}
            </FormGroup>
          </div>
        )}
      </div>
    );
  };
  const schedulerContrutor = () => {
    return (
      <div key={forceUpdate} style={{ width: "100%" }}>
        <>
          {isEditable ? (
            <Calendar
              localizer={localizer}
              events={events}
              date={dateSelected}
              toolbar={true}
              defaultView={defaultView}
              setEvents={setEvents}
              convertToEventsMarkedDays={convertToEventsMarkedDays}
              convertToEventsMarkedDaysToApprove={
                convertToEventsMarkedDaysToApprove
              }
              settoastText={settoastText}
              setShowToast={setShowToast}
              isEditable={isEditable}
              t={t}
              views={{
                day: false,
                week: false,
                month: true,
                year: Year,
              }}
              max={moment(new Date().setHours(20, 0, 0, 0)).toDate()}
              min={moment(new Date().setHours(8, 0, 0, 0)).toDate()}
              startAccessor="start"
              endAccessor="end"
              selectable={true}
              popup
              onNavigate={getDatesFromDays}
              onView={(e) => setDefaultView(e)}
              dayPropGetter={dayPropGetter}
              onDrillDown={onDrillDown}
              singleDaySelection={singleDaySelection}
              setRemoveAll={setRemoveAll}
              setRemoveSomeDays={setRemoveSomeDays}
              userNamePortalRH={userNamePortalRH}
              onRangeChange={rangeChange}
              formats={{ eventTimeRangeFormat: () => null }}
              messages={{ year: "Year" }}
              tooltipAccessor={constructTooltip}
              eventPropGetter={(myEventsList) => {
                const border = myEventsList.color
                  ? "2px solid " + myEventsList.color
                  : "blue";
                const opacity = 0.3;
                const backgroundColor = myEventsList.color
                  ? `${myEventsList.color}${Math.floor(opacity * 255).toString(
                      16
                    )}`
                  : "blue";

                if (myEventsList.type === "ferias") {
                  return {
                    className: myEventsList.type === "ferias" ? "ferias" : "",
                    style: {
                      border,
                      cursor: "default",
                      color: "inherit",
                      backgroundColor,
                    },
                  };
                }
                if (myEventsList.type === "feriasPendentes") {
                  return {
                    className: `${
                      myEventsList.type === "feriasPendentes"
                        ? "feriasPendentes"
                        : ""
                    } ${
                      myEventsList?.parteDia === "1"
                        ? "meio-dia1parte"
                        : myEventsList?.parteDia === "2"
                        ? "meio-dia2parte"
                        : ""
                    } ${myEventsList.toCancel ? "toCancel" : ""}`.trim(),
                    style: {
                      border: myEventsList.toCancel
                        ? "2px solid #b85959"
                        : "2px solid #E6CD32",
                      cursor: "default",
                      color: "inherit",
                      backgroundColor:
                        myEventsList?.parteDia === "1" ||
                        myEventsList?.parteDia === "2"
                          ? "transparent"
                          : myEventsList.toCancel
                          ? "#F8B4B4"
                          : "#E6CD32",
                    },
                  };
                }
                if (myEventsList.type === "datasMarcacoes") {
                  return {
                    className: `${
                      myEventsList.type === "datasMarcacoes"
                        ? "datasMarcacoes"
                        : ""
                    } ${
                      myEventsList?.parteDia === "1"
                        ? "meio-dia1parte"
                        : myEventsList?.parteDia === "2"
                        ? "meio-dia2parte"
                        : ""
                    }`.trim(),
                    style: {
                      cursor: "default",
                      color: "inherit",
                    },
                  };
                }
                if (myEventsList.type === "datasGozados") {
                  return {
                    className: `${
                      myEventsList.type === "datasGozados" ? "datasGozados" : ""
                    } ${
                      myEventsList?.parteDia === "1"
                        ? "meio-dia1parte"
                        : myEventsList?.parteDia === "2"
                        ? "meio-dia2parte"
                        : ""
                    }`.trim(),
                    style: {
                      cursor: "default",
                      color: "inherit",
                    },
                  };
                }
                if (myEventsList.type === "feriasmarked") {
                  return {
                    className:
                      myEventsList.type === "feriasmarked"
                        ? "feriasmarked"
                        : "",
                    style: {
                      border,
                      cursor: "default",
                      color: "inherit",
                      backgroundColor: "transparent",
                    },
                  };
                }
                return null;
              }}
              components={{
                event: Event,
                month: {
                  dateHeader: CustomDateHeader,
                },
                toolbar: CustomToolbar,
              }}
            />
          ) : (
            <Calendar
              localizer={localizer}
              events={events}
              date={dateSelected}
              toolbar={true}
              defaultView={defaultView}
              setEvents={setEvents}
              convertToEventsMarkedDays={convertToEventsMarkedDays}
              convertToEventsMarkedDaysToApprove={
                convertToEventsMarkedDaysToApprove
              }
              infoEmployee={infoEmployee}
              settoastText={settoastText}
              setShowToast={setShowToast}
              isEditable={isEditable}
              infoTypeRequest={infoTypeRequest}
              t={t}
              views={{
                day: false,
                week: false,
                month: true,
                year: Year,
              }}
              handleClickToSHowList={handleClick}
              max={moment(new Date().setHours(20, 0, 0, 0)).toDate()}
              min={moment(new Date().setHours(8, 0, 0, 0)).toDate()}
              startAccessor="start"
              endAccessor="end"
              selectable={false}
              popup
              //showAllEvents={true}
              onNavigate={getDatesFromDays}
              onView={(e) => setDefaultView(e)}
              dayPropGetter={dayPropGetter}
              onDrillDown={onDrillDown}
              tooltipAccessor={constructTooltip}
              onRangeChange={rangeChange}
              formats={{ eventTimeRangeFormat: () => null }}
              messages={{ year: "Year" }}
              eventPropGetter={(myEventsList) => {
                const border = myEventsList.color
                  ? "2px solid " + myEventsList.color
                  : "blue";
                const opacity = 0.3;
                const backgroundColor = "transparent";
                if (myEventsList.type === "ferias") {
                  const eventos = eventosDoDia(myEventsList.start);
                  if (eventos.length > 2) {
                    return {
                      className: myEventsList.type === "ferias" ? "ferias" : "",
                      style: {
                        border: "0px",
                        cursor: "default",
                        backgroundColor: "transparent",
                      },
                    };
                  }
                  return {
                    className: myEventsList.type === "ferias" ? "ferias" : "",
                    style: {
                      border,
                      cursor: "default",
                      color: "inherit",
                      backgroundColor: "rgb(112, 112, 112/ 10%)",
                    },
                  };
                }
                if (myEventsList.type === "feriasmarked") {
                  return {
                    className:
                      myEventsList.type === "feriasmarked"
                        ? "feriasmarked"
                        : "",
                    style: {
                      border,
                      cursor: "default",
                      color: "inherit",
                      backgroundColor,
                    },
                  };
                }
                return null;
              }}
              components={{
                event: Event,
                month: {
                  dateHeader: CustomDateHeader,
                },
                toolbar: CustomToolbar,
              }}
            />
          )}
          <Popover
            open={open}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={anchorEl}
          >
            {Array.isArray(popupEvent) &&
              popupEvent.map(
                (event) =>
                  event.type !== "diasAprovar" &&
                  event.type !== "diasAprovarCancelar" && (
                    <div
                      key={event.id}
                      style={{ padding: "10px", cursor: "default" }}
                    >
                      <CreatePopUpEntry event={event} />
                    </div>
                  )
              )}
            <Stack
              direction="row"
              style={{ placeContent: "center" }}
              alignItems="center"
              spacing={2}
              margin="5px"
            >
              <ButtonBoot className="card-button-red" onClick={handleClose}>
                {t("portalrh.workDays.close")}
              </ButtonBoot>
            </Stack>
          </Popover>

          {/* {dataSelectedRequest === undefined && (
            <div
              id="legenda"
              style={{ padding: "0px", textAlignLast: "start" }}
            >
              {isEditable && (
                <Button
                  size="small"
                  variant="text"
                  style={{
                    pointerEvents: "none",
                    padding: "0px 10px 0px 0px",
                    color: "#4C4B4B",
                  }}
                >
                  <FaCircle
                    color="rgb(171, 171, 171)"
                    style={{ margin: "0px 5px" }}
                  />
                  {t("portalrh.workDays.legend")}
                </Button>
              )}
              <Button
                size="small"
                variant="text"
                style={{
                  pointerEvents: "none",
                  padding: "0px 10px 0px 0px",
                  color: "#4C4B4B",
                }}
              >
                <FaClock color="goldenrod" style={{ margin: "0px 5px" }} />
                {t("portalrh.workDays.Pendente")}
              </Button>
              <Button
                size="small"
                variant="text"
                style={{
                  pointerEvents: "none",
                  padding: "0px 10px 0px 0px",
                  color: "#4C4B4B",
                }}
              >
                <FaCheckCircle color="green" style={{ margin: "0px 5px" }} />
                {t("portalrh.workDays.Aprovado")}
              </Button>
              <Button
                size="small"
                variant="text"
                style={{
                  pointerEvents: "none",
                  padding: "0px 10px 0px 0px",
                  color: "#4C4B4B",
                }}
              >
                <FaTimesCircle color="red" style={{ margin: "0px 5px" }} />
                {t("portalrh.workDays.refused")}
              </Button>
            </div>
          )} */}
        </>
      </div>
    );
  };

  const CreatePopUpEntry = ({ event }) => {
    const border = `2px solid ${event.color || "blue"}`;
    const backgroundColor = "rgba(112, 112, 112, 0.09)";
    const fontSize = "x-small";
    const fontWeight = "bolder";
    const whiteSpacing = "nowrap";

    const style = {
      color: event.color,
      backgroundColor,
      border,
      fontSize,
      fontWeight,
      whiteSpacing,
    };

    const content = { imageState: event.state, text: event.name };

    return (
      <p className="popupP" style={style}>
        {createEventImageDependingState(content.imageState)}
        {content.text}
      </p>
    );
  };

  const constructTooltip = (event) => {
    return event.name;
  };

  const onDrillDown = (slotInfo) => {
    if (isEditable) {
      if (slotInfo.getMonth() !== dateSelected.getMonth()) {
        return;
      }
      const eventOnThisDay = events.find((event) =>
        moment(event.start).isSame(slotInfo, "day")
      );

      if (eventOnThisDay) {
        if (eventOnThisDay.type !== "feriasmarked") {
          if (
            eventOnThisDay.type === "ferias" ||
            eventOnThisDay.type === "datasMarcacoes" ||
            eventOnThisDay.type === "feriasPendentes"
          ) {
            setRemoveSomeDays(eventOnThisDay);
          } else return;
        } else {
          setSelectedSlot(slotInfo);
          confirmCheckDay(slotInfo);
        }
      } else {
        setSelectedSlot(slotInfo);
        confirmCheckDay(slotInfo);
      }
    }
  };

  function CustomDateHeader({ label, drilldownView, onDrillDown, isOffRange }) {
    const filteredEvents = events.filter(
      (event) => event.start.getMonth() === dateSelected.getMonth()
    );
    const padNumber = (number) => (number < 10 ? `0${number}` : number);

    const hasEvents = filteredEvents.some((event) => {
      const eventDayString = padNumber(event.start.getDate().toString());

      return event.type !== "feriasmarked" && eventDayString === label;
    });

    const hasEventsMark = filteredEvents.some((event) => {
      const eventDayString = padNumber(event.start.getDate().toString());
      return event.type === "feriasmarked" && eventDayString === label;
    });

    const hasEventsMarkDiasMarcados = filteredEvents.some((event) => {
      const eventDayString = padNumber(event.start.getDate().toString());
      return (
        (event.type === "ferias" ||
          event.type === "datasMarcacoes" ||
          event.type === "feriasPendentes") &&
        eventDayString === label
      );
    });

    const hasEventToApprove = filteredEvents.some((event) => {
      const eventDayString = padNumber(event.start.getDate().toString());
      return (
        (event.type === "diasAprovar" ||
          event.type === "diasAprovarCancelar") &&
        eventDayString === label &&
        !isOffRange
      );
    });

    const hasEventFeriados = filteredEvents.some((event) => {
      const eventDayString = padNumber(event.start.getDate().toString());
      return (
        event.type === "feriados" && eventDayString === label && !isOffRange
      );
    });

    // const dateToRender = moment(dateSelected).date(label);
    // const currentYear = new Date().getFullYear();
    // const currentMonth = new Date().getMonth();
    // const yearToRender = moment(dateToRender).year();
    // const monthToRender = moment(dateToRender).month();

    // const currentDay = moment().date();
    // const dayToRender = moment(dateToRender).date();

    // const isBeforeCurrentYear = yearToRender < currentYear;
    // const isBeforeCurrentMonth =
    //   yearToRender === currentYear && monthToRender < currentMonth;
    // const isBeforeCurrentDay =
    //   yearToRender === currentYear &&
    //   monthToRender === currentMonth &&
    //   dayToRender < currentDay;

    const handleClickOnDrillDown = (e) => {
      // if (
      //   !isBeforeCurrentYear &&
      //   !isBeforeCurrentMonth &&
      //   !isBeforeCurrentDay
      // ) {
      //   onDrillDown(e);
      // }
      onDrillDown(e);
    };
    // const cursorStyle =
    //   isBeforeCurrentYear || isBeforeCurrentMonth || isBeforeCurrentDay
    //     ? "default"
    //     : "pointer";

    // const elementStyle = {
    //   cursor: cursorStyle,
    // };
    if (isEditable) {
      return (
        <div
          onClick={(e) => handleClickOnDrillDown(e)}
          className="custom-date-header"
          // style={elementStyle}
        >
          <div className="dh-item header-left">
            {!isOffRange && !hasEvents ? (
              // !isBeforeCurrentYear &&
              // !isBeforeCurrentMonth &&
              // !isBeforeCurrentDay
              hasEventsMark ? (
                <MdOutlineRemoveCircleOutline color="#b1b1b1" />
              ) : (
                <GoPlusCircle color="#b1b1b1" />
              )
            ) : null}
            {!isOffRange && hasEventsMarkDiasMarcados && (
              <GoTrash color="#b1b1b1" />
            )}
          </div>
          <div className="dh-item header-right">
            <a>{hasEventFeriados ? "F" : label}</a>
          </div>
        </div>
      );
    } else {
      return (
        <div onClick={onDrillDown} className={`custom-date-header`}>
          <div className="dh-item header-right">
            <a>{label}</a>
          </div>
        </div>
      );
    }
  }

  // function CustomDateHeader({ label, drilldownView, onDrillDown, isOffRange }) {
  //   if (isEditable) {
  //     const filteredEvents = events.filter(
  //       (event) => event.start.getMonth() === dateSelected.getMonth()
  //     );
  //     const padNumber = (number) => (number < 10 ? `0${number}` : number);

  //     const hasEvents = filteredEvents.some((event) => {
  //       const eventDayString = padNumber(event.start.getDate().toString());

  //       return event.type !== "daysmarked" && eventDayString === label;
  //     });

  //     const hasEventsMark = filteredEvents.some((event) => {
  //       const eventDayString = padNumber(event.start.getDate().toString());
  //       return event.type === "daysmarked" && eventDayString === label;
  //     });

  //     const hasEventsMarkDiasMarcados = filteredEvents.some((event) => {
  //       const eventDayString = padNumber(event.start.getDate().toString());
  //       return (
  //         event.type === "diasMarcados" &&
  //         eventDayString === label &&
  //         event.flag === 1
  //       );
  //     });

  //     const hasDeleteEvent = filteredEvents.some((event) => {
  //       const eventDayString = padNumber(event.start.getDate().toString());

  //       return deleteDays.some(
  //         (deleteEvent) =>
  //           moment(deleteEvent.start).format("YYYY-MM-DD") ===
  //             moment(event.start).format("YYYY-MM-DD") &&
  //           eventDayString === label
  //       );
  //     });

  //     return (
  //       <div onClick={onDrillDown} className="custom-date-header">
  //         <div className="dh-item header-left">
  //           {!isOffRange && !hasEvents ? (
  //             hasEventsMark ? (
  //               <MdOutlineRemoveCircleOutline color="#b1b1b1" />
  //             ) : (
  //               <GoPlusCircle color="#b1b1b1" />
  //             )
  //           ) : null}
  //           {!isOffRange && hasEventsMarkDiasMarcados ? (
  //             hasDeleteEvent ? (
  //               <Badge color="error" size="large" variant="dot">
  //                 <GoTrash color="#b1b1b1" />
  //               </Badge>
  //             ) : (
  //               <GoTrash color="#b1b1b1" />
  //             )
  //           ) : null}
  //         </div>
  //         <div className="dh-item header-right">
  //           <a>{label}</a>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div onClick={onDrillDown} className={`custom-date-header`}>
  //         <div className="dh-item header-right">
  //           <a>{label}</a>
  //         </div>
  //       </div>
  //     );
  //   }
  // }

  const handleClick = (event, selectedEvent) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const top = rect.top - rect.height;

    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";

    setAnchorEl({ top: top, left: rect.left });
    setPopupEvent(selectedEvent);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopupEvent(null);

    document.body.style.overflow = "";
    document.body.style.position = "";
  };

  function Event({ event }) {
    if (event.type == "feriasmarked") {
      return (
        <div>
          <span
            className="eventSpan"
            style={{ display: "inline-flex", marginTop: "2px" }}
          >
            {event.title}
          </span>
        </div>
      );
    }
    if (event.type == "intervals") {
      const eventos = eventosDoDia(event.start);
      if (eventos.length > 2) {
        return (
          <div
            onClick={(e) => handleClick(e, eventos)}
            style={{ textAlign: "center" }}
            title={`${eventos.length} ${t("portalrh.workDays.eventos")}`}
          >
            <span
              className="eventSpan"
              style={{
                display: "inline-flex",
                marginTop: "2px",
                backgroundColor: "rgba(51,5,141, 0.4)",
                padding: "5px",
                width: "40px",
                borderRadius: "20px",
                fontSize: "small",
                border: "2px solid rgb(51,5,141)",
                color: "rgb(51,5,141)",
              }}
            >
              + {eventos.length}
            </span>
          </div>
        );
      }

      return (
        <div>
          {createEventImageDependingState(event.state)}
          <span
            className="eventSpan"
            style={{ display: "inline-flex", marginTop: "2px" }}
          >
            {event.title}
          </span>
        </div>
      );
    }
    if (event.type == "ferias") {
      const eventos = eventosDoDia(event.start);
      if (eventos.length > 2) {
        return (
          <div
            onClick={(e) => handleClick(e, eventos)}
            style={{ textAlign: "center" }}
            title={`${eventos.length} ${t("portalrh.workDays.eventos")}`}
          >
            <span
              className="eventSpan"
              style={{
                display: "inline-flex",
                marginTop: "2px",
                backgroundColor: "rgba(237,108,2, 0.4)",
                padding: "5px",
                width: "40px",
                borderRadius: "20px",
                fontSize: "small",
                border: "2px solid #ED6C02",
                color: "#ED6C02",
              }}
            >
              + {eventos.length}
            </span>
          </div>
        );
      }
      return (
        <div>
          {createEventImageDependingState(event.state)}
          <span
            className="eventSpan"
            style={{
              display: "inline-flex",
              marginTop: "2px",
              color: "dimgrey",
            }}
          >
            {event.name}
          </span>
        </div>
      );
    }
    if (event.type == "feriasPendentes") {
      return (
        <div>
          <span
            className="eventSpan"
            style={{ display: "inline-flex", marginTop: "2px" }}
          >
            {event.title}
          </span>
        </div>
      );
    }
    if (event.type == "datasMarcacoes") {
      return (
        <div>
          <span
            className="eventSpan"
            style={{ display: "inline-flex", marginTop: "2px" }}
          >
            {event.title}
          </span>
        </div>
      );
    }
    if (event.type == "datasGozados") {
      return (
        <div>
          <span
            className="eventSpan"
            style={{ display: "inline-flex", marginTop: "2px" }}
          >
            {event.title}
          </span>
        </div>
      );
    }
    return null;
  }

  const eventosDoDia = (date) =>
    events.filter(
      (evento) =>
        evento.start.getDate() === date.getDate() &&
        evento.start.getMonth() === date.getMonth() &&
        evento.start.getFullYear() === date.getFullYear()
    );
  const createEventImageDependingState = (state) => {
    if (state === 0) {
      return (
        <FaClock
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "goldenrod",
          }}
        />
      );
    }
    if (state === 1) {
      return (
        <FaClock
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "goldenrod",
          }}
        />
      );
    }
    if (state === 2) {
      return (
        <FaMinusCircle
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "red",
          }}
        />
      );
    }
    if (state === 3) {
      return (
        <FaCheckCircle
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "green",
          }}
        />
      );
    }
    if (state === 4) {
      return (
        <FaTimesCircle
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "red",
          }}
        />
      );
    }
    if (state === 5) {
      return (
        <FaEye
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "green",
          }}
        />
      );
    }
  };

  const createEventDescriptionDependingState = (state) => {
    if (state === 1) {
      return t("portalrh.workDays.Pendente");
    }
    if (state === 2) {
      return t("portalrh.workDays.Anulado");
    }
    if (state === 3) {
      return t("portalrh.workDays.Aprovado");
    }
    if (state === 4) {
      return t("portalrh.workDays.Recusado");
    }
    if (state === 5) {
      return t("portalrh.workDays.Visto");
    }
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[400]),
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: grey[200],
    },
  }));

  const ColorButtonArrow = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[600],
    "&:hover": {
      backgroundColor: grey[500],
    },
  }));

  const handleSendDays = () => {
    const eventsDays = events.filter(
      (event) => event.type === "feriasmarked" || event.type === "intervals"
    );
    if (eventsDays.length < 1) {
      return;
    }
    setEventsTemp(eventsDays);
    setShowModalWithDaysMarked(true);
  };

  const removeDayFromEvents = (day) => {
    const updatedEvents = events.filter((event) => {
      return !(event.type === day.type && event.id === day.id);
    });
    setEvents(updatedEvents);
  };

  const formatDateSaveWorkDays = (date) => {
    return new Date(date)
      .toLocaleString("en-GB", {
        timeZone: "Europe/Lisbon",
      })
      .split(",")[0]
      .split("/")
      .reverse()
      .join("-");
  };

  const handleSaveWorkDays = () => {
    const eventsDays = eventsTemp;
    let dates = [];

    eventsDays.forEach((event) => {
      let eventDates = [formatDateSaveWorkDays(event.start)];

      if (event.intervals) {
        event.intervals.forEach((interval) => {
          eventDates.push(formatDateSaveWorkDays(interval));
        });
      }

      if (event.intervals || event.type !== "intervals") {
        dates.push({
          data: eventDates,
          meioDia: event.checkedHalfDay,
          parteDia: event.parteDia,
          anoAnterior: event.empConsumoSaldo,
        });
      }
    });
    if (userNamePortalRH !== undefined) {
      const payload = {
        dates: dates,
        userId: userNamePortalRH.id,
        type: "Novo",
      };
      dispatch(postMarcacaoFerias(payload));
      setEventsTemp([]);
      handleRestartMarkup();
      setShowModalWithDaysMarked(false);
    }
  };

  const handleSaveWorkDaysConfirm = () => {
    const eventsDays = events.filter((event) => event.type === "feriasmarked");

    let dates = [];
    eventsDays.forEach((event) => {
      let eventDates = [new Date(event.start).toISOString().split("T")[0]];
      if (event.intervals) {
        event.intervals.forEach((interval) => {
          eventDates.push(new Date(interval).toISOString().split("T")[0]);
        });
      }
      dates.push({ dates: eventDates });
    });

    if (userNamePortalRH !== undefined) {
      const payload = {
        anoAnterior: false,
        dates: dates,
        userId: userNamePortalRH.id,
      };
      //dispatch(postMarcacaoFeriasConfirm(payload));
      setShowModalWithDaysMarkedConfirm(false);
      setShowModalWithDaysMarked(false);
    }
  };

  const handleCheckboxChange = (day, type) => {
    setEvents((prevEvents) => {
      const updatedEventsDays = prevEvents.map((eventDay) => {
        if (eventDay.id === day.id) {
          // Verifica se o estado atual é diferente do novo estado
          const newChecked = type === "diaInteiro" ? true : false;
          const newCheckedHalfDay = type === "diaInteiro" ? false : true;

          return {
            ...eventDay,
            checked: newChecked,
            checkedHalfDay: newCheckedHalfDay,
          };
        }

        return eventDay;
      });

      return updatedEventsDays;
    });
  };

  const handleRemoveDays = () => {
    setConfirmRemoveDaysSelected(true);
  };
  const handleConfirmRemoveDays = (eventsDays, onHide) => {
    if (removeSomeDays.type !== "datasMarcacoes") {
      const payload = {
        ped_id: removeSomeDays.idToSelect,
        state: 2,
        username: userNamePortalRH.username,
      };
      dispatch(approveDeleteRemoveMarcacaoFerias(payload));
      const updatedEvents = events.filter(
        (event) => event.idToSelect !== removeSomeDays.idToSelect
      );
      setEvents(updatedEvents);
      onHide();
    } else {
      let arrayDestino = [];

      if (eventsDays.every((eventDay) => eventDay.daytoRemove)) {
        const { dataInicio, dataFim, numFuncionario, idpedido } =
          eventsDays[0].marcacaoPedidoEvent;
        arrayDestino.push({ dataInicio, dataFim, numFuncionario, idpedido });
      } else {
        arrayDestino = eventsDays
          .filter((eventDay) => eventDay.daytoRemove)
          .map((eventDay) => ({
            dataInicio: moment(eventDay.start).format("YYYY-MM-DD"),
            dataFim: moment(eventDay.end).format("YYYY-MM-DD"),
            numFuncionario: userNamePortalRH.funcionarioNum,
            idpedido: eventDay.idToSelect,
          }));
      }
      if (arrayDestino.length > 0) {
        const payloadOtherRequest = {
          usrId: userNamePortalRH.id,
          entidade: userNamePortalRH.codEntidade,
          numFunc: userNamePortalRH.funcionarioNum,
          ano: loadNewDateSelected.getFullYear(),
        };
        dispatch(cancelDayOffRequest(arrayDestino, payloadOtherRequest));
        setShowModalWithDaysToRemove(false);
        setDateToCompare(undefined);
        setRemoveSomeDays({});
        setConfirmRemoveDaysSelected(false);
      }
    }
  };

  function ModalWithDaysToRemove(props) {
    if (Object.keys(removeSomeDays).length < 1 && dateToCompare === undefined) {
      setShowModalWithDaysToRemove(false);
      setDateToCompare(undefined);
      return null;
    }

    const eventsDays = events.filter(
      (event) => event.idToSelect === removeSomeDays.idToSelect
    );

    const hasFeriasPendentes = eventsDays.some(
      (event) => event.type === "feriasPendentes"
    );

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        key={uuid()}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>
              {hasFeriasPendentes
                ? t("portalrh.workDays.reuqestCanceled")
                : t("portalrh.workDays.requestFeriasCanceled")}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!hasFeriasPendentes && (
            <Form.Label>
              {" "}
              {t("portalrh.workDays.selectDaysToRemove")}
            </Form.Label>
          )}
          <div
            className="scrollBar-visible-year"
            style={{
              overflowY: "auto",
              maxHeight: "350px",
              padding: "10px",
            }}
          >
            <ListGroup>{renderListModalsToRemove(eventsDays)}</ListGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Stack direction="row" spacing={2}>
            {confirmRemoveDaysSelected ? (
              <>
                <Button
                  variant="contained"
                  className="card-button-red"
                  onClick={() => setConfirmRemoveDaysSelected(false)}
                >
                  {t("portalrh.workDays.back")}
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  className="card-button-scheduler"
                  onClick={() =>
                    handleConfirmRemoveDays(eventsDays, props.onHide)
                  }
                >
                  {hasFeriasPendentes
                    ? t("portalrh.workDays.confirmCancelRequest")
                    : t("portalrh.workDays.confirmarCancelamento")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  className="card-button-red"
                  onClick={props.onHide}
                >
                  {t("portalrh.workDays.close")}
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  className="card-button-scheduler"
                  onClick={() => handleRemoveDays()}
                  disabled={eventsDays.every(
                    (eventDay) =>
                      !eventDay.daytoRemove &&
                      eventDay.type === "datasMarcacoes"
                  )}
                >
                  {hasFeriasPendentes
                    ? t("portalrh.workDays.calcenRequest")
                    : t("portalrh.workDays.selectedFeriasMarcadas")}
                </Button>
              </>
            )}
          </Stack>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleCheckboxChangeRemoveDays = (id) => {
    setEvents((prevEvents) => {
      const updatedEventsDays = prevEvents.map((eventDay) => {
        if (eventDay.id === id) {
          const newChecked = !eventDay.daytoRemove;

          return {
            ...eventDay,
            daytoRemove: newChecked,
          };
        }

        return eventDay;
      });

      return updatedEventsDays;
    });
  };

  const renderListModalsToRemove = (entry) =>
    entry.map((day) => (
      <ListGroup.Item
        key={day.id}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        {" "}
        {day.type === "datasMarcacoes" && (
          <div style={{ display: "flex", marginBottom: "5px" }}>
            <FormControlLabel
              key={uuidv4()}
              style={{ margin: "0px" }}
              label={t("portalrh.workDays.diaInteiro")}
              control={
                <Checkbox
                  style={{ margin: "0px" }}
                  checked={
                    day.marcacaoPedidoEvent.meioDia === "0" ? true : false
                  }
                  disabled={true}
                />
              }
            />
            <FormControlLabel
              key={uuidv4()}
              style={{ margin: "0px", marginLeft: "10px" }}
              label={t("portalrh.workDays.diasMarcadosMeiodia")}
              control={
                <Checkbox
                  style={{ margin: "0px" }}
                  checked={
                    day.marcacaoPedidoEvent.meioDia === "1" ? true : false
                  }
                  disabled={true}
                />
              }
            />
          </div>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <b
            style={{
              cursor: "default",
              display: "flex",
              alignItems: "center",
              placeContent: "space-between",
            }}
          >
            <>
              {moment(day.start).format(`DD [${t("portalrh.workDays.de")}] MMMM
              [${t("portalrh.workDays.de")}] YYYY`)}
            </>
            {day.type === "datasMarcacoes" && (
              <FormControlLabel
                key={uuidv4()}
                style={{ margin: "0px", marginLeft: "10px" }}
                control={
                  <Checkbox
                    style={{ margin: "0px" }}
                    checked={day.daytoRemove}
                    onChange={() => handleCheckboxChangeRemoveDays(day.id)}
                    disabled={confirmRemoveDaysSelected}
                  />
                }
              />
            )}
          </b>
        </div>
      </ListGroup.Item>
    ));

  const updateEventState = (eventId, updatedProperties) => {
    const updatedEvents = eventsTemp.map((event) => {
      if (event.id === eventId) {
        return { ...event, ...updatedProperties };
      }
      return event;
    });

    setEventsTemp(updatedEvents);
  };

  const removeDayFromEventsTemp = (day) => {  
      const dayId = day.id;
      const dayIntervals = day.intervals ? day.intervals.map(interval => new Date(interval).toISOString().split('T')[0]) : [];
  
      const updatedEvents = eventsTemp.filter((event) => {
          const eventDate = new Date(event.start).toISOString().split('T')[0];
          if (event.type === 'intervals') {
              if (event.id === dayId || dayIntervals.includes(eventDate)) {
                  return false;
              }
          } else {
              if (event.id === dayId) {
                  return false;
              }
          }
          return true;
      });
      setEventsTemp(updatedEvents);
  };

  function ModalWithCheckDays() {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        key={uuid()}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{t("portalrh.workDays.listmarkedDays")}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              className="card-button-red"
              onClick={handleModalCloseCheckDays}
            >
              {t("portalrh.workDays.close")}
            </Button>
            <Button
              variant="contained"
              color="success"
              className="card-button-scheduler"
              onClick={() => handleSaveWorkDays()}
            >
              {t("portalrh.workDays.submitDay")}
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    );
  }

  function ModalWithCriteria(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        key={uuid()}
        expport
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{t("portalrh.workDays.export.title")}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ margin: "10px 0px" }}>
            <Col lg="12" style={{ padding: "0px" }}>
              <InputLabel id="demo-controlled-open-select-label">
                {t("portalrh.workDays.export.groups")}
              </InputLabel>
              <Select
                value={criteriaGroup}
                label="Grupos"
                onChange={(e) => setCriteriaGroup(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Select group" }}
              >
                <MenuItem value="">{t("portalrh.workDays.all")}</MenuItem>
                {Object.keys(gruposTrabalhoRemoto).length > 0 &&
                  gruposTrabalhoRemoto.map((group) => (
                    <MenuItem key={group.grupo.id} value={group.grupo.id}>
                      {group.grupo.designacao}
                    </MenuItem>
                  ))}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <InputLabel id="demo-controlled-open-select-label">
                {t("portalrh.workDays.export.startDate")}
              </InputLabel>
              <DatePicker
                value={startAt}
                onChange={(e) => setStartAt(e)}
                format="dd/MM/yyyy"
              />
            </Col>
            <Col lg="6">
              <InputLabel id="demo-controlled-open-select-label">
                {t("portalrh.workDays.export.endDate")}
              </InputLabel>
              <DatePicker
                value={endAt}
                onChange={(e) => setEndAt(e)}
                format="dd/MM/yyyy"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              className="card-button-red"
              onClick={handleModalCloseCriteria}
            >
              {t("portalrh.workDays.close")}
            </Button>
            <Button
              variant="contained"
              color="success"
              className="card-button-scheduler"
              onClick={() => handleSendCriteriaData()}
            >
              {t("portalrh.workDays.export.btn")}
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    );
  }

  const renderListModals = (entry, add) =>
    entry.map((day) => (
      <ListGroup.Item
        key={day.date}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        {" "}
        {day?.intervals != undefined && day?.intervals.length > 0 ? null : (
          <div style={{ display: "flex", marginBottom: "5px" }}>
            <FormControlLabel
              key={uuidv4()}
              style={{ margin: "0px" }}
              label={t("portalrh.workDays.diaInteiro")}
              control={
                <Checkbox
                  style={{ margin: "0px" }}
                  checked={
                    add ? day.checked : day.diaInteiro === "0" ? true : false
                  }
                  disabled={add ? false : true}
                  onChange={() => handleCheckboxChange(day, "diaInteiro")}
                />
              }
            />
            <FormControlLabel
              key={uuidv4()}
              style={{ margin: "0px", marginLeft: "10px" }}
              label={t("portalrh.workDays.diasMarcadosMeiodia")}
              control={
                <Checkbox
                  style={{ margin: "0px" }}
                  checked={
                    add
                      ? day.checkedHalfDay
                      : day.diaInteiro === "1"
                      ? true
                      : false
                  }
                  disabled={add ? false : true}
                  onChange={() => handleCheckboxChange(day, "halfDay")}
                />
              }
            />
          </div>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <b
            style={{ cursor: "default", display: "flex", alignItems: "center" }}
          >
            <>
              {add
                ? moment(day.start).format(
                    `DD [${t("portalrh.workDays.de")}] MMMM [${t(
                      "portalrh.workDays.de"
                    )}] YYYY`
                  )
                : moment(day.date).format(
                    `DD [${t("portalrh.workDays.de")}] MMMM [${t(
                      "portalrh.workDays.de"
                    )}] YYYY`
                  )}
              {add && (
                <div
                  style={{
                    cursor: "pointer",
                    marginLeft: "auto",
                    color: "#AD3E3E",
                  }}
                  onClick={() => removeDayFromEvents(day)}
                >
                  <FaTrash />
                </div>
              )}
            </>
          </b>
          {day?.intervals !== undefined && day?.intervals.length > 0 && (
            <>
              {day.intervals.map((interval, index) => (
                <b>
                  {moment(interval).format(
                    `DD [${t("portalrh.workDays.de")}] MMMM [${t(
                      "portalrh.workDays.de"
                    )}] YYYY`
                  )}
                </b>
              ))}
            </>
          )}
        </div>
      </ListGroup.Item>
    ));

  function ModalWithDaysMarkedConfirm(props) {
    const eventsDays = events.filter(
      (event) => event.type === "feriasmarked" || event.type === "intervals"
    );

    if (eventsDays.length < 1) {
      setShowModalWithDaysMarked(false);
      setShowModalWithDaysMarkedConfirm(false);
      return null;
    }
    return (
      <>
        {Object.keys(marcacaoFeriasmsg).length > 0 && (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            key={uuid()}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h4>{t("portalrh.workDays.daysToConfirm")}</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {marcacaoFeriasmsg.semanal.length > 0 && (
                <>
                  <p style={{ margin: "0px" }}>
                    {t("portalrh.workDays.textoDiasSemanais.1")}{" "}
                    {marcacaoFeriasmsg.semanal.length}{" "}
                    {t("portalrh.workDays.textoDiasSemanais.2")}
                  </p>
                  <ListGroup
                    style={{
                      overflowY: "auto",
                      maxHeight: "225px",
                      padding: "10px",
                    }}
                    className="scrollBar-visible-year"
                  >
                    {renderListModals(marcacaoFeriasmsg.semanal)}
                  </ListGroup>
                </>
              )}

              {marcacaoFeriasmsg.exedente.length > 0 && (
                <>
                  <p style={{ margin: "0px" }}>
                    {t("portalrh.workDays.textoDiasExcedentes.1")}{" "}
                    {marcacaoFeriasmsg.exedente.length}{" "}
                    {t("portalrh.workDays.textoDiasExcedentes.2")}
                  </p>
                  <ListGroup
                    style={{
                      overflowY: "auto",
                      maxHeight: "225px",
                      padding: "10px",
                    }}
                    className="scrollBar-visible-year"
                  >
                    {renderListModals(marcacaoFeriasmsg.exedente)}
                  </ListGroup>
                </>
              )}

              {marcacaoFeriasmsg.bolsa.length > 0 && (
                <>
                  <p style={{ margin: "0px" }}>
                    {t("portalrh.workDays.textoBolsa.1")}{" "}
                    {marcacaoFeriasmsg.bolsa.length}{" "}
                    {t("portalrh.workDays.textoBolsa.2")}
                  </p>
                  <ListGroup
                    style={{
                      overflowY: "auto",
                      maxHeight: "330px",
                      padding: "10px",
                    }}
                    className="scrollBar-visible-year"
                  >
                    {renderListModals(marcacaoFeriasmsg.bolsa)}
                  </ListGroup>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Stack
                direction="row"
                spacing={2}
                style={{
                  width: "100%",
                  justifyContent: "right",
                  padding: "0px 16px",
                }}
              ></Stack>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className="card-button-red"
                  onClick={props.onHide}
                >
                  {t("portalrh.workDays.close")}
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  className="card-button-scheduler"
                  onClick={() => handleSaveWorkDaysConfirm()}
                >
                  {t("portalrh.workDays.confirmDays")}
                </Button>
              </Stack>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }

  const toastResultMSG = (text) => {
    if (text === "error")
      return (
        <AlertMaterial
          onClose={handleCloseShowToastMSG}
          severity="error"
          sx={{ width: "100%" }}
        >
          {t("portalrh.workDays.error")}
        </AlertMaterial>
      );
    if (text === "sucess")
      return (
        <AlertMaterial
          onClose={handleCloseShowToastMSG}
          severity="success"
          sx={{ width: "100%" }}
        >
          {t("portalrh.workDays.success")}
        </AlertMaterial>
      );
  };

  const handleExport = (type) => {
    if (type === "criteria") {
      setShowModalCriteria(true);
    } else {
      let list;
      if (type === "screen") {
        const filteredDiasMarcados = marcacaoFerias.diasmarcadosTeam.filter(
          (item) => {
            // Verificar se o userId está presente em selectedUsers
            return (
              selectedUsers.includes(item.userId) &&
              new Date(item.data).getMonth() === dateSelected.getMonth()
            );
          }
        );
        list = filteredDiasMarcados;
      }
      if (type === "year") {
        const filteredDiasMarcados = marcacaoFerias.diasmarcadosTeam.filter(
          (item) => {
            // Verificar se o userId está presente em selectedUsers
            return (
              selectedUsers.includes(item.userId) &&
              new Date(item.data).getFullYear() === dateSelected.getFullYear()
            );
          }
        );
        list = filteredDiasMarcados;
      }
      const monthName = moment(dateSelected).format("MMMM");
      const yearDate = moment(dateSelected).format("YYYY");
      const payload = {
        type,
        list,
        monthName,
        yearDate,
      };
      sendExportData(payload);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSendCriteriaData = () => {
    if (Object.keys(gruposTrabalhoRemoto).length > 0) {
      let ids;
      if (criteriaGroup === "") {
        const allUsersCriteria = gruposTrabalhoRemoto.flatMap(
          (group) => group.userList
        );
        ids = allUsersCriteria.map((user) => user.id);
      } else {
        const selectedGroupCriteria = gruposTrabalhoRemoto.find(
          (group) => group.grupo.id === criteriaGroup
        );
        ids = selectedGroupCriteria.userList.map((user) => user.id);
      }
      if (startAt === "" || endAt === "") {
        setOpenExportError(true);
        return;
      }
      const payload = {
        type: "criteria",
        dataInicio: formatDate(new Date(startAt)),
        dataFim: formatDate(new Date(endAt)),
        ids,
      };
      sendExportData(payload);
    }
  };

  const sendExportData = (payload) => {
    //dispatch(getExportVals(payload));
    handleModalCloseCriteria();
  };

  const handleSingleDaySelection = (event, newAlignment) => {
    if (newAlignment === null) return;
    setSingleDaySelection(newAlignment);
  };

  const handleRestartMarkup = () => {
    const filteredEvents = events.filter(
      (event) =>
        event.type !== "feriasmarked" &&
        event.type !== "hover" &&
        event.type !== "intervals"
    );
    setEvents(filteredEvents);
    setRemoveAll(false);
  };
  class CustomToolbar extends React.Component {
    navigate = (action) => {
      this.props.onNavigate(action);
    };

    changeView = (view) => {
      this.props.onView(view);
    };

    renderExportButton = () => {
      return (
        <DropDownBoot>
          <DropDownBoot.Toggle
            id="dropdown-basic"
            style={{
              backgroundColor: "#4484f4",
              borderColor: "#4484f4",
              color: "white",
            }}
          >
            <FaFileExport className="me-2" />
            {t("portalrh.workDays.export.btn")}
          </DropDownBoot.Toggle>
          <DropDownBoot.Menu>
            <DropDownBoot.Item onClick={() => handleExport("screen")}>
              {t("portalrh.workDays.export.item1")}
            </DropDownBoot.Item>
            <DropDownBoot.Item onClick={() => handleExport("year")}>
              {t("portalrh.workDays.export.item2")}
            </DropDownBoot.Item>
            <DropDownBoot.Item onClick={() => handleExport("criteria")}>
              {t("portalrh.workDays.export.item3")}
            </DropDownBoot.Item>
          </DropDownBoot.Menu>
        </DropDownBoot>
      );
    };

    renderToggleButtonGroup = () => {
      return (
        <ToggleButtonGroup
          value={defaultView}
          exclusive
          onChange={(event, value) => value !== null && this.changeView(value)}
          aria-label="calendar view"
        >
          <ToggleButton value="month" aria-label="month">
            <FaCalendarDay /> {t("portalrh.workDays.mes")}
          </ToggleButton>
          <ToggleButton value="year" aria-label="year">
            <FaCalendarAlt /> {t("portalrh.workDays.ano")}
          </ToggleButton>
        </ToggleButtonGroup>
      );
    };

    renderSingleDaySelection = () => {
      return (
        <>
          <InputLabel id="typeMarkup">Tipo de Marcação</InputLabel>
          <ToggleButtonGroup
            value={singleDaySelection}
            exclusive
            onChange={handleSingleDaySelection}
            aria-label="text alignment"
          >
            <ToggleButton value="single" aria-label="Dia">
              <CgCalendarToday /> Dia
            </ToggleButton>
            <ToggleButton
              value="interval"
              aria-label="Intervalo"
              disabled={blockIntervalSelections}
            >
              <HiMiniCalendarDays /> Intervalo
            </ToggleButton>
          </ToggleButtonGroup>
        </>
      );
    };

    render() {
      let {
        localizer: { messages },
        label,
      } = this.props;
      // const dateToRender = moment(dateSelected);
      // const currentYear = moment().year();
      // const currentMonth = moment().month();
      // const isBeforeCurrentYear = dateToRender.year() < currentYear;
      // const isBeforeCurrentMonth =
      //   dateToRender.year() === currentYear &&
      //   dateToRender.month() < currentMonth;

      return (
        <div className="rbc-toolbar">
          <Row className="mb-3 w-100">
            {!isEditable && exportBtn && (
              <Col xs="12" md="6" className="p-0 mb-2 mb-md-0">
                {this.renderExportButton()}
              </Col>
            )}
            <Col
              xs="12"
              md={exportBtn ? "6" : !hideChangeView ? "6" : "12"}
              className={`text-end d-flex justify-content-end gap-3 align-items-baseline col-lg-${
                isEditable ? 6 : 12
              } col-md-12 col-sm-12 ${hideChangeView ? "text-end" : ""}`}
            >
              <Row className="w-100">
                {!isMobile && (
                  <Col
                    xs="12"
                    lg={!hideChangeView ? "5" : "4"}
                    md={!hideChangeView ? "5" : "4"}
                    className="text-end p-0 text-md-start"
                  >
                    {this.renderToggleButtonGroup()}
                  </Col>
                )}
                <Col
                  xs="12"
                  lg={!hideChangeView ? "7" : "8"}
                  md={!hideChangeView ? "7" : "8"}
                  className={`align-items-center p-0 ${
                    isMobile
                      ? "mb-3 d-flex justify-content-center"
                      : "justify-content-start"
                  }`}
                >
                  <ColorButtonArrow
                    size="small"
                    variant="text"
                    startIcon={<FaAngleDoubleLeft />}
                    onClick={this.navigate.bind(null, navigate.PREVIOUS)}
                  />
                  <span
                    className="rbc-toolbar-label mx-2"
                    style={{ maxWidth: "15%" }}
                    onClick={() => {
                      defaultView !== "year" && setShowCalendar(!showCalendar);
                    }}
                  >
                    {label}
                  </span>
                  <ColorButtonArrow
                    size="small"
                    variant="text"
                    startIcon={<FaAngleDoubleRight />}
                    onClick={this.navigate.bind(null, navigate.NEXT)}
                  />
                  {showCalendar && (
                    <CalendarReact
                      className="reactCalendarClass"
                      onChange={handleDateChange}
                      value={dateSelected}
                      maxDetail="year"
                      minDetail="decade"
                      locale={selectedLocale || "en"}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            {!isMobile && isEditable && (
              <Col
                lg="6"
                md="12"
                sm="12"
                className="text-end"
                style={{
                  padding: "0px",
                  display: "inline-flex",
                  justifyContent: "right",
                  gap: "1rem",
                  alignItems: "baseline",
                }}
              >
                {!hideChangeView &&
                  defaultView !== "month" &&
                  this.renderSingleDaySelection()}
                {isEditable && (
                  <Stack
                    direction="row"
                    style={{ placeContent: "center" }}
                    alignItems="center"
                    spacing={2}
                    margin="5px"
                  >
                    <ButtonBoot
                      className="card-button"
                      onClick={handleSendDays}
                      style={{ color: "white" }}
                      disabled={
                        events.filter(
                          (event) =>
                            event.type === "feriasmarked" ||
                            event.type === "intervals"
                        ).length <= 0
                      }
                    >
                      {t("portalrh.workDays.newMark")}
                    </ButtonBoot>
                    {removeall && (
                      <ButtonBoot
                        className="card-button-red active"
                        onClick={handleRestartMarkup}
                      >
                        {t("portalrh.workDays.cancel")}
                      </ButtonBoot>
                    )}
                  </Stack>
                )}
              </Col>
            )}
            {isMobile && (
              <Col
                lg="6"
                md="12"
                sm="12"
                className="text-end d-flex p-0 justify-content-end gap-3 align-items-flex-start"
              >
                {!hideChangeView && defaultView !== "month" && (
                  <Col
                    className={`justify-content-end ${isMobile ? "mb-3" : ""}`}
                    style={{ display: "contents" }}
                  >
                    {this.renderSingleDaySelection()}
                  </Col>
                )}
              </Col>
            )}
            {isEditable && isMobile && (
              <Row className="g-2 align-items-center justify-content-center">
                <Col
                  className={`d-flex ${
                    isMobile ? "justify-content-center" : "justify-content-end"
                  }`}
                  style={{ gap: "1.2em", marginTop: "0.5em" }}
                >
                  <ButtonBoot
                    className="card-button"
                    onClick={handleSendDays}
                    style={{ color: "white" }}
                    disabled={
                      events.filter(
                        (event) =>
                          event.type === "feriasmarked" ||
                          event.type === "intervals"
                      ).length <= 0
                    }
                  >
                    {t("portalrh.workDays.newMark")}
                  </ButtonBoot>
                  {removeall && (
                    <ButtonBoot
                      className="card-button-red active"
                      onClick={handleRestartMarkup}
                    >
                      {t("portalrh.workDays.cancel")}
                    </ButtonBoot>
                  )}
                </Col>
              </Row>
            )}
          </Row>
          {isEditable && (
            <>
              {/* {isBeforeCurrentMonth && defaultView === "month" ? (
                <Row className="w-100">
                  <Col xs="12" className="p-0">
                    <Alert variant="info">
                      {t("portalrh.workDays.cannotScheduleOnThisMonth")}
                    </Alert>
                  </Col>
                </Row>
              ) : isBeforeCurrentYear ? (
                <Row className="w-100">
                  <Col xs="12" className="p-0">
                    <Alert variant="info">
                      {t("portalrh.workDays.cannotScheduleOnThisYear")}
                    </Alert>
                  </Col>
                </Row>
              ) : null} */}
              <Row className="w-100 mb-3">
                <Col xs="12" className="p-0">
                  <Situation />
                </Col>
              </Row>
            </>
          )}
        </div>
      );
    }
  }

  const syncEventsWithTemp = () => {
      let updatedEvents = [...events];
      updatedEvents = updatedEvents.filter(event => {
          if (event.type === 'intervals' || event.type === 'feriasmarked') {
              return eventsTemp.some(tempEvent => tempEvent.id === event.id);
          }
          return true;
      });
      eventsTemp.forEach((tempEvent) => {
          const eventIndex = updatedEvents.findIndex(
              (event) => event.id === tempEvent.id
          );
  
          if (eventIndex !== -1) {
              updatedEvents[eventIndex] = { ...tempEvent };
          } else {
              updatedEvents.push({ ...tempEvent });
          }
      });
  
      setEvents(updatedEvents);
  };

  const handleModalClose = () => {
    syncEventsWithTemp();
    setShowModalWithDaysMarked(false);
    setShowModalWithDaysMarkedConfirm(false);
  };

  const handleModalCloseDaysToRemove = () => {
    setShowModalWithDaysToRemove(false);
    setDateToCompare(undefined);
    setRemoveSomeDays({});
    setConfirmRemoveDaysSelected(false);
    const updatedEvents = events.map((event) => ({
      ...event,
      daytoRemove: event.daytoRemove != null,
    }));
    setEvents(updatedEvents);
  };

  const handleCloseExport = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setExportVals(""));
    setOpenExport(false);
    setmessageTranslated("");
  };

  const handleCloseExportError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenExportError(false);
  };

  const noSaldoAndEmpConsumoSaldoZeroOrTwo =
    marcacaoFeriasCalendario.sPendente === 0 &&
    (eventsTemp.some((event) => event.empConsumoSaldo === "0") ||
      eventsTemp.some((event) => event.empConsumoSaldo === "2"));

  const halfDayNotCheckedAndEmpConsumoSaldoZeroOrTwo =
    marcacaoFeriasCalendario.sPendente === 0.5 &&
    eventsTemp.some((event) => event.checkedHalfDay === false) &&
    (eventsTemp.some((event) => event.empConsumoSaldo === "0") ||
      eventsTemp.some((event) => event.empConsumoSaldo === "2"));

  const noSaldoAnoAntAndEmpConsumoSaldoOne =
    marcacaoFeriasCalendario.sPendenteAnoAnt === 0 &&
    eventsTemp.some((event) => event.empConsumoSaldo === "1");

  const noChoosedHalfDay =
    eventsTemp.some((event) => event.checkedHalfDay === true) &&
    eventsTemp.some((event) => event.parteDia === "0");

  const isDisabled =
    noSaldoAndEmpConsumoSaldoZeroOrTwo ||
    halfDayNotCheckedAndEmpConsumoSaldoZeroOrTwo ||
    noSaldoAnoAntAndEmpConsumoSaldoOne ||
    noChoosedHalfDay ||
    eventsTemp.length < 1;

  return (
    <>
      <SpinnerNew open={isLoadingExport} />
      <Snackbar
        open={openExport}
        autoHideDuration={5000}
        onClose={handleCloseExport}
      >
        <AlertMaterial
          onClose={handleCloseExport}
          severity="success"
          sx={{ width: "100%" }}
        >
          {messageTranslated}
        </AlertMaterial>
      </Snackbar>
      <Snackbar
        open={openExportError}
        autoHideDuration={5000}
        onClose={handleCloseExportError}
      >
        <AlertMaterial
          onClose={handleCloseExportError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {t("portalrh.workDays.export.dateToExport")}
        </AlertMaterial>
      </Snackbar>
      <Snackbar
        open={showToast}
        autoHideDuration={20000}
        onClose={handleCloseShowToast}
      >
        <AlertMaterial
          onClose={handleCloseShowToastMSG}
          severity="error"
          sx={{ width: "100%" }}
        >
          {toastText}
        </AlertMaterial>
      </Snackbar>
      <Snackbar
        open={showToastMSG}
        autoHideDuration={20000}
        onClose={handleCloseShowToastMSG}
      >
        {toastResultMSG(toastText)}
      </Snackbar>
      {/* <Sidebar menuSideBar={menuSideBar} path={path} /> */}
      <SpinnerNew open={isLoadingMarcacaoFerias} />
      <>
        {showCalendarAndTable && (
          <div
            style={{
              padding: !isEditable ? "" : !hideChangeView ? "" : "10px",
            }}
          >
            <Row>
              {!isEditable && Object.keys(gruposTrabalhoRemoto).length > 0 && (
                <Col lg={"4"} md={"4"} sm="12" style={{ padding: 0 }}>
                  {listUsersByGroupConstructor()}
                </Col>
              )}
              <Col
                lg={!isEditable ? "8" : "12"}
                md={!isEditable ? "8" : "12"}
                sm="12"
                style={{ padding: 0 }}
              >
                <>{selectedLocale && schedulerContrutor()}</>
              </Col>
            </Row>
          </div>
        )}
      </>

      <Modal
        show={showModalWithDaysMarked}
        onHide={handleModalClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{t("portalrh.workDays.listmarkedDays")}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {((marcacaoFeriasCalendario.sPendente === 0 &&
            (eventsTemp.some((event) => event.empConsumoSaldo === "0") ||
              eventsTemp.some((event) => event.empConsumoSaldo === "2"))) ||
            (marcacaoFeriasCalendario.sPendente === 0.5 &&
              eventsTemp.some((event) => event.checkedHalfDay === false) &&
              (eventsTemp.some((event) => event.empConsumoSaldo === "0") ||
                eventsTemp.some(
                  (event) => event.empConsumoSaldo === "2"
                )))) && (
            <Alert variant="info">{t("portalrh.workDays.dontHaveSaldo")}</Alert>
          )}
          {marcacaoFeriasCalendario.sPendenteAnoAnt === 0 &&
            eventsTemp.some((event) => event.empConsumoSaldo === "1") && (
              <Alert variant="info">
                {t("portalrh.workDays.dontHaveSaldoLastYear")}
              </Alert>
            )}

          <ListGroup
            style={{
              overflowY: "auto",
              maxHeight: "300px",
              padding: "10px",
              height: "300px",
            }}
            className="scrollBar-visible-year"
          >
            {eventsTemp
              .filter((event) => !event.removeDay)
              .map((event) => (
                <EventItem
                  key={event.id}
                  event={event}
                  updateEventState={updateEventState}
                  removeDayFromEvents={removeDayFromEventsTemp}
                  disabled={false}
                  isToRemove={false}
                  selectedLocale={selectedLocale}
                />
              ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              className="card-button-red"
              onClick={handleModalClose}
            >
              {t("portalrh.workDays.close")}
            </Button>
            <Button
              variant="contained"
              color="success"
              className="card-button-scheduler"
              onClick={handleSaveWorkDays}
              disabled={isDisabled}
            >
              {t("portalrh.workDays.submitDay")}
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModalWithDaysToRemove}
        onHide={handleModalCloseDaysToRemove}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>
              {eventsTemp.some((event) => event.type === "feriasPendentes")
                ? t("portalrh.workDays.reuqestCanceled")
                : t("portalrh.workDays.requestFeriasCanceled")}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!eventsTemp.some((event) => event.type === "feriasPendentes") && (
            <Form.Label>
              {" "}
              {t("portalrh.workDays.selectDaysToRemove")}
            </Form.Label>
          )}
          <ListGroup
            style={{
              overflowY: "auto",
              maxHeight: "350px",
              padding: "10px",
            }}
            className="scrollBar-visible-year"
          >
            {eventsTemp.map((event) => (
              <EventItem
                key={event.id}
                event={event}
                updateEventState={updateEventState}
                removeDayFromEvents={removeDayFromEventsTemp}
                disabled={true}
                isToRemove={true}
                selectedLocale={selectedLocale}
              />
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Stack direction="row" spacing={2}>
            {confirmRemoveDaysSelected ? (
              <>
                <Button
                  variant="contained"
                  className="card-button-red"
                  onClick={() => setConfirmRemoveDaysSelected(false)}
                >
                  {t("portalrh.workDays.back")}
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  className="card-button-scheduler"
                  onClick={() =>
                    handleConfirmRemoveDays(
                      eventsTemp,
                      handleModalCloseDaysToRemove
                    )
                  }
                >
                  {eventsTemp.some((event) => event.type === "feriasPendentes")
                    ? t("portalrh.workDays.confirmCancelRequest")
                    : t("portalrh.workDays.confirmarCancelamento")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  className="card-button-red"
                  onClick={handleModalCloseDaysToRemove}
                >
                  {t("portalrh.workDays.close")}
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  className="card-button-scheduler"
                  onClick={() => handleRemoveDays()}
                  disabled={eventsTemp.every(
                    (eventDay) =>
                      !eventDay.daytoRemove &&
                      eventDay.type === "datasMarcacoes"
                  )}
                >
                  {eventsTemp.some((event) => event.type === "feriasPendentes")
                    ? t("portalrh.workDays.calcenRequest")
                    : t("portalrh.workDays.selectedFeriasMarcadas")}
                </Button>
              </>
            )}
          </Stack>
        </Modal.Footer>
      </Modal>

      <ModalWithDaysMarkedConfirm
        show={showModalWithDaysMarkedConfirm}
        onHide={handleModalClose}
      />
      <ModalWithCheckDays
        show={showModalCheckDays}
        onHide={handleModalCloseCheckDays}
      />
      <ModalWithCriteria
        show={showModalCriteria}
        onHide={handleModalCloseCriteria}
      />
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          {/* Legend */}
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h4>{t("portalrh.workDays.legendTitle")}</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div>
                  <span
                    style={{
                      backgroundColor: "#E2E2E2",
                      padding: "5px",
                      marginRight: "10px",
                    }}
                  ></span>
                  {t("portalrh.workDays.feriasTranslated.folgasFeriados")}
                </div>
                <div>
                  <span
                    style={{
                      backgroundColor: "#188804",
                      padding: "5px",
                      marginRight: "10px",
                    }}
                  ></span>
                  {t("portalrh.workDays.feriasTranslated.approved")}
                </div>
                <div>
                  <span
                    style={{
                      backgroundColor: "#C9FFCC",
                      padding: "5px",
                      marginRight: "10px",
                    }}
                  ></span>
                  {t("portalrh.workDays.feriasTranslated.gozadas")}
                </div>
              </div>
            </div>
            <div>
              <h4>
                {`${t(
                  "portalrh.workDays.vacationsPlanOf"
                )} ${loadNewDateSelected.getFullYear()}`}
              </h4>
              <div>
                <strong>{t("portalrh.workDays.group")}</strong>{" "}
                {selectedGroup.length === 1
                  ? (() => {
                      const designacao = gruposTrabalhoRemoto.find(
                        (group) => group.grupo.id === selectedGroup[0]
                      ).grupo.designacao;
                      return designacao;
                    })()
                  : `${selectedGroup.length} ${t(
                      "portalrh.workDays.selectedGroups"
                    )}`}
              </div>
            </div>
          </div>

          {calendarData.map((monthData, index) => (
            <div
              key={index}
              style={{
                border: "1px solid black",
                padding: "10px",
                marginBottom: "20px",
              }}
            >
              <h3 style={{ textAlign: "center" }}>{monthData.month}</h3>
              <Table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    {Array.from(
                      { length: monthData.days[0].days.length },
                      (_, day) => (
                        <th key={day} style={{ width: "15px", padding: "2px" }}>
                          {day + 1}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {monthData.days.map((person, index) => (
                    <tr key={index}>
                      <td style={{ padding: "2px" }}>{person.name}</td>
                      {person.days.map((day, dayIndex) => (
                        <td
                          key={dayIndex}
                          style={{
                            backgroundColor:
                              day.isDayOff || day.isHoliday
                                ? "#E2E2E2"
                                : day.isVacationApproved
                                ? "#188804"
                                : day.isVacationGozado
                                ? "#C9FFCC"
                                : "white",
                            width: "15px",
                            padding: "2px",
                          }}
                        >
                          {/* No text, just color */}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ))}

          {/* Signature and Date */}
          <div
            style={{
              marginTop: "50px",
              paddingTop: "10px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <div>
              <strong>{t("portalrh.workDays.assignature")}</strong>
              <span>___________________________</span>
            </div>
            <div>
              <strong>{t("portalrh.workDays.date")}</strong>
              <span>___________________________</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(SchedulerComponent);
