import React, { useState } from "react";
import { FaFilter } from "react-icons/fa";
import { withNamespaces } from "react-i18next";
import { Accordion, Button, Col, Form } from "react-bootstrap";
import DatePicker from "~/components/DatePicker";

const Filters = ({
  t,
  userNamePortalRH,
  selectedUser,
  createDatetoDispatch,
  getRHSaldos,
  setSaldos_hours,
  saldos_hours,
  saldos_hoursCopy,
  isMobile,
  dateVal,
  setDateVal,
  haveFilters,
  setHaveFilters,
  handleReset,
  grupos
}) => {
  const [filterActive, setFilterActive] = useState(isMobile ? false : true);

  const handleStartAt = (e) => {
    const funcionarioNum = selectedUser && grupos != "" ? selectedUser.numFuncionario : userNamePortalRH?.funcionarioNum;
    if (funcionarioNum) {
      getRHSaldos(createDatetoDispatch(e), funcionarioNum, e);
    }
  };

  const formatDate = (date) => {
    const ano = date.getFullYear();
    const mes = (date.getMonth() + 1).toString().padStart(2, "0"); // +1 porque os meses começam em zero
    const dia = date.getDate().toString().padStart(2, "0");

    return `${ano}-${mes}-${dia}`;
  };

  return (
    <>
      <div
        className={
          filterActive
            ? "task-filter-category-toggle-wrapper-active"
            : "task-filter-category-toggle-wrapper"
        }
        onClick={() => {
          setFilterActive(!filterActive);
        }}
        style={{ display: "flex", marginTop: grupos != "" ? "1rem" : "0" }}
      >
        {haveFilters && (
          <div
            style={{
              width: "7px",
              height: "7px",
              backgroundColor: "red",
              borderRadius: "20px",
              alignSelf: "start",
            }}
          ></div>
        )}
        <span className="task-filter-category-toggle">
          {<FaFilter />} &nbsp; {t("general.filters")}&nbsp;
        </span>
      </div>
      <Accordion activeKey={filterActive ? "0" : null}>
        <Accordion.Collapse eventKey="0">
          <Form>
            <Form.Row>
              <Col lg="4">
                <Form.Label>{t("taskPage.general.dataRegisto")}</Form.Label>
                <DatePicker
                  value={dateVal}
                  onChange={(e) => handleStartAt(e)}
                  format="dd/MM/yyyy"
                />
              </Col>
            </Form.Row>
            <br />
            <Button
              className="card-button"
              onClick={() => {
                handleReset();
              }}
              style={{ marginLeft: "15px" }}
            >
              {t("general.clean")}
            </Button>
            <hr />
          </Form>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
};

export default withNamespaces()(Filters);
