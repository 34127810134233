import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getOrganizationById } from "~/store/ducks/organizations/actionTypes";
import { getRHBolsaHorasConfiguration } from "~/store/ducks/rhadminConfiguration/actionTypes";
import {
  getGruposTrabalhoRemoto,
  postMarcacaoFerias,
  getCollaborators,
  getMarcacaoFerias,
  getAllMarcacaoFerias,
  postMarcacaoFeriasConfirm,
  getInfoSaldoTrabalhoRemoto,
  getExportVals,
  cancelDayOffRequest,
  approveDeleteRemoveMarcacaoFerias,
} from "~/store/ducks/portalrh/actionTypes";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { convertToEvents, pedidosEvents, folgasEvents } from "./utils";
import Scheduler from "./Scheduler";
import Legend from "./Legend";
import { momentLocalizer } from "react-big-calendar";
import Toast from "react-bootstrap/Toast";

const Ferias = (props) => {
  const {
    portalRhMenus,
    designacao,
    path,
    isEditable,
    showCalendarAndTable,
    loadEventsStart,
    t,
  } = props;
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { organization } = useSelector((state) => state.organizationsReducer);
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const {
    collaborators,
    marcacaoFerias,
    isLoadingMarcacaoFerias,
    marcacaoFeriasmsg,
    marcacaoFeriasmsgConfirm,
    gruposTrabalhoRemoto,
    exportMsg,
    isLoadingExport,
    marcacaoFeriasCalendario,
  } = useSelector((state) => state.portalrhReducer);

  const [selectedUser, setSelectedUser] = useState(undefined);
  const [grupos, setgrupos] = useState("");
  const [activeButton, setActiveButton] = useState("");
  const [userNamePortalRH, setUsernamePortalRH] = useState("");
  const [allEvents, setAllEvents] = useState([]);
  const [selectedLocale, setSelectedLocale] = useState(userSettings && userSettings.language.trim());
  const [localizer, setLocalizer] = useState(momentLocalizer(moment));
  const [showToast, setShowToast] = useState(false);
  const [toastText, settoastText] = useState("");

  useEffect(() => {
    if (Object.keys(organization).length < 1 && user?.organization?.id) {
      dispatch(getOrganizationById(user.organization.id));
    } else if (Object.keys(organization).length > 0) {
      dispatch(getRHBolsaHorasConfiguration(organization.id));

      const supportedLanguages = ["es", "pt"];
      const language = supportedLanguages.includes(userSettings?.language)
        ? userSettings.language.trim()
        : "en";
      require(`moment/locale/${language}.js`);
      moment.locale(language);
      if (language !== "en") {
        moment.updateLocale(language, { week: { dow: 0 } });
      }
      setLocalizer(momentLocalizer(moment));
      setSelectedLocale(language);
    }
    dispatch(getCollaborators());
  }, [user, organization, userSettings]); // Dependências combinadas

  useEffect(() => {
    // Encontra o userNamePortalRH baseado em condições específicas
    const userNamePortalRH = collaborators.find((collaborator) => {
      return user.legacyUsers.some((legacyUser) => {
        return (
          legacyUser.username === collaborator.username &&
          legacyUser.legacyApplication.label === "portalrh"
        );
      });
    });
    setUsernamePortalRH(userNamePortalRH);
  }, [collaborators, user]); // Adicionado user.legacyUsers para precisão

  useEffect(() => {
    // Executa ações específicas quando o componente é montado ou quando isEditable muda
    if (!isEditable) {
      const payload = {
        label: "vacationsManagement",
        typeData: "AC",
      };
      dispatch(getGruposTrabalhoRemoto(payload));
      setSelectedUser(undefined);
      setgrupos("");
      setActiveButton("");
      dispatch(postMarcacaoFerias({}));
    }
  }, [isEditable]); // Dependência em isEditable para re-execução condicional

  useEffect(() => {
    if (userNamePortalRH !== "" && userNamePortalRH !== undefined) {
      const payload = {
        usrId: userNamePortalRH.id,
        entidade: userNamePortalRH.codEntidade,
        numFunc: userNamePortalRH.funcionarioNum,
        ano: new Date().getFullYear(),
      };
      if (isEditable) dispatch(getMarcacaoFerias(payload));
    }
  }, [userNamePortalRH]);

  useEffect(() => {
    if (Object.keys(marcacaoFerias).length > 0) {
      const pedidosEventsTemp = pedidosEvents(marcacaoFerias, t);
      const folgasEventsTemp = folgasEvents(marcacaoFerias, t);

      const allConvertedEventsPedidos = pedidosEventsTemp.flatMap(
        (pedidoEvent) => convertToEvents(pedidoEvent)
      );
      const allConvertedEventsFolgas = folgasEventsTemp.flatMap((folgasEvent) =>
        convertToEvents(folgasEvent)
      );
      setAllEvents([...allConvertedEventsPedidos, ...allConvertedEventsFolgas]);
    } else {
      setAllEvents([]);
    }
  }, [marcacaoFerias]);

  useEffect(() => {
    setTimeout(() => {
      setShowToast(false);
      settoastText("");
    }, 5000);
  }, [showToast]);

  const createToast = (text) => {
    let className = "";
    let message = "";

    if (text === "error") {
      className = "bg-danger";
      message = t("portalrh.workDays.error");
    } else if (text === "success") {
      className = "bg-success";
      message = t("portalrh.workDays.success");
    } else {
      className = "bg-danger"; // Default class if text is neither error nor success
      message = text; // Use the text directly if it's not a known key
    }

    return (
      <Toast>
        <Toast.Body className={className} style={{ color: "white" }}>
          {message}
        </Toast.Body>
      </Toast>
    );
  };

  const callUpdateMarcacaoFerias = (newDate) => {
    if (isEditable) {
      const payload = {
        usrId: userNamePortalRH.id,
        entidade: userNamePortalRH.codEntidade,
        numFunc: userNamePortalRH.funcionarioNum,
        ano: newDate.getFullYear(),
      };
      dispatch(getMarcacaoFerias(payload));
    }
    // else {
    //   const selectedGroupInfo =
    //     Object.keys(gruposTrabalhoRemoto).length > 0 &&
    //     gruposTrabalhoRemoto.find((group) => group.grupo.id === selectedGroup);
    //   if (selectedGroupInfo) {
    //     const lepNums = selectedGroupInfo.userList.map(
    //       (user) => user.numFuncionario
    //     );
    //     const payload = {
    //       lepNum: lepNums,
    //       ano: loadNewDateSelected.getFullYear(),
    //     };
    //     dispatch(getAllMarcacaoFerias(payload));
    //   } else {
    //     const lepNums = displayedUsers.map((user) => user.numFuncionario);
    //     const payload = {
    //       lepNum: lepNums,
    //       ano: loadNewDateSelected.getFullYear(),
    //     };
    //     dispatch(getAllMarcacaoFerias(payload));
    //   }
    // }
  };

  const callSaveWorkDays = (workDays) => {
    let dates = [];
    const payload = {
      anoAnterior: false,
      dates: dates,
      userId: userNamePortalRH.id,
      type: "Novo",
    };
    if (userNamePortalRH) dispatch(postMarcacaoFerias(payload));
  };

  return (
    <div className="main-card-v2" style={{ overflow: "auto", scrollY: "auto" }}>
      <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
        <Card.Header className="justify-content-between">
          <div>
            <h6>{t("menu.portalrh.subMenus.vacationsManagement")}</h6>
            {showToast && createToast(toastText)}
          </div>
        </Card.Header>
        <Card.Body>
          <Scheduler
            allEvents={allEvents}
            isEditable={isEditable}
            designacao={designacao}
            selectedLocale={selectedLocale}
            localizer={localizer}
            setAllEvents={setAllEvents}
            callUpdateMarcacaoFerias={callUpdateMarcacaoFerias}
            callSaveWorkDays={callSaveWorkDays}
          />
          {/* <SchedulerComponent
            portalRhMenus={portalRhMenus}
            designacao={designacao}
            path={path}
            isEditable={isEditable}
            showCalendarAndTable={showCalendarAndTable}
            loadEventsStart={loadEventsStart}
            setLoadEventsStart={setLoadEventsStart}
            selectedUser={isEditable ? undefined : selectedUser}
            setSelectedUser={setSelectedUser}
            grupos={grupos}
            setgrupos={setgrupos}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
            hideChangeView={hideChangeView}
          /> */}
        </Card.Body>
        <Card.Footer>
          <Legend />
        </Card.Footer>
      </Card>
    </div>
  );
};

export default withNamespaces()(Ferias);
