import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import pagination from "~/components/pagination/currentSizePerPage/10";
import { mobilePagination } from "~/components/pagination/currentSizePerPage/mobilePagination";
import Dropdown from "~/components/Dropdown";
import { Row, Col, Form, Badge } from "react-bootstrap";
import moment from "moment";
import { toLocaleDateString } from "~/utils";
import MobileCard from "./MobileCard";
import { Chip } from "@mui/material";

const ConsumosTable = ({ t }) => {
  const dispatch = useDispatch();
  const { consumosList, isLoadingConsumosList } = useSelector(
    (state) => state.processesReducer
  );
  const { configuration } = useSelector((state) => state.adminConfigReducer);

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 && configuration.mobileReady
  );
  const [value, setValue] = useState("0");
  const [tableValues, setTableValues] = useState([]);
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [saldoAprovado, setSaldoAprovado] = useState(0);
  const [saldoPendente, setSaldoPendente] = useState(0);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(
        window.innerWidth < 768 && configuration.mobileReady
      );
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setTableValues(consumosList.adiantamentos || []);
    setSaldoAprovado(consumosList.saldoAprovado || 0);
    setSaldoPendente(consumosList.saldoPendente || 0);
  }, [consumosList]);

  const getColorByStatus = (status) => {
    switch (status) {
      case "APROVADO":
        return "success";
      case "REJEITADO":
        return "danger";
      case "PENDENTE":
        return "warning";
      default:
        return "";
    }
  };

  const translateStatus = (status) => {
    switch (status) {
      case "APROVADO":
        return t("portalrh.rhadmin.consumos.aprovado");
        case "REJEITADO":
        return t("portalrh.rhadmin.consumos.rejeitado");
      case "PENDENTE":
        return t("portalrh.rhadmin.consumos.pendente");
      default:
        return "";
    }
  };

  const buildStatusColumn = (_, row, index) => {
    if (row.status) {
      const variant = getColorByStatus(row.status);
      const translatedStatus = translateStatus(row.status);
      return (
        <Badge pill variant={variant}>
          {t(translatedStatus)}
        </Badge>
      );
    }
    return "";
  };

  const translateText = (textField, short = false) => {  
    return short && textField.length > 30 ? `${textField.substring(0, 30)}...` : textField;
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "80px" };
      },
      hidden: true,
    },
    {
      dataField: "descricao",
      text: t("portalrh.rhadmin.consumos.description"),
      sort: true,
      hidden: isMobile
    },
    {
      dataField: "mobileCard",
      sort: false,
      text: "",
      formatter: (cellContent, row, rowIndex, formatExtraData) => {
        return (
          <MobileCard
          row={row}
          cellContent={cellContent}
          rowIndex={rowIndex}
            description={row.descricao}
            date={row.data}
            value={row.valor}
            buildStatusColumn={buildStatusColumn}
          />
        );
      },
      formatExtraData: {
        headers: (columns || []).map(column => ({
          ...column,
          formatExtraData: column.formatExtraData || {}
        })),
        formatters: {
          toLocaleDateString,
          translateText,
          buildStatusColumn
        },
      },
      hidden: isMobile ? false : true,
    },
    {
      dataField: "data",
      text: t("portalrh.rhadmin.consumos.date"),
      sort: true,
      formatter: (cell) => moment(cell).format("DD/MM/YYYY HH:mm"),
      hidden: isMobile
    },
    {
      dataField: "valor",
      text: t("portalrh.rhadmin.consumos.value"),
      sort: true,
      formatter: (cell) => `€${cell.toFixed(2)}`,
      hidden: isMobile
    },
    {
      dataField: "status",
      text: t("portalrh.rhadmin.consumos.status"),
      sort: true,
      formatter: buildStatusColumn,
      hidden: isMobile
    },
  ];

  const dropdownListConsumos = [
    { label: t("portalrh.rhadmin.consumos.all"), value: "0", selected: value === "0" },
    { label: t("portalrh.rhadmin.consumos.aprovado"), value: "1", selected: value === "1" },
    { label: t("portalrh.rhadmin.consumos.rejeitado"), value: "2", selected: value === "2" },
    { label: t("portalrh.rhadmin.consumos.pendente"), value: "3", selected: value === "3" },
  ];
  
  const handleChange = (newValue) => {
    setValue(newValue);
    let filteredList;
    switch (newValue) {
      case "1":
        filteredList = consumosList.adiantamentos.filter(
          (consumo) => consumo.status === "APROVADO"
        );
        break;
      case "2":
        filteredList = consumosList.adiantamentos.filter(
          (consumo) => consumo.status === "REJEITADO"
        );
        break;
      case "3":
        filteredList = consumosList.adiantamentos.filter(
          (consumo) => consumo.status === "PENDENTE"
        );
        break;
      default:
        filteredList = consumosList.adiantamentos;
    }
    setTableValues(filteredList);
  };

  const { SearchBar } = Search;
  return (
    <>
      <div className="custom-table">
        <ToolkitProvider
          keyField="id"
          data={tableValues}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div style={{ paddingBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Row style={{width: "100%"}}>
                  <Col lg="4">
                    <SearchBar
                      {...props.searchProps}
                      placeholder={t("portalrh.rhadmin.consumos.searchInput")}
                    />
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "inline-flex",
                        marginTop: isMobile ? "1rem" : "",
                      }}
                    >
                      <Form.Label style={{ marginRight: "10px" }}>
                        {t("portalrh.rhadmin.consumos.filtro")}
                      </Form.Label>
                      <Dropdown
                        haveWidth={true}
                        width="100%"
                        list={dropdownListConsumos}
                        handleSubmit={handleChange}
                        emptyDefault={true}
                        resetDropdown={cleanDropdown}
                        setResetDropdown={setCleanDropdown}
                        flowId={value}
                        orderedAlphabetical={true}
                      />
                    </div>
                  </Col>
                </Row>                
              </div>
              <Row>
                  <Col>
                  <div style={{ display: "flex", gap: "3rem", marginBottom: "2rem" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                    <span>{t("portalrh.rhadmin.consumos.saldoAprovado")}:</span>
                    <Chip label={saldoAprovado} color="success"  style={{ marginLeft: "5px" }} />
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                    <span>{t("portalrh.rhadmin.consumos.saldoPendente")}:</span>
                    <Chip label={saldoPendente} style={{ marginLeft: "5px" }} />
                  </div>
                </div>
                  </Col>
                </Row>
              {!isLoadingConsumosList && (
                <BootstrapTable
                  {...props.baseProps}
                  responsive
                  bootstrap4
                  pagination={paginationFactory(
                    isMobile ? mobilePagination : pagination
                  )}
                  noDataIndication={t("portalrh.rhadmin.consumos.emptyTable")}
                  striped={isMobile ? false : true}
                  hover={isMobile ? false : true}
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};

export default withNamespaces()(ConsumosTable);
