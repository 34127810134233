import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";

import { toastInititalState } from "./actions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";


const Toast = (props) => {
  const { t } = props;
  const dispatch = useDispatch();

  const {
    activeSuccess,
    successMessage,
    activeWarning,
    warningMessage,
    activeError,
    errorMessage,
    infoLogin,
    infoLoginMessage,
  } = useSelector((state) => state.toastsReducer);

  useEffect(() => {
    if (activeSuccess) {
      toast.success("✔️ " + t(successMessage), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    dispatch(toastInititalState());
  }, [activeSuccess]);

  useEffect(() => {
    if (activeWarning) {
      toast.warning("⚠️ " + t(warningMessage), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    dispatch(toastInititalState());
  }, [activeWarning]);

  useEffect(() => {
    if (activeError) {
      toast.error("❌ " + t(errorMessage), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    dispatch(toastInititalState());
  }, [dispatch, activeError]);

  useEffect(() => {
    if (infoLogin) {
      const { startDate, expectedDuration } = infoLoginMessage;
      const formattedStartDate = moment(startDate, "HH:mm");
      const expectedDurationTemp = formattedStartDate.add(expectedDuration, 'hours').format("HH:mm");
      const manutencao1 = t('general.manutencao1');
      const manutencao2 = t('general.manutencao2');
      const message = `${manutencao1} ${expectedDurationTemp} ${manutencao2}`;
  
      toast.info("ℹ️ " + message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    dispatch(toastInititalState());
  }, [infoLogin]);
  
  return <ToastContainer />;
  
};

export default withNamespaces()(Toast);
