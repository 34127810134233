import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";
import { Card } from "react-bootstrap";
import TableList from "./TableList";
import { withNamespaces } from "react-i18next";
import { getCollaborators } from "~/store/ducks/portalrh/actionTypes";

const RhPlafonds = ({ portalRhMenus, designacao, path, t }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCollaborators());
  }, [dispatch]);


  return (
    <div style={{ display: "flex", height: "100%" }}>
      {/* <Sidebar menuSideBar={menuSideBar} path={path} /> */}
      <div
        className="main-card-v2"
        style={{ overflow: "auto", scrollY: "auto" }}
      >
        <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
          <Card.Header className="justify-content-between">
            <h6>{t("portalrh.rhadmin.plafonds.cardTitle")}</h6>
          </Card.Header>
          <Card.Body>
            <TableList />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
export default withNamespaces()(RhPlafonds);
