import { put, call } from "redux-saga/effects";
import http from "~/utils/config/http";
import axios from "axios";
import he from "he";
import { activeError, activeSuccess } from "~/components/Messages/actions";
import { parseJsonFromString, hasError } from "~/utils";
import oauth from "~/utils/oauth";
import {
  BPM_MIDDLEWARE,
  IFLOW_APP,
  PORTALRH_MIDDLEWARE,
  RH_MIDDLEWARE,
} from "~/utils/constants";
import {
  setProcesses,
  setProcessesHistory,
  setProcessesCountTotal,
  setProcessesCount,
  setHistory,
  setIflowFormdata,
  setIflowDocuments,
  resetIflowDocuments,
  setIflowTriggerLoading,
  setIflowDone,
  setIflowMetadataPreview,
  setIflowPidScheduler,
  setCompaniesNewCollab,
  setAdmissaoCollabMessage,
  setAdmissaoCollabMessageAlreadyExists,
  setIsSubstitutingIflowDocumentDone,
  setTemplatesList,
  updateTemplateProfileDone,
  setTemplateDetail,
  setTemplateDocumentsAll,
  setCatalogoFormacao,
  setUploadPlanoFormacao,
  setDownloadPlanoFormacao,
  setCatalogoFormacaoDropdowns,
  updateCreateCatalogoFormacaoDone,
  setEntitiesFormacao,
  setDeleteCatalogoFormacaoMessage,
  setConsumosList,
} from "./actionTypes";
const devLogConsole = require("~/utils/devLog");
export function* findIflowProcessesByFilters({ payload }) {
  try {
    const resp = yield http.post(
      "/validprocs/process",
      payload,
      BPM_MIDDLEWARE
    );
    yield put(setProcesses(resp.data));
    yield put(setProcessesCount(resp.data.length));
  } catch (e) {
    yield put(activeError("datacapture.general.errorMessage"));
  }
}

export function* findIflowProcessesHistory({ payload }) {
  try {
    const resp = yield http.post(
      "/validprocs/process-history",
      payload,
      BPM_MIDDLEWARE
    );
    yield put(setProcessesHistory(resp.data));
    devLogConsole("findIflowProcessesByFilters", resp.data);
  } catch (e) {
    yield put(activeError("datacapture.general.errorMessage"));
  }
}
export function* findIflowProcessesCount({ payload }) {
  try {
    const resp = yield http.post(
      "/validprocs/process-count",
      payload,
      BPM_MIDDLEWARE
    );
    yield put(setProcessesCountTotal(resp.data));
  } catch (e) {
    yield put(activeError("datacapture.general.errorMessage"));
  }
}

export function* postAdmissaoCollab({ data }) {
  try {
    const resp = yield http.post(
      "/api/rh/admissaoFuncionario?criacaoSimplificada=true",
      data,
      RH_MIDDLEWARE
    );
    yield put(setAdmissaoCollabMessage(resp.data));
    yield put(setAdmissaoCollabMessageAlreadyExists(false));
  } catch (error) {
    devLogConsole(error);
    yield put(setAdmissaoCollabMessageAlreadyExists(true));
    yield put(setAdmissaoCollabMessage(null));
  }
}

export function* getCompaniesNewCollab() {
  try {
    const resp = yield http.get("/api/entidades", RH_MIDDLEWARE);
    yield put(setCompaniesNewCollab(resp.data));
  } catch (error) {
    yield put(setCompaniesNewCollab(null));
  }
}

export function* findIflowProcessesByHistory({ payload }) {
  try {
    const resp = yield http.post(
      "/validprocs/process-history",
      payload,
      BPM_MIDDLEWARE
    );
    yield put(setHistory(resp.data));
  } catch (e) {
    yield put(activeError("messages.error"));
  }
}

//iFlow/inicio_flow.jsp?

export function* getIflowFormdata({ data, isNewForm }) {
  try {
    let resp = yield http.get(`${data.url}`, IFLOW_APP);
    yield put(setIflowTriggerLoading(true));

    //NOVA TENTATIVA DO FORM CASO DE SESSION EXPIRED
    if (
      resp.data.includes("session") ||
      resp.data.responseMessage?.includes("session")
    ) {
      let originalUrl = data.url;
      let urlWithoutAuth = originalUrl.replace(/(&?Authorization=[^&]*)/, "");
      let updatedUrl =
        urlWithoutAuth + "&Authorization=" + oauth.getAccessToken();
      resp = yield http.get(updatedUrl, IFLOW_APP);
      yield put(setIflowTriggerLoading(true));
    }

    if (isNewForm) {
      yield put(setIflowFormdata(resp.data));
    } else {
      if (typeof resp?.data === "string" && resp?.data?.includes(`{"form" :`)) {
        try {
          let obj = parseJsonFromString(resp.data);
          yield put(setIflowFormdata(obj));
        } catch (error) {
          devLogConsole(error);
          yield put(activeError("datacapture.general.errorMessage"));
          yield put(setIflowFormdata(false));
        }
      } else {
        yield put(
          setIflowFormdata({
            responseMessage: resp.data,
            url: resp?.request?.responseURL,
          })
        );
      }
    }
  } catch (e) {
    devLogConsole(e);
    yield put(activeError("datacapture.general.errorMessage"));
    const { response } = e;
    if (hasError(response.data)) {
      let arr = response.data.split(`<div class="error_msg">`);
      let arr1 = arr[1].split("</div>");
      yield put(
        setIflowFormdata({
          responseMessage: arr1[0],
          url: response?.request?.responseURL,
        })
      );
    } else {
      yield put(setIflowFormdata(false));
    }
  }
}

//iFlow/Form/form.jsp?
export function* postIflowFormData({ formdata, url, isNewForm }) {
  try {
    const resp = yield http.post(url, formdata, IFLOW_APP);

    if (isNewForm) {
      yield put(setIflowFormdata(resp.data));
    } else {
      if (resp.data.includes(`{"form" :`)) {
        try {
          let obj = parseJsonFromString(resp.data);
          yield put(setIflowFormdata(obj));
        } catch (error) {
          yield put(activeError(error));
          yield put(setIflowFormdata(null));
        }
      } else {
        yield put(
          setIflowFormdata({
            responseMessage: resp.data,
            url: resp?.request?.responseURL,
          })
        );
      }
    }
  } catch (e) {
    devLogConsole(e);
    yield put(activeError("datacapture.general.errorMessage"));
    const { response } = e;
    if (response?.data?.includes(`<div class="error_msg">`)) {
      let arr = response.data.split(`<div class="error_msg">`);
      let arr1 = arr[1].split("</div>");
      yield put(
        setIflowFormdata({
          responseMessage: arr1[0],
          url: response?.request?.responseURL,
        })
      );
    } else {
      yield put(setIflowFormdata(false));
    }
  }
}

export function* postIflowForm({ payload, url }) {
  try {
    const resp = yield http.post(url, payload, IFLOW_APP);
    yield put(setIflowPidScheduler(resp.data));
  } catch (e) {
    devLogConsole(e);
    yield put(setIflowPidScheduler({}));
  }
}
//iFlow/document?
export function* getIflowDocument({ data }) {
  try {
    yield put(resetIflowDocuments());
    const resp = yield axios.get(`${data.url}`, { responseType: "blob" });
    yield put(setIflowDocuments(resp.data, data.docid, data.filename));
  } catch (e) {
    yield put(activeError("messages.error"));
    yield put(resetIflowDocuments());
  }
}

//iFlow/DocumentService?
export function* substituteIflowDocument({ url, data, updateCoords }) {
  let error;
  for (let i = 0; i < 2; i++) {
    error = "";
    try {
      yield put(setIsSubstitutingIflowDocumentDone(true));
      const resp = yield http.post(url, data, IFLOW_APP);

      if (
        resp.status === 200 &&
        updateCoords.docid &&
        updateCoords.numpages &&
        updateCoords.x &&
        updateCoords.y &&
        updateCoords.flowId &&
        updateCoords.pid &&
        updateCoords.subpid
      ) {
        try {
          const response = yield http.get(`/jsessionidretriever`, IFLOW_APP);
          if (response?.data?.jsessionId) {
            yield http.post(`/api/signature-coordinates/save`, {
              jsessionId: response?.data?.jsessionId,
              docId: updateCoords.docid,
              flowid: updateCoords.flowId,
              pid: updateCoords.pid,
              subpid: updateCoords.subpid,
              hash: updateCoords.isHash,
              pageNumber: updateCoords.numpages,
              newPosX: updateCoords.x,
              newPosY: updateCoords.y,
            });
          }
        } catch (e) {
          devLogConsole(e);
        }
        yield put(activeSuccess("general.successSignedDocument"));
      } else {
        yield put(activeSuccess("general.successSignedDocument"));
      }
      yield put(setIsSubstitutingIflowDocumentDone(false));
      break;
    } catch (e) {
      error = e;
    }
  }

  if (error) {
    yield put(setIsSubstitutingIflowDocumentDone(false));
    yield put(
      activeError(
        `Não conseguimos substituir o documento  ${updateCoords.docid}`
      )
    );
  }
}
//Form/detail.jsp?flowid=${flowid}&pid=${pNumber}&subpid=${subPid}&procStatus=-4&fwSearch=true&uri=Forward/forward.jsp&op=10`;
export function* getIflowMetadataPreview({ data }) {
  try {
    const resp = yield http.get(`${data.url}`, IFLOW_APP);
    try {
      if (typeof resp.data === "object") {
        yield put(setIflowMetadataPreview(resp.data));
      } else if (typeof resp.data === "string") {
        //Remove trailing comma from last object
        const regex = /\,(?!\s*?[\{\[\"\'\w])/g;
        //Remove All Line Breaks from a String
        let transformedString = resp?.data
          ?.replace(/(\r\n|\n|\r|\t)/gm, "")
          ?.replace(/\\/g, "\\\\")
          ?.replace(regex, "");
        let obj = JSON.parse(transformedString);

        /*
        let testData =
          '{"metadata": [ { "name": "Título", "value": "Atribuição de apoios económicos a cuidados de saúde" }, { "name": "Assunto", "value": "teste" }, { "name": "Nome do Cliente Municipal", "value": "" }, { "name": "NIF", "value": "" }, { "name": "Tramitação", "value": { "row": [ {"col": [ { "header": "true", "value": "Ordem" }, { "header": "true", "value": "Acção" }, { "header": "true", "value": "Por" }, { "header": "true", "value": "Em" } ] }, {"col": [ { "header": "false", "value": "1" }, { "header": "false", "value": "Informado" }, { "header": "false", "value": "alexandra.rodrigues" }, { "header": "false", "value": "2022-10-10 21:42" } ]}, {"col": [ { "header": "false", "value": "2" }, { "header": "false", "value": "Encaminhado para teresa.lopes" }, { "header": "false", "value": "alexandra.rodrigues" }, { "header": "false", "value": "2022-10-14 08:41" } ]} ] } } ] }';

        testData = testData?.replace(regex, "");
        let obj = JSON.parse(testData);*/

        yield put(setIflowMetadataPreview(obj));
      }
    } catch (error) {
      devLogConsole(error);
      yield put(setIflowMetadataPreview(null));
    }
  } catch (e) {
    devLogConsole(e);
    yield put(setIflowMetadataPreview(null));
  }
}
export function* getTemplatesList() {
  try {
    const resp = yield http.post("/api/funcoes/all", null, PORTALRH_MIDDLEWARE);
    yield put(setTemplatesList(resp.data));
  } catch (error) {
    yield put(setTemplatesList([]));
  }
}

export function* getCatalogoFormacao() {
  try {
    const resp = yield http.get("/api/formacao/all", RH_MIDDLEWARE);
    yield put(setCatalogoFormacao(resp.data));
  } catch (error) {
    yield put(setCatalogoFormacao([]));
  }
}
export function* getCatalogoFormacaoDropdowns() {
  try {
    const resp = yield http.get(
      "/api/formacao/find/configurations",
      RH_MIDDLEWARE
    );
    yield put(setCatalogoFormacaoDropdowns(resp.data));
  } catch (error) {
    yield put(setCatalogoFormacaoDropdowns([]));
  }
}

export function* deleteCatalogoFormacao({ id }) {
  try {
    yield http.delete(`/api/formacao/delete/${id}`, RH_MIDDLEWARE);
    yield put(setDeleteCatalogoFormacaoMessage(""));
  } catch (error) {
    yield put(
      setDeleteCatalogoFormacaoMessage(
        "portalrh.rhadmin.gestaoTemplates.formacaoWithUsers"
      )
    );
    yield put(activeError("general.error"));
  }
}

export function* getEntitiesFormacao() {
  try {
    const resp = yield http.get("/api/entidades", RH_MIDDLEWARE);
    yield put(setEntitiesFormacao(resp.data));
  } catch (error) {
    yield put(setEntitiesFormacao([]));
  }
}

export function* getPlanoFormacao({ payload }) {
  try {
    const resp = yield http.post(
      `/api/training-sheet/read-excel?empCode=${payload.empCode}`,
      {},
      RH_MIDDLEWARE
    );
    let binary = atob(resp.data);
    let array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    let blob = new Blob([new Uint8Array(array)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    let linkElement = document.createElement("a");
    linkElement.href = URL.createObjectURL(blob);
    linkElement.download = "plano_formacao_colaborador.xlsx";
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
    yield put(setDownloadPlanoFormacao(true));
  } catch (error) {
    yield put(setDownloadPlanoFormacao(false));
  }
}

export function* uploadPlanoFormacao({ payload }) {
  try {
    const resp = yield http.post(
      `/api/training-sheet/import-excel?empCode=${payload.empCode}`,
      payload,
      RH_MIDDLEWARE
    );
    yield put(setUploadPlanoFormacao(true));
  } catch (error) {
    yield put(setUploadPlanoFormacao(false));
  }
}

export function* updateTemplateProfile({ payload }) {
  try {
    const dataToSend = {
      funcaoDto: { descricao: payload.descricao, ativo: payload.ativo },
      documentos: payload.documentos,
    };
    yield http.post(
      `/api/funcoes/documentos/${payload.id}`,
      dataToSend,
      PORTALRH_MIDDLEWARE
    );
    yield put(updateTemplateProfileDone());
  } catch (error) {
    yield put(updateTemplateProfileDone());
  }
}
export function* updateCreateCatalogoFormacao({ payload }) {
  try {
    yield http.post(`/api/formacao/save`, payload, RH_MIDDLEWARE);
    yield put(updateCreateCatalogoFormacaoDone());
  } catch (error) {
    yield put(updateCreateCatalogoFormacaoDone());
  }
}

export function* getTemplateDetail({ id }) {
  try {
    if (id) {
      const resp = yield http.get(
        `/api/funcoes/documentos/${id}`,
        PORTALRH_MIDDLEWARE
      );
      yield put(setTemplateDetail(resp.data));
    } else {
      const resp = yield http.get(
        `/api/document-class/all`,
        PORTALRH_MIDDLEWARE
      );
      yield put(setTemplateDocumentsAll(resp.data));
    }
  } catch (error) {
    yield put(setTemplateDetail([]));
    yield put(setTemplateDocumentsAll([]));
  }
}

export function* deleteTemplate({ id }) {
  try {
    yield http.delete(`/api/delete-funcao/${id}`, PORTALRH_MIDDLEWARE);
  } catch (error) {
    yield put(activeError("general.error"));
  }
}

export function* getConsumosList({ idUser }) {
  try {
    const resp = yield http.get(
      `/api/get-user-adiantamentos?userid=${idUser}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setConsumosList(resp.data));
  } catch (error) {
    yield put(setConsumosList([]));
  }
}
