import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  getCollaborators,
  getScheduler,
} from "~/store/ducks/portalrh/actionTypes";
import Sidebar from "../RhCollaborator/Sidebar";
import Dropdown from "~/components/Dropdown";
import SpinnerNew from "~/components/SpinnerNew";
import { Button, Stack } from "@mui/material";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import {
  Card,
  Row,
  Col,
  Tabs,
  Tab,
  Form,
  Button as ButtonBoot,
  Badge,
} from "react-bootstrap";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { FaSearchMinus, FaPrint } from "react-icons/fa";
import {
  getRHHorasExtraUsersGroup,
  getRHSaldoHorasExtra,
} from "~/store/ducks/rhadminConfiguration/actionTypes";
import {
  clearRHSaldoHorasExtra,
  clearRHSaldoInicialFinalHorasExtra,
} from "~/store/ducks/rhadminConfiguration/actionTypes";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { toLocaleDateString } from "~/utils";
import FlowModal from "~/components/FlowModal";
import { mobilePagination } from "~/components/pagination/currentSizePerPage/mobilePagination";
import Filters from "./Filters";
import MobileCard from "./MobileCard";

const HorasExtra = ({
  portalRhMenus,
  designacao,
  path,
  showDrop,
  t,
  handleOpenFlowModal,
  handleCloseFlowModal,
  selectedProcess,
  showFlowModal,
}) => {
  const {
    rhHorasExtraUsersGroup,
    saldoHorasExtraBolsa,
    saldoHorasExtraBolsaInicialFinal,
    isLoadingSaldo,
  } = useSelector((state) => state.rhadminConfigurationReducer);
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { collaborators } = useSelector((state) => state.portalrhReducer);

  const dispatch = useDispatch();

  const [menuSideBar, setMenuSideBar] = useState(null);
  const [grupos, setgrupos] = useState("");
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [activeButton, setActiveButton] = useState("");
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [saldosHours_inicialFinal, setSaldosHours_inicialFinal] = useState("");
  const [saldos_hours, setSaldos_hours] = useState([]);
  const [saldos_hoursCopy, setSaldos_hoursCopy] = useState([]);
  const [hiddenDefaultColumns, setHiddenDeafultColumns] = useState(undefined);
  const [field, setField] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [userNamePortalRH, setuserNamePortalRH] = useState(undefined);
  const [callDetail, setCallDetail] = useState(false);
  // const [isMobile, setIsMobile] = useState(
  //   window.innerWidth < 768 && configuration && configuration.mobileReady
  // );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [haveFilters, setHaveFilters] = useState(false);
  const [dateVal, setDateVal] = useState("");

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    dispatch(getCollaborators());
    portalRhMenus.map((menu) => {
      if (menu.designacao == designacao) {
        setMenuSideBar(menu);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const userRH = collaborators.find((collaborator) => {
      return user.legacyUsers.some((legacyUser) => {
        return (
          legacyUser.username === collaborator.username &&
          legacyUser.legacyApplication.label === "portalrh"
        );
      });
    });
    setuserNamePortalRH(userRH);
  }, [collaborators]);

  useEffect(() => {
    if (showDrop) {
      dispatch(clearRHSaldoHorasExtra());
      dispatch(clearRHSaldoInicialFinalHorasExtra());
      dispatch(getRHHorasExtraUsersGroup());
    }
    setSelectedUser(undefined);
    setgrupos("");
    setActiveButton("");
  }, []);

  const getRHSaldos = (date, id, filterDate = "") => {
    const startOfMonth = moment(date, "YYYY-MM-DD")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = moment(date, "YYYY-MM-DD")
      .endOf("month")
      .format("YYYY-MM-DD");

    const payload = {
      dateinicio: startOfMonth,
      dateFim: endOfMonth,
      ids: [id],
    };

    if (!showDrop || (grupos !== "" && selectedUser)) {
      dispatch(getRHSaldoHorasExtra(payload));
    }
    setDateVal(filterDate);
    setHaveFilters(!!filterDate);
  };

  useEffect(() => {
    if (!showDrop) {
      if (userNamePortalRH != undefined) {
        getRHSaldos(
          createDatetoDispatch(new Date()),
          userNamePortalRH.funcionarioNum
        );
      }
    }
  }, [userNamePortalRH]);

  useEffect(() => {
    setSaldos_hours(saldoHorasExtraBolsa);
    setSaldos_hoursCopy(saldoHorasExtraBolsa);
    setSaldosHours_inicialFinal(saldoHorasExtraBolsaInicialFinal);
  }, [saldoHorasExtraBolsa, saldoHorasExtraBolsaInicialFinal]);

  useEffect(() => {
    if (selectedUser != undefined && grupos != "") {
      getRHSaldos(
        createDatetoDispatch(new Date()),
        selectedUser.numFuncionario
      );
    }
  }, [selectedUser]);

  const createDatetoDispatch = (dataAtual) => {
    const ano = dataAtual.getFullYear();
    let mes = (dataAtual.getMonth() + 1).toString().padStart(2, "0"); // +1 porque os meses começam em zero
    let dia = dataAtual.getDate().toString().padStart(2, "0");

    const novaDataFormatada = `${ano}-${mes}-${dia}`;

    return novaDataFormatada;
  };

  const handleClearFilter = () => {
    setSelectedUser(undefined);
    setgrupos("");
    setActiveButton("");
    setCleanDropdown(true);
    setDateVal("");
    setHaveFilters(false);
    dispatch(clearRHSaldoHorasExtra());
    dispatch(clearRHSaldoInicialFinalHorasExtra());
    if (userNamePortalRH != undefined) {
      getRHSaldos(
        createDatetoDispatch(new Date()),
        userNamePortalRH.funcionarioNum
      );
    }
  };

  const handleChooseUser = (user) => {
    setSelectedUser(user);
    setActiveButton(user.nomeCurto);
  };

  const handleStatusSort = (field) => {
    setOrder((order) => (order === "desc" ? "asc" : "desc"));
    setField(field);
  };

  const isHidden = (column) => {
    if (hiddenDefaultColumns) {
      return hiddenDefaultColumns.some((c) => c === column) ? true : false;
    } else return false;
  };

  const ChecksFormatter = (cell, row, rowIndex, formatExtraData) => {
    let value = undefined;
    if (row[formatExtraData.value] == null) {
      value = "00:00";
    } else {
      value = row[formatExtraData.value];
    }
    return value;
  };

  const getColorByStatus = (status) => {
    switch (status) {
      case "APROVADO":
        return "success";
      case "REJEITADO":
        return "danger";
      case "PENDENTE":
        return "warning";
      default:
        return "danger";
    }
  };

  const translateStatus = (status) => {
    switch (status) {
      case "APROVADO":
        return t("portalrh.rhadmin.consumos.aprovado");
      case "REJEITADO":
        return t("portalrh.rhadmin.consumos.rejeitado");
      case "PENDENTE":
        return t("portalrh.rhadmin.consumos.pendente");
      default:
        return "Nao processado";
    }
  };
  const buildProcessadoColumn = (_, row, index) => {
    const variant = getColorByStatus(row.processado);
    const translatedStatus = translateStatus(row.processado);
    return (
      <Badge pill variant={variant}>
        {t(translatedStatus)}
      </Badge>
    );
  };

  const columns = [
    {
      dataField: "mobileCard",
      sort: false,
      text: "",
      formatter: (cellContent, row, rowIndex, formatExtraData) => {
        return (
          <MobileCard
            row={row}
            cellContent={cellContent}
            rowIndex={rowIndex}
            buildProcessadoColumn={buildProcessadoColumn}
          />
        );
      },
      formatExtraData: {
        headers: (columns || []).map((column) => ({
          ...column,
          formatExtraData: column.formatExtraData || {},
        })),
      },
      hidden: isMobile ? false : true,
    },
    {
      dataField: "entidade",
      text: t("portalrh.horasExtra.entidade"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) => row.entidade || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "numeroFuncionario",
      text:  t("portalrh.horasExtra.numeroFuncionario"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) => row.numeroFuncionario || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "dataEfectivacao",
      text:  t("portalrh.horasExtra.dataEfectivacao"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) =>
        new Date(row.dataEfectivacao).toLocaleDateString() || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "hrInicio",
      text: t("portalrh.horasExtra.hrInicio"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) =>
        row.hrInicio
          ? new Date(row.hrInicio).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })
          : "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "hrFim",
      text: t("portalrh.horasExtra.hrFim"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) =>
        row.hrFim
          ? new Date(row.hrFim).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })
          : "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "subRefeicao",
      text: t("portalrh.horasExtra.subRefeicao"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) => row.subRefeicao || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "horasA",
      text: t("portalrh.horasExtra.firstHoras"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) => row.horasA || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "horasB",
      text: t("portalrh.horasExtra.secondHora"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) => row.horasB || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "horasC",
      text: t("portalrh.horasExtra.descansoComplementar"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) => row.horasC || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "horasD",
      text: t("portalrh.horasExtra.descansoObrigatorio"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) => row.horasD || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "horasD2",
      text: t("portalrh.horasExtra.descansoFeriado"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) => row.horasD2 || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "horasE",
      text: t("portalrh.horasExtra.primeiraHoraNocturna"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) => row.horasE || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "horasE2",
      text: t("portalrh.horasExtra.horasNocturnasSeguintes"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) => row.horasE2 || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "horasF",
      text: t("portalrh.horasExtra.horasExtrasAlemLimite"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) => row.horasF || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "mesProcessamento",
      text: t("portalrh.horasExtra.mesProcessamento"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) => row.mesProcessamento || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "processado",
      text: t("portalrh.horasExtra.processado"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: buildProcessadoColumn,
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
    {
      dataField: "dtCri",
      text: t("portalrh.horasExtra.dataCriacao"),
      sort: true,
      hidden: isMobile ? true : false,
      formatter: (_, row) => new Date(row.dtCri).toLocaleDateString() || "----",
      style: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
      headerStyle: () => ({
        textAlignLast: "center",
        breakWord: "break-all",
      }),
    },
  ];

  const dropdownListgrupos = rhHorasExtraUsersGroup.map((item) => {
    return {
      label: item.grupo.designacao,
      value: item.grupo.designacao,
      selected: item.grupo.designacao === grupos,
    };
  });

  const handleSubmitGrupos = (e) => {
    if (grupos != e) {
      setSelectedUser(undefined);
      setActiveButton("");
    }
    setgrupos(e);
  };

  const handleRowClick = (row) => {
    handleOpenFlowModal(row);
    setCallDetail(true);
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.stopPropagation();
      e.preventDefault();
      handleRowClick(row);
    },
  };

  const { SearchBar } = Search;

  const tableContrutor = () => {
    return (
      <>
        {showDrop && (
          <Row style={{ alignItems: "center" }}>
            <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{ padding: "0px" }}>
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Form.Label
                  style={{ marginRight: "10px", marginBottom: "1rem" }}
                >
                  {t("portalrh.scheduler.groups")}
                </Form.Label>
                <Dropdown
                  haveWidth={true}
                  width={"100%"}
                  list={dropdownListgrupos}
                  handleSubmit={handleSubmitGrupos}
                  emptyDefault={true}
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                />
              </div>
            </Col>
            {grupos !== "" && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                margin="0px"
              >
                <ButtonBoot
                  className="card-button"
                  onClick={handleClearFilter}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <FaSearchMinus />
                  {t("portalrh.scheduler.clean")}
                </ButtonBoot>
              </Stack>
            )}
            {grupos !== "" && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                margin="0px"
                display="block"
                className="scrollBar-visible"
                maxHeight="6.5rem"
                overflow={"auto"}
              >
                {rhHorasExtraUsersGroup
                  .filter((group) => group.grupo.designacao === grupos)
                  .map((group) =>
                    group.userList.map((user) => (
                      <ButtonBoot
                        className={`button-scheduler ${
                          activeButton === user.nomeCurto ? "active" : ""
                        }`}
                        onClick={() => handleChooseUser(user)}
                      >
                        {user.nomeCurto}
                      </ButtonBoot>
                    ))
                  )}
              </Stack>
            )}
          </Row>
        )}
        <Filters
          userNamePortalRH={userNamePortalRH}
          selectedUser={selectedUser}
          createDatetoDispatch={createDatetoDispatch}
          getRHSaldos={getRHSaldos}
          setSaldos_hours={setSaldos_hours}
          saldos_hoursCopy={saldos_hoursCopy}
          saldos_hours={saldos_hours}
          isMobile={isMobile}
          dateVal={dateVal}
          setDateVal={setDateVal}
          haveFilters={haveFilters}
          setHaveFilters={setHaveFilters}
          handleReset={handleClearFilter}
          grupos={grupos}
        />
        <div className="custom-table task-table">
          <ToolkitProvider
            keyField="timeSheetWeekId"
            data={saldos_hours != undefined ? saldos_hours : []}
            columns={columns}
            search
          >
            {(props) => (
              <div style={{ paddingBottom: "15px", marginTop: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <SearchBar
                    {...props.searchProps}
                    style={{ paddingLeft: "20px" }}
                    placeholder={t("general.search")}
                  />
                </div>
                <div>
                  <Row style={{ paddingRight: "0px" }}>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                      {saldosHours_inicialFinal != "" && (
                        <p>
                          {" "}
                          {t("portalrh.scheduler.horaTotal")}{" "}
                          <b>{saldosHours_inicialFinal}</b>
                        </p>
                      )}
                    </Col>
                  </Row>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    noDataIndication={t("portalrh.scheduler.noHoursfound")}
                    sort={{
                      dataField: field,
                      order: order,
                    }}
                    pagination={paginationFactory(
                      isMobile ? mobilePagination : pagination
                    )}
                    striped={isMobile ? false : true}
                    hover={true}
                    rowEvents={rowEvents}
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </div>
      </>
    );
  };

  return (
    <>
      {/* <Sidebar menuSideBar={menuSideBar} path={path} /> */}
      <div
        className="main-card-v2"
        style={{ overflow: "auto", scrollY: "auto" }}
      >
        <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
          <Card.Body>
            {isLoadingSaldo ? (
              <SpinnerNew open={isLoadingSaldo} />
            ) : (
              <>
                <>{tableContrutor()}</>
              </>
            )}
          </Card.Body>
        </Card>
      </div>
      <FlowModal
        showModal={showFlowModal}
        closeModal={handleCloseFlowModal}
        process={selectedProcess}
        isPortal={true}
        callDetail={callDetail}
        setCallDetail={setCallDetail}
      />
    </>
  );
};

export default withNamespaces()(HorasExtra);
