import React, { useState } from "react";
import "../assets/scss/_projectDescriptionSection.scss";
import "../assets/scss/_option.scss";
import TextAreaInput from "../components/TextAreaInput";
import iconPlus from "~/pages/Liftworld/assets/img/icon-plus-filled.png";
import iconPlusEmpty from "~/pages/Liftworld/assets/img/icon-plus.png";
import Modal from "~/pages/Easypay/components/Modal";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
import uuid from "uuid/v1";
import Textbox from "./Textbox";
import Input from "./Input";
import { useDispatch, useSelector } from "react-redux";
import { setAllWorkplans } from "~/store/ducks/budgetManagement/actionTypes";
import LiftworldHoursTable from "./LiftworldHoursTable";
import LiftworldSuppliersTable from "./LiftworldSuppliersTable";
import LiftworldCostsTable from "./LiftworldCostsTable";
import Checkbox from "./Checkbox";
import iconDelete from "../assets/img/icon-error-filled.png";

const Option = ({ info, index, itemIndex, activityIndex, workplanIndex }) => {
  const dispatch = useDispatch();

  const { workplans, providers, departments, expenditureTypes } = useSelector(
    (state) => state.budgetManagementReducer
  );

  const [showContent, setShowContent] = useState(true);
  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);

  function getRowType(type) {
    if (type === "horas") {
      return {
        idFuncao: 0,
        colaborador: 0,
        categoria: 0,
        departamentoHoras: 0,
        tarefa: "",
        horasPropostas: "",
        horasPropostasArray: [],
        valorAdicional: 0,
        custosHoras: 0,
        valorVendaHoras: 0,
        margemHoras: 0,
      };
    }
    if (type === "fornecedores") {
      return {
        tipoFornecedor: 0,
        fornecedorForn: 0,
        departamentoForn: 0,
        descricaoProdServ: "",
        precoCompraForn: 0,
        margemSemPrecoVendaForn: "",
        precoVendaForn: "",
        valorVendaForn: 0,
        margemForn: 0,
        prazoPagamento: 0,
      };
    }
    if (type === "despesas") {
      return {
        tipoFornecedor: 0,
        fornecedorForn: 0,
        departamentoForn: 0,
        descricaoProdServ: "",
        precoCompraForn: 0,
        margemSemPrecoVendaForn: 0,
        precoVendaForn: 0,
        valorVendaForn: 0,
        margemForn: 0,
      };
    }
  }

  const monthsBtwnDates = (startDate, endDate) => {

    if (startDate === null || endDate === null) return;
    var newStart = new Date(startDate);
    var newEnd = new Date(endDate);
    newStart = new Date(
      newStart.getTime() - newStart.getTimezoneOffset() * +60000
    );
    newEnd = new Date(newEnd.getTime() - newEnd.getTimezoneOffset() * +60000);

    var startStr = newStart.toISOString();
    var endStr = newEnd.toISOString();

    startStr = startStr.slice(0, 10);
    endStr = endStr.slice(0, 10);
    var start = startStr.split("-");
    var end = endStr.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];


    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]);
      var startMon = i === startYear ? parseInt(start[1]) : 0;

      for (var j = startMon; j <= endMonth; j++) {
        var month = j;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }

    return dates;
  };

  function createBodyError() {
    return (
      <div className="lift-modal-body-wrapper">
        <div>
          <ProvaDeVida text="Erro ao adicionar horas" status="error" />
          <div style={{ fontWeight: "bold" }}>
            Para adicionar horas precisa de preencher os campos Data Inicio de
            Projeto, Data Final de Projeto e Tipo de Projeto.
          </div>
        </div>
      </div>
    );
  }

  function addTableRow(type) {
    workplans[workplanIndex].resumoFinaceiro.toChange = 1;
    if (type === "horas") {
      const startDate = workplans[workplanIndex].dataInicioProjeto.toString();
      const endDate = workplans[workplanIndex].dataFinalProjeto.toString();
      if (
        startDate === null ||
        startDate === undefined ||
        endDate === null ||
        endDate === undefined
      ) {
        setIsModalErrorShowing(true);
        return;
      }
      const array = monthsBtwnDates(startDate, endDate);
      var hoursArray = [];
      if (array.length > 0) {
        for (var i = 0; i < array.length; i++) {
          const dateArray = array[i].split("-");
          const currentMonth = parseInt(dateArray[1]);
          hoursArray.push({
            month: currentMonth,
            year: parseInt(dateArray[0]),
            hoursValue: "00:00:00",
          });
        }
      }
    }
    if (itemIndex != null && activityIndex != null && index != null) {
      if (type === "horas") {
        var currentRow = getRowType(type);
        currentRow.horasPropostasArray = hoursArray;

        workplans[workplanIndex].items[itemIndex].atividades[
          activityIndex
        ].opcoes[index][type].push(currentRow);
        dispatch(setAllWorkplans(workplans));
      } else {
        workplans[workplanIndex].items[itemIndex].atividades[
          activityIndex
        ].opcoes[index][type].push(getRowType(type));
        dispatch(setAllWorkplans(workplans));
      }
    }
  }

  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 100,
      text: text,
    };
  }

  function showContentFunction() {
    setShowContent(!showContent);
  }

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".");
    const finalString = newArray[0].replace(",", ".") + "," + newArray[1];
    return finalString;
  }

  function deleteOption() {
    workplans[workplanIndex].resumoFinaceiro.toChange = 1;
    workplans[workplanIndex].items[itemIndex].atividades[
      activityIndex
    ].opcoes.splice(index, 1);
    dispatch(setAllWorkplans(workplans));
  }

  const hourList = [
    "Colaborador",
    "Categoria",
    "Departamento",
    "Função",
    "Tarefa",
    "Horas Propostas",
    "Valor Adicional Proposto(€)",
    "Custo Horas Propostas",
    "Valor de Venda Proposta",
    "Margem",
  ];
  const supplierList = [
    "Tipo de Fornecedor",
    "Fornecedor",
    "Departamento",
    "Descrição Prod./Serv",
    "Preço de Compra(€)",
    "Margem S/ Preço Venda(%)",
    "Preço de Venda(€)",
    "Valor de Venda",
    "Margem",
    "Prazo Pagamento",
  ];
  const costList = [
    "Tipo de Despesa",
    "Fornecedor",
    "Departamento",
    "Descrição",
    "Preço de Compra(€)",
    "Margem S/ Preço Venda(%)",
    "Preço de Venda(€)",
    "Valor de Venda",
    "Margem",
  ];
  return (
    <div className="liftworld-option-main-wrapper">
      <Modal
        className="easypay-modal"
        showModal={isModalErrorShowing}
        setShowModal={setIsModalErrorShowing}
        headerContent={"Erro"}
        bodyContent={createBodyError()}
        key={uuid()}
      ></Modal>
      <div className="liftworld-item-option-row-wrapper">
        <div style={{ display: "flex", width: "50%", alignItems: "center" }}>
          <div
            className="easypay-text-icon"
            onClick={() => showContentFunction()}
          >
            <img src={iconPlus} alt="Icon Check" />
          </div>
          <div style={{ width: "100%" }}>
            <Input
              field={buildInputField("Opção " + (index + 1), info.nomeOpcao)}
              key={uuid()}
              color={"option"}
              size={"large-size"}
              name={"nomeOpcao"}
              itemIndex={itemIndex}
              activityIndex={activityIndex}
              optionIndex={index}
              workplanIndex={workplanIndex}
            />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Textbox
            title={"Valor de Custo"}
            value={
              isNaN(info.valorCustoOpt)
                ? "0 €"
                : numberWithCommas(parseFloat(info.valorCustoOpt).toFixed(2)) +
                  " €"
            }
            size={"normal"}
            color={"option"}
          />
          <Textbox
            title={"Valor de Venda"}
            value={
              isNaN(info.valorVendaOpt)
                ? "0 €"
                : numberWithCommas(parseFloat(info.valorVendaOpt).toFixed(2)) +
                  " €"
            }
            size={"normal"}
            color={"option"}
          />
          <div className={"liftworld-checkbox-wrapper"}>
            <Checkbox
              value={""}
              name={"option"}
              checked={info.selected}
              workplanIndex={workplanIndex}
              activityIndex={activityIndex}
              itemIndex={itemIndex}
              optionIndex={index}
              isPrint={false}
            />
          </div>
          <div className="liftworld-delete-button-wrapper">
            <div className="easypay-text-icon" onClick={() => deleteOption()}>
              <i className="icon-trash table-action-icon" />
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "20px" }} />
      {showContent && (
        <div className="liftworld-option-content-wrapper">
          <div className="liftworld-item-option-row-wrapper-inputs">
            <div
              style={{ display: "flex", width: "20%", alignItems: "center" }}
            >
              <div style={{ width: "100%" }}>
                <Input
                  field={buildInputField("Quantidade ", info.quantidade)}
                  key={uuid()}
                  color={"option"}
                  size={"large-size"}
                  name={"quantidade"}
                  itemIndex={itemIndex}
                  activityIndex={activityIndex}
                  optionIndex={index}
                  workplanIndex={workplanIndex}
                />
              </div>
            </div>
            <div
              style={{ display: "flex", width: "25%", alignItems: "center" }}
            >
              <div style={{ width: "100%" }}>
                <Input
                  field={buildInputField(
                    "Nº Unidade Medida ",
                    info.nunidadeDeMedia
                  )}
                  key={uuid()}
                  color={"option"}
                  size={"large-size"}
                  name={"nunidadeDeMedia"}
                  itemIndex={itemIndex}
                  activityIndex={activityIndex}
                  optionIndex={index}
                  workplanIndex={workplanIndex}
                />
              </div>
            </div>
            <div
              style={{ display: "flex", width: "55%", alignItems: "center" }}
            >
              <div style={{ width: "100%" }}>
                <Input
                  field={buildInputField(
                    "Unidade de medida ",
                    info.unidadeDeMedia
                  )}
                  key={uuid()}
                  color={"option"}
                  size={"large-size"}
                  name={"unidadeDeMedia"}
                  itemIndex={itemIndex}
                  activityIndex={activityIndex}
                  optionIndex={index}
                  workplanIndex={workplanIndex}
                />
              </div>
            </div>
          </div>
          <div className="liftworld-objective-img-text-wrapper">
            <div className="liftworld-option-text-wrapper">HORAS</div>
          </div>
          <div className="liftworld-objective-row-wrapper">
            <div
              className="liftworld-plus-icon"
              onClick={() => addTableRow("horas")}
            >
              <img src={iconPlusEmpty} alt="Icon Check" />
            </div>
            <LiftworldHoursTable
              list={hourList}
              typeList={info.horas}
              optionIndex={index}
              activityIndex={activityIndex}
              itemIndex={itemIndex}
              workplanIndex={workplanIndex}
            />
          </div>
          <div className="liftworld-objective-img-text-wrapper">
            <div className="liftworld-option-text-wrapper">FORNECEDORES</div>
          </div>
          <div className="liftworld-objective-row-wrapper">
            <div
              className="liftworld-plus-icon"
              onClick={() => addTableRow("fornecedores")}
            >
              <img src={iconPlusEmpty} alt="Icon Check" />
            </div>
            <LiftworldSuppliersTable
              list={supplierList}
              typeList={info.fornecedores}
              optionIndex={index}
              activityIndex={activityIndex}
              itemIndex={itemIndex}
              workplanIndex={workplanIndex}
            />
          </div>
          <div className="liftworld-objective-img-text-wrapper">
            <div className="liftworld-option-text-wrapper">
              DESPESAS E CUSTOS ADMINISTRATIVOS
            </div>
          </div>
          <div className="liftworld-objective-row-wrapper">
            <div
              className="liftworld-plus-icon"
              onClick={() => addTableRow("despesas")}
            >
              <img src={iconPlusEmpty} alt="Icon Check" />
            </div>
            <LiftworldCostsTable
              list={costList}
              typeList={info.despesas}
              optionIndex={index}
              activityIndex={activityIndex}
              itemIndex={itemIndex}
              workplanIndex={workplanIndex}
              providers={providers}
              expenditureTypes={expenditureTypes}
              departments={departments}
            />
          </div>
          <div style={{ paddingBottom: "15px" }} />
          <TextAreaInput
            field={buildInputField("Observações (Reflete-se no Orçamento)", info.observaceosOpt)}
            key={uuid()}
            itemIndex={itemIndex}
            activityIndex={activityIndex}
            optionIndex={index}
            name={"observaceosOpt"}
            workplanIndex={workplanIndex}
            size={"small"}
          />
        </div>
      )}
      <div style={{ paddingBottom: "15px" }} />
    </div>
  );
};
export default Option;
