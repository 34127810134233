import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Modal } from "react-bootstrap";
import DocTemplate from "./DocTemplate";
import DateTemplate from "./DateTemplate";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { errorMessageVariable } from "~/store/ducks/udw/actionTypes";

const DeleteModal = (props) => {
  const dispatch = useDispatch();
  const {
    //Specific modal functionalities
    selectedEmailTemplate,
    showDeleteModal,
    closeDeleteModal,
    HandleDeleteEmailTemplate,
    //Email template data
    operations,
    selectedOperationId,
    periodicidadeListDropdown,
    handlePeriodicidadeSelect,
    periodicidade,
    fromTemplate,
    periodicidadeError,
    fromTemplateError,
    datasListDropdown,
    handleDatasSelect,
    datas,
    datasError,
    numberDays,
    setNumberDays,
    resetDropdown,
    setResetDropdown,
    resetDropdownPeriodicidade,
    setResetDropdownPeriodicidade,
    resetValuesDataTemplate,
    dateOwnSubjectRef,
    dateOwnMessageRef,
    dateOthersSubjectRef,
    dateOthersMessageRef,
    subjectOwn,
    messageOwn,
    subjectOthers,
    messageOthers,
    setSubjectOwn,
    setMessageOwn,
    setSubjectOthers,
    setMessageOthers,
    dateForPeriodicidade,
    setDateForPeriodicidade,
    createDateMessageVariableButtons,
    handleDropdownClassName,
    toDelete,
    setSubject,
    setMessage,
    setFocus,
    setSelectedDocTypeId,
    handleSubmit,
    handleSetSelectedOperationId,
    messageRef,
    subjectRef,
    validated,
    setResetDocTypeDropdown,
    resetDocTypeDropdown,
    docTypeError,
    operationError,
    message,
    subject,
    notificationNot,
    emailNotification,
    handleNotificationType,
    regraListDropdown,
    regraError,
    handleRegraSelect,
    resetDropdownRegra,
    setResetDropdownRegra,
    contextValue,
    regra,
    t,
  } = props;

  const asterisk = <i style={{ color: "#dc3545" }}>*</i>;

  return (
    <>
      <Modal show={showDeleteModal} onHide={closeDeleteModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="default-modal-title">
            {t("general.remove") +
              " " +
              t("portalrh.emailTemplate.templateID") +
              " " +
              selectedEmailTemplate?.id}
          </Modal.Title>
        </Modal.Header>
        {contextValue === "1" ? (
          <DocTemplate
            setFocus={setFocus}
            showCreateModal={showDeleteModal}
            closeCreateModal={closeDeleteModal}
            operations={operations}
            selectedOperationId={selectedOperationId}
            setSelectedDocTypeId={setSelectedDocTypeId}
            subject={subject}
            setSubject={setSubject}
            message={message}
            setMessage={setMessage}
            handleDropdownClassName={handleDropdownClassName}
            handleSubmit={handleSubmit}
            handleSetSelectedOperationId={handleSetSelectedOperationId}
            validated={validated}
            operationError={operationError}
            docTypeError={docTypeError}
            resetDocTypeDropdown={resetDocTypeDropdown}
            setResetDocTypeDropdown={setResetDocTypeDropdown}
            subjectRef={subjectRef}
            messageRef={messageRef}
            asterisk={asterisk}
            selectedEmailTemplate={selectedEmailTemplate}
            toDelete={toDelete}
          />
        ) : (
          <DateTemplate
            handleSubmit={handleSubmit}
            createDateMessageVariableButtons={createDateMessageVariableButtons}
            setFocus={setFocus}
            closeCreateModal={closeDeleteModal}
            handleDropdownClassName={handleDropdownClassName}
            validated={validated}
            subjectRef={subjectRef}
            messageRef={messageRef}
            periodicidadeListDropdown={periodicidadeListDropdown}
            handlePeriodicidadeSelect={handlePeriodicidadeSelect}
            periodicidade={periodicidade}
            periodicidadeError={periodicidadeError}
            datasListDropdown={datasListDropdown}
            handleDatasSelect={handleDatasSelect}
            datas={datas}
            datasError={datasError}
            numberDays={numberDays}
            setNumberDays={setNumberDays}
            resetDropdownPeriodicidade={resetDropdownPeriodicidade}
            setResetDropdownPeriodicidade={setResetDropdownPeriodicidade}
            dateOwnSubjectRef={dateOwnSubjectRef}
            dateOwnMessageRef={dateOwnMessageRef}
            dateOthersSubjectRef={dateOthersSubjectRef}
            dateOthersMessageRef={dateOthersMessageRef}
            subjectOwn={subjectOwn}
            messageOwn={messageOwn}
            subjectOthers={subjectOthers}
            messageOthers={messageOthers}
            setSubjectOwn={setSubjectOwn}
            setMessageOwn={setMessageOwn}
            setSubjectOthers={setSubjectOthers}
            setMessageOthers={setMessageOthers}
            dateForPeriodicidade={dateForPeriodicidade}
            setDateForPeriodicidade={setDateForPeriodicidade}
            asterisk={asterisk}
            selectedEmailTemplate={selectedEmailTemplate}
            toDelete={toDelete}
            notificationNot={notificationNot}
            emailNotification={emailNotification}
            handleNotificationType={handleNotificationType}
            regraListDropdown={regraListDropdown}
            regraError={regraError}
            handleRegraSelect={handleRegraSelect}
            resetDropdownRegra={resetDropdownRegra}
            setResetDropdownRegra={setResetDropdownRegra}
            regra={regra}
          />
        )}
      </Modal>
    </>
  );
};
export default withNamespaces()(DeleteModal);
