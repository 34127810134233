import React from "react";

const MobileCard = ({ description, date, value, buildStatusColumn, row,
    cellContent,
    rowIndex }) => {
  const truncateText = (text, maxLength) => {
    return text && text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  const formatCurrency = (amount) => {
    if (!amount) return "-";
    return `${amount} €`; // Assuming EUR as the currency
  };

  return (
    <div
      className="card_container"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem",
        marginBottom: "1rem",
        backgroundColor: "whitesmoke",
        borderRadius: "12px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Left Column - Description */}
      <div style={{ flex: 1 }}>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#333",
            marginBottom: "0.5rem",
          }}
        >
          {truncateText(description, 20)}
        </div>
        <div
          style={{
            fontSize: "12px",
            color: "#888",
          }}
        >
          {date}
        </div>
      </div>

      {/* Right Column - Status and Amount */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          flexShrink: 0,
          gap: "1rem",
        }}
      >
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#f5a623",
            marginBottom: "0.5rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={row.status}
        >
          {buildStatusColumn(cellContent, row, rowIndex)}
        </div>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            color: "#333",
          }}
        >
          {formatCurrency(value)}
        </div>
      </div>
    </div>
  );
};

export default MobileCard;