import React from "react";
import { withNamespaces } from "react-i18next";
import { OverlayTrigger } from "react-bootstrap";
import { BiDetail } from "react-icons/bi";

const MobileCard = ({
  row,
  cell,
  headers,
  t,
  buildStatusColumn,
  handleRowClick,
  showUser = false,
}) => {
  const renderValue = (key) => {
    switch (key) {
      case "state":
        return buildStatusColumn(cell, row);
      case "requestDetail.fornecedor":
        return row.requestDetail.fornecedor;
      case "auth_designation":
        return row.auth_designation;
      case "requestDetail.dataFatura":
        return row.requestDetail.dataFatura;
      case "requestDetail.valor":
        return `${row.requestDetail.valor} €`;
      case "username":
        const nameParts = row.username.split(" ");
        if (nameParts.length > 1) {
          return `${nameParts[0]} ${nameParts[nameParts.length - 1]}`;
        }
        return row.username;
      default:
        return row[key];
    }
  };

  return (
    <div
      className="card_container"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem",
        marginBottom: "1rem",
        backgroundColor: "whitesmoke",
        borderRadius: "12px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Left Column - Actions */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "1rem",
        }}
      >
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={"detail"}
        >
          <BiDetail
            style={{ fontSize: "large", color: "#007bff" }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleRowClick(row);
            }}
          />
        </OverlayTrigger>
      </div>

      {/* Middle Column - Dynamic Content */}
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        {headers
          .filter(
            (header) =>
              header.dataField !== "state" &&
              header.dataField !== "requestDetail.valor" &&
              header.dataField !== "username"
          )
          .map((header, index) => (
            <div
              key={index}
              style={{
                fontSize:
                  header.dataField === "requestDetail.fornecedor" || index === 0
                    ? "14px"
                    : "12px",
                fontWeight: index === 0 ? "bold" : "600",
                color: index === 0 ? "inherit" : "#888",
                marginBottom: "0.5rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={renderValue(header.dataField)}
            >
              <span style={{ whiteSpace: "break-spaces" }}>
                {renderValue(header.dataField)}
              </span>
            </div>
          ))}
        {showUser && (
          <div
            style={{
              fontSize: "12px",
              fontWeight: "600",
              color: "#888",
              marginBottom: "0.5rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={renderValue("username")}
          >
            <span style={{ whiteSpace: "break-spaces" }}>
              {t("portalrh.requestTable.from")}: {renderValue("username")}
            </span>
          </div>
        )}
      </div>

      {/* Right Column - Status and Amount */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          flexShrink: 0,
          gap: "1rem",
        }}
      >
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#f5a623",
            marginBottom: "0.5rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={renderValue("state")}
        >
          {renderValue("state")}
        </div>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            color: "#333",
          }}
          title={renderValue("requestDetail.valor")}
        >
          {renderValue("requestDetail.valor")}
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(MobileCard);
