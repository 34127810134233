import React from "react";
import { withNamespaces } from "react-i18next";
import { Modal, Button, Form, Col } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import {
  adaptListForDocType,
  adaptListForOperations,
} from "~pages/PortalRH/components/DocumentManagement/handleDropdownList";

const DocTemplate = (props) => {
  const {
    //General modal funcionalities
    createMessageVariableButtons,
    setFocus,
    //Specific modal functionalities
    showCreateModal,
    closeCreateModal,
    //Email template data
    operations,
    selectedOperationId,
    setSelectedDocTypeId,
    subject,
    setSubject,
    message,
    setMessage,
    //Validation Funcionalities
    handleDropdownClassName,
    handleSubmit,
    handleSetSelectedOperationId,
    //validation data
    validated,
    operationError,
    docTypeError,
    //Reset docType dropdown when needed
    resetDocTypeDropdown,
    setResetDocTypeDropdown,
    //track cursor position in textareas
    subjectRef,
    messageRef,
    asterisk,
    selectedEmailTemplate,
    toDelete = false,
    t,
  } = props;

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={(e) => handleSubmit(e, toDelete)}
    >
      <Modal.Body>
        <Form.Row>
          <Col sm={3}>
            <Form.Label style={{ marginTop: "7px" }} className="default-label">
              {t("portalrh.emailTemplate.operation")} {asterisk}
            </Form.Label>
          </Col>
          <Col sm={9}>
            <Dropdown
              list={adaptListForOperations(
                operations,
                t("portalrh.documentManagement.selectOperationPlaceholder")
              )}
              className={handleDropdownClassName(operationError)}
              handleSubmit={handleSetSelectedOperationId}
              defaultValue={
                selectedEmailTemplate && selectedEmailTemplate?.operation
              }
              required
              disabled={toDelete}
              readOnly={toDelete}
            />
            {operationError && validated ? (
              <p className="dropdown-text-invalid">
                {t("portalrh.emailTemplate.warnnings.operationMissing")}
              </p>
            ) : (
              <p></p>
            )}
          </Col>
        </Form.Row>
        <Form.Row>
          {selectedOperationId ? (
            <>
              <Col sm={3}>
                <Form.Label
                  style={{ marginTop: "7px" }}
                  className="default-label"
                >
                  {t("portalrh.emailTemplate.docType")} {asterisk}
                </Form.Label>
              </Col>
              <Col sm={9}>
                <Dropdown
                  list={adaptListForDocType(
                    operations,
                    selectedOperationId,
                    t("portalrh.documentManagement.selectOperationPlaceholder")
                  )}
                  className={handleDropdownClassName(docTypeError)}
                  handleSubmit={setSelectedDocTypeId}
                  resetDropdown={resetDocTypeDropdown}
                  setResetDropdown={setResetDocTypeDropdown}
                  defaultValue={
                    selectedEmailTemplate && selectedEmailTemplate?.docType
                  }
                  required
                  disabled={toDelete}
                  readOnly={toDelete}
                />
                {docTypeError && validated ? (
                  <p className="dropdown-text-invalid">
                    {t("portalrh.emailTemplate.warnnings.documentTypeMissing")}
                  </p>
                ) : null}
              </Col>
            </>
          ) : (
            ""
          )}
        </Form.Row>
        <Form.Row>
          <Form.Label className="default-label">
            {t("portalrh.emailTemplate.subject")}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder={t("portalrh.emailTemplate.subject")}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            onFocus={() => setFocus("subject")}
            ref={subjectRef}
            disabled={toDelete}
            readOnly={toDelete}
          />
        </Form.Row>
        <Form.Row>
          <Form.Label className="default-label">
            {t("portalrh.emailTemplate.message")} {asterisk}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder={t("portalrh.emailTemplate.message")}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onFocus={() => setFocus("message")}
            ref={messageRef}
            required
            disabled={toDelete}
            readOnly={toDelete}
          />
          <Form.Control.Feedback type="invalid">
            {t("portalrh.emailTemplate.warnnings.textMessageMissing")}
          </Form.Control.Feedback>
        </Form.Row>
        <br></br>
        {!toDelete && (
          <>
            {" "}
            <Form.Label className="default-label">
              {t("portalrh.emailTemplate.messageVariables.title")}
            </Form.Label>
            <Form.Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {createMessageVariableButtons(subjectRef, messageRef)}
            </Form.Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
      {toDelete && (
          <p style={{ color: "black", textAlign: "left", flex: 1 }}>
            {t("portalrh.emailTemplate.removeWarning")}
          </p>
        )}
        <Button variant="secondary" className="btn" onClick={closeCreateModal}>
          {t("general.cancel")}
        </Button>
        <Button className="card-button" type="submit">
          {selectedEmailTemplate
            ? toDelete
              ? t("general.remove")
              : t("general.save")
            : t("general.add")}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default withNamespaces()(DocTemplate);
