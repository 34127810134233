import React, { useState, useRef, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/icons.css";
import { find } from "lodash";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import pdfPreview from "~/assets/img/datacapture/pdf_preview.svg";
import { abbreviateString } from "~/utils";
import {
  Form,
  Card,
  OverlayTrigger,
  Tooltip,
  Button,
  Badge,
  Row,
  Col,
} from "react-bootstrap";
import CancelIcon from "@mui/icons-material/Cancel";
import SpinnerNew from "~/components/SpinnerNew";
import { getCollaborators } from "~/store/ducks/portalrh/actionTypes";
import { getIflowFormdata } from "~/store/ducks/processes/actionTypes";
import Select from "@material-ui/core/Select";
import Dropdown from "~/components/Dropdown";
import MenuItem from "@material-ui/core/MenuItem";
import { Stack } from "@mui/material";
import uuid from "uuid/v1";
import { setDocument } from "~/store/ducks/iflow/actionTypes";

import {
  saveDocumentExpenses,
  setSaveDocumentExpensesLoading,
  postIflowFormExpenses,
  setAlertDocumentExpenses,
  getDocumentExpensesList,
  getDocumentExpensesSpecific,
  setDocumentExpensesSpecific,
  setCreateDocumentExpenses,
  postCreateDocumentExpenses,
  updateOpenAiConsumption,
  setDocumentExpensesResult,
  setPidExpenses,
} from "~/store/ducks/openai/actionTypes";
import { getOpenAIConfiguration } from "~/store/ducks/rhadminConfiguration/actionTypes";
import NavTabs from "../NavTabs";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import { generateRequestMenu } from "~/containers/GenerateRequestMenu";
import { setGenericRequestState } from "~/store/ducks/portalrh/actionTypes";
import ModalExpenses from "./ModalExpenses";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { pagination } from "~/components/pagination/currentSizePerPage/5";
import oauth from "~/utils/oauth";
import { toLocaleDateString } from "~/utils";
import { getColorByStatusDocument, translateStatusDocument } from "./utils";
import ModalDocument from "./ModalDocument";
import FlowModal from "~/components/FlowModal";
import { requestConfiguration } from "~/pages/Admin/SystemSettings/actions";

const Expenses = ({
  t,
  portalRhMenus,
  designacao,
  sectionName,
  searchFilter,
  activeSelected,
}) => {
  const { user } = useSelector((state) => state.globalReducer);
  const { openAIConfiguration } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const inputFile = useRef(null);
  const {
    isLoadingSaveDocumentExpenses,
    documentExpensesResult,
    alertDocumentExpense,
    documentExpensesList,
    documentExpensesSaved,
    documentExpensesSavedId,
    pidExpenses,
  } = useSelector((state) => state.openaiReducer);

  const dispatch = useDispatch();
  const [warningMessage, setWarningMessage] = useState("");
  const [onDragOver, setOnDragOver] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [menus, setMenus] = useState({});
  const [containMenu, setContainMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [image, setImage] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [pidExp, setPidExp] = useState("");
  const [showFlowModal, setShowFlowModal] = useState(false);

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 && configuration && configuration.mobileReady
  );
  const [showListDocuments, setShowListDocuments] = useState(!isMobile);
  const [imageBase64, setImageBase64] = useState("");
  const [messageAlertDocumentExpense, setMessageAlertDocumentExpense] =
    useState("");
  const [docTypeExtraConfiguration, setDocTypeExtraConfiguration] = useState(
    []
  );
  const [datasetSelected, setDatasetSelected] = useState({});

  const { SearchBar } = Search;

  const [documentValues, setDocumentValues] = useState({});
  const [haveExtraction, setHaveExtraction] = useState(false);
  const [havePreview, setHavePreview] = useState(false);
  const [haveCorrection, setHaveCorrection] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState("");
  const [cleanDropdown, setCleanDropdown] = useState(false);

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(
        window.innerWidth < 768 && configuration && configuration.mobileReady
      );
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (user?.organization?.id) {
      dispatch(getOpenAIConfiguration(user?.organization?.id));
      dispatch(requestConfiguration());
    }
  }, [user]);

  useEffect(() => {
    setIsMobile(
      window.innerWidth < 768 && configuration && configuration.mobileReady
    );
  }, [configuration]);

  useEffect(() => {
    if (menu) {
      let app = "";
      //find app from url
      const arr1 =
        window.location.href.includes("/uniksystem/") &&
        window.location.href.split("/uniksystem/");
      if (arr1.length > 1) {
        const arr2 = arr1[1];
        const arr3 = arr2.split("/");
        app = arr3[0];
      }
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/expenses` || item.route === `${app}/expenses`
      );
      if (menuItem) {
        if (menuItem.docTypes && menuItem.docTypes.length > 0) {
          setDocTypeExtraConfiguration(menuItem.docTypes);
        } else {
          const extraConfiguration_ = JSON.parse(
            menuItem.extraConfiguration || false
          );
          const doctypeExtraConfiguration_ =
            extraConfiguration_ && extraConfiguration_.docTypes;
          setDocTypeExtraConfiguration(doctypeExtraConfiguration_);
        }
      } else {
        setDocTypeExtraConfiguration(["expense"]);
      }
    }
  }, [menu]);

  const getLegacyUser = () => {
    if (!user || !user.id) return;
    return user.legacyUsers.find((u) => u.legacyApplication.label == "bpm");
  };

  useEffect(() => {
    if (documentExpensesSavedId != null) {
      if (documentExpensesSaved == "success") {
        callhandleIflow();
        handleResetState();
      } else if (documentExpensesSaved == "error") {
        handleResetState();
        setOpenAlert(true);
        setMessageAlertDocumentExpense(t("openai.errorReadingDocument"));
        setTypeAlert("error");
      }
    }
  }, [documentExpensesSaved]);

  useEffect(() => {
    if (Object.keys(pidExpenses).length > 0 && pidExpenses.pid) {
      setPidExp(pidExpenses.pid);
      const payload = {
        url:
          "Forward/forward.jsp?flowid=" +
          datasetSelected.flowId +
          "&pid=" +
          pidExpenses.pid +
          "&subpid=1&Authorization=" +
          oauth.getAccessToken(),
      };
      const isNewForm = true;
      dispatch(getIflowFormdata(payload, isNewForm));
      setShowFlowModal(true);
    } else {
      setShowFlowModal(false);
      setPidExp("");
    }
  }, [pidExpenses]);

  const closeModal = () => {
    dispatch(setPidExpenses({}));
    setShowFlowModal(false);
    setPidExp("");
  };

  useEffect(() => {
    if (Object.keys(documentExpensesResult).length > 0) {
      if (datasetSelected.extraction && datasetSelected.preview) {
        setOpenModal(true);
        setHaveExtraction(true);
        setHavePreview(true);
      } else if (datasetSelected.extraction && !datasetSelected.preview) {
        let payload = {
          flowId: datasetSelected.flowId,
          base64: imageBase64,
          userId: user.id,
          organizationId: user.organization.id,
          fileName: documentName,
          metadata: documentExpensesResult.metadata,
          fileType: documentExpensesResult.fileType,
          openaiId: documentExpensesResult.openaiId || null,
        };
        setHaveExtraction(true);
        //TODO: chamar consumo com o id da despesa documentExpensesResult
        // dispatch(updateOpenAiConsumption({ id: documentExpensesResult.id }));
        dispatch(postCreateDocumentExpenses(payload));
      } else {
        callhandleIflow();
      }
    } else {
      if (documentExpensesResult == "") {
        setOpenAlert(true);
        setMessageAlertDocumentExpense(t("openai.errorReadingDocument"));
        setTypeAlert("error");
        handleRemoveFile();
      }
      setOpenModal(false);
      handleRemoveFile();
    }
  }, [documentExpensesResult]);

  useEffect(() => {
    if (alertDocumentExpense != "") {
      setOpenAlert(true);
      setMessageAlertDocumentExpense(t(`openai.${alertDocumentExpense}`));
      setTypeAlert(alertDocumentExpense);
    }
  }, [alertDocumentExpense]);

  useEffect(() => {
    handleResetState();
    setMenus({});
    /*close full screen iframes*/
    closeIframesScreens();
    setContainMenu(false);
    setMenus(generateRequestMenu(portalRhMenus, designacao, setContainMenu));
    let requestState = localStorage.getItem("requestState");
    if (requestState != null) {
      if (requestState.startsWith('"') && requestState.endsWith('"')) {
        requestState = JSON.parse(requestState);
      }
      dispatch(setGenericRequestState(requestState));
    }
    dispatch(getCollaborators());
    dispatch(getDocumentExpensesList(user.id));
    dispatch(setAlertDocumentExpenses(""));
    setOpenAlert(false);
    setMessageAlertDocumentExpense("");
    setTypeAlert("");
    //localStorage.removeItem("requestState")
  }, []);

  useEffect(() => {
    if (
      Object.keys(openAIConfiguration).length > 0 &&
      openAIConfiguration.datasets &&
      openAIConfiguration.datasets.length > 0
    ) {
      if (
        docTypeExtraConfiguration.length > 0 &&
        docTypeExtraConfiguration &&
        docTypeExtraConfiguration.length === 1
      ) {
        const dataset = openAIConfiguration.datasets.find(
          (dataset) =>
            dataset.documentType &&
            docTypeExtraConfiguration.some((docType) =>
              dataset.documentType.includes(docType)
            )
        );
        setDatasetSelected(dataset);
        setSelectedDocType(dataset?.documentType || "");
      }
    }
  }, [docTypeExtraConfiguration, openAIConfiguration]);

  const handleDocTypeChange = (event) => {
    setSelectedDocType(event);
    const dataset = openAIConfiguration.datasets.find(
      (dataset) => dataset.documentType && dataset.documentType.includes(event)
    );
    setDatasetSelected(dataset);
  };

  const callhandleIflow = () => {
    const usr = getLegacyUser();
    let payload = {
      flowid: datasetSelected.flowId,
      emittingVat: documentValues.emittingVat,
      emittingCompany: documentValues.emittingCompany,
      customerVat: documentValues.customerVat,
      totalAmount:
        documentValues.totalAmount === null ? 0 : documentValues.totalAmount,
      invoiceDate:
        documentValues.invoiceDate === null ? "" : documentValues.invoiceDate,
      username: usr.username,
      documentId: documentExpensesSavedId,
      extraction: haveExtraction.toString(),
      preview: havePreview.toString(),
      correction: haveCorrection.toString(),
    };
    handleSubmitIflow(payload);
  };

  const handleSubmitIflow = (values = {}) => {
    const url = "/startProcess?Authorization=" + oauth.getAccessToken();
    dispatch(setSaveDocumentExpensesLoading(true));
    dispatch(postIflowFormExpenses(values, url));
    dispatch(getDocumentExpensesList(user.id));
    dispatch(setCreateDocumentExpenses("", null));
    dispatch(setDocumentExpensesResult({}));
    handleResetState();
  };

  const handleResetState = () => {
    setDocumentValues({});
    handleRemoveFile();
    setImageBase64("");
    setEditMode(false);
    setOpenModal(false);
    setOpenDocument(false);
    setHaveExtraction(false);
    setHavePreview(false);
    setHaveCorrection(false);
    setDocumentName("");
    dispatch(setDocumentExpensesSpecific({}));
  };

  const maxFileSize = 1024 * 1024 * 15; //15 Mb

  const handleOnChoose = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile.type === "image/jpeg" ||
      selectedFile.type === "image/png" ||
      selectedFile.type === "image/jpg" ||
      selectedFile.type === "application/pdf"
    ) {
      handleMultipleFiles([selectedFile]);
    } else {
      setWarningMessage(t("portalrh.expenses.fileType"));
      setOpenToast(true);
    }
  };

  const handleOnDrop = (event) => {
    setOnDragOver(false);
    const files_ = event.dataTransfer.files;
    const files = [];
    for (let i = 0; i < files_.length; i++) {
      files.push(files_.item(i));
    }
    handleMultipleFiles(files);
    event.preventDefault();
  };

  const handleMultipleFiles = (files) => {
    let totalfilesize = files.reduce(
      (accumulator, currentValue) => accumulator + currentValue.size,
      0
    );
    if (totalfilesize > maxFileSize) {
      setWarningMessage(t("portalrh.expenses.fileSize"));
      setOpenToast(true);
      return;
    }
    setDocuments(files);
    setDocumentName(files[0].name);
  };

  const handleClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const getPreviewImage = () => {
    const file = documents[0];
    if (file) {
      return file.type === "application/pdf"
        ? pdfPreview
        : URL.createObjectURL(file);
    } else {
      return document.filetype === "application/pdf"
        ? pdfPreview
        : "data:image/jpg;base64," + document.data;
    }
  };

  const handleRemoveFile = () => {
    setDocuments([]);
    setDocumentName("");
    if (inputFile.current) {
      inputFile.current.value = "";
    }
  };

  const handleSaveDocs = () => {
    if (
      Object.keys(openAIConfiguration).length > 0 &&
      openAIConfiguration.extractionApiKey
    ) {
      dispatch(setDocument(null));
      const docList = [];
      documents.forEach((document, index, array) => {
        if (document.type.includes("image")) {
          setIsImage(true);
        } else {
          setIsImage(false);
        }
        const reader = new FileReader();
        reader.onload = function (e) {
          docList.push({
            file: e.target.result,
          });
          if (index === array.length - 1) {
            if (docList.length > 0) {
              const base64String = docList[0].file.split("base64,")[1];
              if (datasetSelected.extraction) {
                const payload = {
                  userId: user.id,
                  organizationId: user.organization.id,
                  base64: base64String,
                  docType: datasetSelected.documentType,
                  openaiId: documentExpensesResult.openaiId || null,
                };
                setImageBase64(base64String);
                dispatch(saveDocumentExpenses(payload));
              } else {
                let payload = {
                  flowId: datasetSelected.flowId,
                  base64: base64String,
                  userId: user.id,
                  organizationId: user.organization.id,
                  fileName: documentName,
                  openaiId: documentExpensesResult.openaiId || null,
                };
                dispatch(postCreateDocumentExpenses(payload));
              }
            }
            setDocuments([]);
            setCleanDropdown(true);
          }
        };
        reader.readAsDataURL(document);
      });
    } else {
      setOpenAlert(true);
      setTypeAlert("error");
      setMessageAlertDocumentExpense(t("openai.errorReadingAPIKEY"));
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setAlertDocumentExpenses(""));
    setOpenAlert(false);
    setMessageAlertDocumentExpense("");
    setTypeAlert("");
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.stopPropagation();
      e.preventDefault();
      handleDisptachDocument(row);
    },
  };

  const handleDisptachDocument = (row) => {
    dispatch(getDocumentExpensesSpecific(row.id));

    const isImage = row.fileType
      ? row.fileType.includes("image")
      : row.fileName.includes(".pdf")
      ? false
      : true;

    setImage(isImage);
    setOpenDocument(true);
  };

  const buildActionsColumn = (_, row, {}) => (
    <>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t("openai.detailDocument"))}
      >
        <i
          className="icon-detail table-action-icon"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleDisptachDocument(row);
          }}
        />
      </OverlayTrigger>
    </>
  );
  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  const buildStatusColumn = (cell, row) => {
    const status = row.status;
    const variant = getColorByStatusDocument(status);
    const translatedStatus = translateStatusDocument(status);
    return (
      <Badge pill variant={variant}>
        {t(translatedStatus)}
      </Badge>
    );
  };

  const buildStatusSort = (cell, row) => {
    const status = row.status;
    return translateStatusDocument(status);
  };

  const renderDocTypeOptions = () =>
    docTypeExtraConfiguration.map((docType, index) => {
      return {
        label: docType, // Use docType as the label
        value: docType, // Use docType as the value
        selected: false,
      };
    });

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      hidden: true,
    },
    {
      dataField: "status",
      text: t("openai.status"),
      sort: true,
      formatter: (cell, row) => buildStatusColumn(cell, row),
      sortValue: (cell, row) => buildStatusSort(cell, row),
      headerStyle: () => {
        return { textAlign: "center", width: "130px" };
      },
      align: "center",
    },
    {
      dataField: "metadata.emittingCompany",
      text: t("openai.emittingCompany"),
      sort: true,
    },
    {
      dataField: "metadata.totalAmount",
      text: t("openai.totalAmount"),
      sort: true,
      headerStyle: () => {
        return { textAlign: "center", width: "130px" };
      },
      align: "center",
    },
    {
      dataField: "submissionDate",
      text: t("openai.dataSubmission"),
      sort: true,
      formatter: (_, row, {}) => toLocaleDateString(row?.submissionDate),
      headerStyle: () => {
        return { textAlign: "center", width: "130px" };
      },
      align: "center",
    },
    {
      dataField: "metadata.invoiceDate",
      text: t("openai.data"),
      sort: true,
      formatter: (_, row, {}) => toLocaleDateString(row?.metadata?.invoiceDate),
      headerStyle: () => {
        return { textAlign: "center", width: "130px" };
      },
      align: "center",
    },
    {
      dataField: "actions",
      text: t("openai.actions"),
      formatter: buildActionsColumn,
      align: "center",
      headerStyle: () => {
        return { textAlign: "center", width: "100px" };
      },
      hidden: isMobile,
    },
  ];

  return (
    <>
      <SpinnerNew open={isLoadingSaveDocumentExpenses || isLoading} />
      <div className="main-card-v2">
        <Card bsPrefix="card-flat" style={{ height: "fit-content" }}>
          <Card.Header className="justify-content-between">
            {/* <NavTabs
              titleMenu={t(`${sectionName}`)}
              menus={menus}
              activeSelected={activeSelected}
              containMenu={containMenu}
              setIsLoading={setIsLoading}
              t={t}
            /> */}
            <h6>{t("openai.submissaoFatura")}</h6>
          </Card.Header>
          <Card.Body
            style={{ display: "flex", flexDirection: "column", padding: "0px" }}
          >
            <div
              style={{
                margin: "0px 0px 2rem",
                width: "100%",
                alignSelf: isMobile ? "auto" : "center",
              }}
            >
              <div style={{ display: !isMobile ? "flex" : "" }}>
                <div
                  className="dropAreaExpenses"
                  onDrop={(e) => handleOnDrop(e)}
                  onClick={() => handleClick()}
                  onDragEnter={() => setOnDragOver(true)}
                  onDragLeave={() => setOnDragOver(false)}
                  style={
                    onDragOver
                      ? { background: "#fff" }
                      : { background: "#efefef" }
                  }
                >
                  <span>
                    <i className={"icon-submit portal-image-upload-icon"} />
                    <div
                      className={"portal-label-main"}
                      style={{ padding: "2rem" }}
                    >
                      {t("portalrh.documentManagement.dropToUpload")}
                    </div>
                  </span>
                  <input
                    multiple={false}
                    id="file"
                    type="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={(e) => handleOnChoose(e)}
                    accept=".pdf, .png,.jpg,.jpeg"
                  />
                </div>
                {isMobile && (
                  <div className="dc-label">
                    {t("portalrh.documentManagement.totalFileSize") + " 15 Mb"}
                  </div>
                )}
                <div
                  style={{
                    display: "inline-block",
                    width: isMobile ? "100%" : "50%",
                    textAlignLast: "center",
                    marginTop: isMobile ? "1rem" : "",
                    height: "0px",
                  }}
                >
                  {(!isMobile || documents.length > 0) && (
                    <h6 style={{ placeContent: "center", padding: "0px" }}>
                      {t("openai.expenseLoaded")}
                    </h6>
                  )}
                  {documents.length > 0 && (
                    <>
                      <img
                        src={getPreviewImage()}
                        className={"portal-image-preview-one"}
                        alt="preview"
                      />
                      <CancelIcon color="error" onClick={handleRemoveFile} />
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={<Tooltip>{documents[0].name}</Tooltip>}
                      >
                        <small>{abbreviateString(documents[0].name, 28)}</small>
                      </OverlayTrigger>
                    </>
                  )}
                  {docTypeExtraConfiguration.length > 1 &&
                    documents.length > 0 && (
                      <div
                        style={{
                          display: isMobile ? "block" : "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "2rem",
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={2}
                          display="contents"
                        >
                          <Form.Label>
                            {t("datacapture.metadata.document_type")}
                          </Form.Label>
                          <Dropdown
                            list={renderDocTypeOptions()}
                            handleSubmit={handleDocTypeChange}
                            emptyDefault={true}
                            resetDropdown={cleanDropdown}
                            setResetDropdown={setCleanDropdown}
                            flowId={selectedDocType}
                          />
                        </Stack>
                      </div>
                    )}
                </div>
              </div>
              {!isMobile && (
                <div className="dc-label">
                  {t("portalrh.documentManagement.totalFileSize") + " 15 Mb"}
                </div>
              )}
            </div>
          </Card.Body>
          <Card.Footer>
            <div
              style={{
                width: "100%",
                textAlignLast: "right",
                marginTop: "3rem",
              }}
            >
              <Button
                className="card-button"
                disabled={
                  documents.length > 0 && selectedDocType != "" ? false : true
                }
                onClick={() => {
                  documents.length > 0 && handleSaveDocs();
                }}
              >
                {t("openai.upload")}
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </div>

      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={typeAlert}
          sx={{ width: "100%" }}
        >
          {messageAlertDocumentExpense}
        </Alert>
      </Snackbar>
      <ModalExpenses
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleSubmitIflow={handleSubmitIflow}
        documentValues={documentValues}
        setDocumentValues={setDocumentValues}
        handleResetState={handleResetState}
        isImage={isImage}
        editMode={editMode}
        setEditMode={setEditMode}
        imageBase64={imageBase64}
        setHaveCorrection={setHaveCorrection}
        documentName={documentName}
        datasetSelected={datasetSelected}
      />
      <ModalDocument
        openModal={openDocument}
        setOpenModal={setOpenDocument}
        handleResetState={handleResetState}
        documentValues={documentValues}
        setDocumentValues={setDocumentValues}
        image={image}
      />
      <FlowModal
        showModal={showFlowModal}
        closeModal={closeModal}
        isPortal={true}
      />
    </>
  );
};

export default withNamespaces()(Expenses);
