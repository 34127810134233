import _uniqueId from "lodash/uniqueId";
import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import iconSearchDefault from "~/pages/Easypay/assets/img/icons/icon-search-default.png";
import iconSearchDisabled from "~/pages/Easypay/assets/img/icons/icon-search-disabled.png";
import iconSearchFocus from "~/pages/Easypay/assets/img/icons/icon-search-focus.png";
import iconSearch from "~/pages/Easypay/assets/img/icons/icon-search.png";
import { isIOS } from "react-device-detect";
import { useSelector } from "react-redux";
const devLogConsole = require("~/utils/devLog");
/*
    Props explanation:
        - t (used when needing to translate any 'general' words) **REQUIRED**
        - list (list of options the dropdown is going to use) **REQUIRED**
        - handleSubmit={(value) => handleSelectChange(value)} **REQUIRED**
        - placeholder **DEFAULT is "option"**
        - required={required} **DEFAULT is false**
        - disabled (used when user wants the input to be disabled)
        - resetSearchableDropdown (used to reset the searchableDropdown value and label)
        - enableSearchEmptyValue (used when the user can delete input value and keep it empty)
        - noBorderRadius (used when using SearchableDropdown inside table (border: 0.25rem))
        - exitOnClose (do not submit on close, used in iFlow forms)
        - nomargin (removes margin)
*/

/* IMPORTANT */
/* When using list make sure you have it in the correct layout. Example:
  
        list = [
            {"label": "label here",
            "value": "value here"}
                ]

    If you have any option in the list that you want to make as default input 
    just add in the property "selected" :true to the list option
*/

const SearchableDropDown = (props) => {
  const {
    t,
    list = [{ label: t("general.dataNotFound") }],
    required = false,
    handleSubmit = false,
    placeholder = "Selecionar opção",
    disabled,
    text,
    name,
    countryList = false,
    resetSearchableDropdown,
    enableSearchEmptyValue,
    noBorderRadius,
    exitOnClose,
    defaultValue,
    sort,
    nomargin,
    styleSheet,
    fontSize,
  } = props;

  /*
        Sorts an array by name, or number (both ascending or descending)
    */
  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const sortBy = {
    nameAscending: (prop) =>
      prop.sort((a, b) => a?.label?.localeCompare(b.label)),
    nameDescending: (prop) =>
      prop.sort((a, b) => b?.label?.localeCompare(a.label)),
    numberAscending: (prop) => prop.sort((a, b) => a - b),
    numberDescending: (prop) => prop.sort((a, b) => b - a),
    noOrder: (prop) => prop,
  };

  const [hovered, setHovered] = useState(undefined);
  const [isIOSDevice, setIsIOSDevice] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 && configuration && configuration.mobileReady
  );

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(
        window.innerWidth < 768 && configuration && configuration.mobileReady
      );
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsMobile(
      window.innerWidth < 768 && configuration && configuration.mobileReady
    );
  }, [configuration]);

  useEffect(() => {
    setIsIOSDevice(isIOS);
  }, []);

  const outerListRef = useRef(undefined);
  const innerListRef = useRef(undefined);
  const [scrollOffset, setScrollOffset] = useState(0);
  const listHeight = 150;
  const [pageUp, pageDown, home, end] = [33, 34, 36, 35];
  const pageOffset = 42;
  const maxHeight =
    (innerListRef.current &&
      innerListRef.current.style.height.replace("px", "")) ||
    listHeight;

  const minHeight = 0.1;

  const keys = {
    [pageUp]: Math.max(minHeight, scrollOffset - pageOffset),
    [pageDown]: Math.min(scrollOffset + pageOffset, maxHeight),
    [end]: maxHeight,
    [home]: minHeight,
  };

  const handleKeyDown = ({ keyCode }) => {
    keys[keyCode] && setScrollOffset(keys[keyCode]);
  };

  useLayoutEffect(() => {
    //devLogConsole(scrollOffset)

    outerListRef.current &&
      outerListRef.current.scrollTo({
        left: 0,
        top: scrollOffset,
        behavior: "auto",
      });
  }, [scrollOffset]);

  //Sorts list
  const optionList = styleSheet?.noOrder
    ? list
    : sort === "noOrder"
    ? list
    : sortBy.nameAscending(list);

  const [selectedValue, setSelectedValue] = useState("");
  const [label, setLabel] = useState("");
  const [displayList, setDisplayList] = useState([optionList]);
  const [icon, setIcon] = useState(disabled ? iconSearchDisabled : iconSearch);
  const [nextInputState, setnextInputState] = useState(null);

  //Randomly creates an id for dropdown and input
  const id = useRef(_uniqueId(""));

  useEffect(() => {
    const defaultItem = optionList.find((item) => item.selected === true);
    if (defaultItem) {
      setLabel(defaultItem.label);
      setSelectedValue(defaultItem.value);
    }

    if (resetSearchableDropdown) {
      setLabel("");
      setSelectedValue("");
    }
  }, [list]);

  useEffect(() => {
    calculate_pos();
  }, [displayList]);

  useEffect(() => {
    const item = optionList.find((item) => item.value == defaultValue);

    if (defaultValue && item?.label) {
      setLabel(item.label);
    }
  }, [defaultValue]);

  /*
        Type ahead feature where list gets refreshed when user is typing
        If there isn't any option correlated to input searchable-shows "data not found"
    */
  const typeFunction = (e) => {
    setScrollOffset(0);
    let input = document
      .getElementById(`formInput-${id.current}`)
      .value.toUpperCase()
      .trim();
    let currentId = parseInt(e.target.id.match(/\d+/)[0]);

    //Shifts to next input when the user presses enter key, also selects first element
    if (e.key === "Enter") {
      outerListRef.current &&
        outerListRef.current.scrollTo({
          left: 0,
          top: scrollOffset,
          behavior: "auto",
        });
      if (displayList.length > 0) {
        if (displayList[0].label === t("general.dataNotFound")) {
          setLabel("");
          setSelectedValue("");
        } else {
          setLabel(displayList[cursor].label);
          setSelectedValue(displayList[cursor].label);
          setCursor(0);
          setnextInputState(
            document.getElementById(`formInput-${currentId + 1}`)
          );
        }
      }
      hideDropDown();
      //if(nextInput != null) nextInput.focus()
      return;
    }

    if (e.keyCode === 8) {
      setCursor(0);
      setScrollOffset(0);
    }

    if (e.keyCode === 38) {
      setCursor(cursor === 0 ? cursor : cursor - 1);
      setScrollOffset(cursor === 0 ? 0 : scrollOffset - pageOffset);
    }

    if (e.keyCode === 40) {
      setCursor(cursor === displayList.length - 1 ? cursor : cursor + 1);
      setScrollOffset(
        cursor === displayList.length - 1
          ? scrollOffset
          : scrollOffset + pageOffset
      );
    }

    /*
       Necessary condition to make drop down work even if input is selected by using "shift" key
    */
    if (
      !document
        .getElementById(`dropDown-${id.current}`)
        .classList.contains("searchable-show")
    ) {
      document
        .getElementById(`dropDown-${id.current}`)
        .classList.add("searchable-show");
    }

    /*
      Gets array of filtered options that contain the input
    */
    const normalizeToUpper = (str) =>
      str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase();

    let filtered = optionList.filter((option) => {
      if (normalizeToUpper(option.label).includes(normalizeToUpper(input))) {
        return input;
      }
    });

    /*
       Puts filtered option list in displayList variable
    */
    if (filtered.length > 0) {
      setDisplayList(filtered);
    } else if (
      document.getElementById(`formInput-${id.current}`).value.length > 0
    ) {
      setDisplayList([{ label: t("general.dataNotFound") }]);
    }

    //All options are searchable-shown when input is empty
    if (document.getElementById(`formInput-${id.current}`).value === "") {
      if (enableSearchEmptyValue) {
        setLabel("");
        setSelectedValue("");
      }
      setDisplayList(optionList);
    }
  };
  useEffect(() => {
    if (nextInputState != null) nextInputState.focus();
  }, [nextInputState]);

  /*
    Functions that runs when clicking any option
  */
  const handleSelect = (e) => {
    //Changes input to the selected option
    setSelectedValue(e.target.value);
    const element = optionList.find(
      (element) => element.value === e.target.value
    );

    if (element) {
      setLabel(element.label);
    }
    //Sorts list to display selected first
    const newList = sortListAfterSelect(optionList, e.target.value);
    setDisplayList(newList);
    //Hides dropdown after selecting any option
    hideDropDown();
    const defaultItem = optionList.find((item) => item.selected === true);

    if (!handleSubmit) {
      setSelectedValue(e.target.value);
      setLabel(e.target.label);
      return;
    }

    if (defaultItem) {
      if (defaultItem.value !== e.target.value) {
        handleSubmit(e.target.value);
      }
    } else {
      handleSubmit(e.target.value);
    }
    setDropdownVisible(false);
  };

  /* 
    Sorts list to display selected element in first place. Also deletes it from the displayed list
  */
  const sortListAfterSelect = (list, selected) => {
    let newList = [];
    newList[0] = list.filter((e) => e.value == selected)[0];
    newList = newList.concat(list.filter((e) => e.value != selected));
    return newList;
  };

  const [cursor, setCursor] = useState(0);

  /*
    Clears out the input whenever the option isn't in list
  */
  const handleClose = () => {
    const inputValue = document
      .getElementById(`formInput-${id.current}`)
      .value.trim();

    if (
      optionList.some(
        (option) => option.label.toUpperCase() === inputValue.toUpperCase()
      )
    ) {
      const option = optionList.find(
        (option) => option.label.toUpperCase() === inputValue.toUpperCase()
      );
      setSelectedValue(option.value);
      setLabel(option.label);

      if (!handleSubmit || exitOnClose) {
        return;
      }
      handleSubmit(option.value);

      const defaultItem = optionList.find((item) => item.selected === true);
      if (defaultItem && defaultItem.value !== option.value) {
        handleSubmit(option.value);
      }
    } else {
      if (enableSearchEmptyValue && inputValue === "") {
        return;
      }

      const defaultItem = optionList.find((item) => item.selected === true);

      if (defaultItem) {
        setSelectedValue(defaultItem.value ? defaultItem.value : "");
        setLabel(defaultItem.label ? defaultItem.label : "");
      } else {
        setSelectedValue("");
        setLabel("");
      }
    }
  };

  const calculate_pos = () => {
    var doc = document.getElementById(`dropDown-${id.current}`);
    doc.style.top = "auto";

    var parent = document
      .getElementById(`dropDown-${id.current}`)
      .closest(".main-card-v2");

    if (parent == undefined)
      parent = document
        .getElementById(`dropDown-${id.current}`)
        .closest(".card-flat");

    if (parent == undefined) parent = doc.parentElement;

    var parentRec = parent.getBoundingClientRect();
    var rect = doc.getBoundingClientRect();
    var wrapperRec = doc.parentElement.getBoundingClientRect();
    let dropdownShowDown = false;
    if (
      styleSheet?.dropdownDown != null ||
      styleSheet?.dropdownDown != undefined
    ) {
      dropdownShowDown = styleSheet?.dropdownDown;
    }
    if (parentRec.bottom < rect.bottom && !dropdownShowDown)
      doc.style.top =
        rect.top -
        rect.bottom -
        (wrapperRec.bottom - wrapperRec.top) -
        10 +
        "px";
    else doc.style.top = "auto";
    doc.style.position = "relative";
  };

  /*
    Toggles dropdown list everytime user clicks in input
    If first element is selected searchable-shows it highlighted
  */
  const toggleList = () => {
    document
      .getElementById(`dropDown-${id.current}`)
      .classList.toggle("searchable-show");

    if (
      document
        .getElementById(`dropDown-${id.current}`)
        .className.includes("searchable-show")
    ) {
      let newList = sortListAfterSelect(optionList, selectedValue);

      if (newList[0] === undefined) {
        setDisplayList(optionList);
      } else {
        setDisplayList(newList);
      }
      calculate_pos();
    } else {
      handleClose();
    }
  };

  const hideDropDown = (desiredId) => {
    desiredId === undefined
      ? document
          .getElementById(`dropDown-${id.current}`)
          .classList.remove("searchable-show")
      : document
          .getElementById(`dropDown-${desiredId}`)
          .classList.remove("searchable-show");
  };

  /*
     Function that runs whenever the input get out of focus
  */
  const outOfFocus = (e) => {
    handleClose();
    hideDropDown(parseInt(e.target.id.match(/\d+/)[0]));
  };

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const selectRef = useRef(null);

  const handleInputClick = () => {
    setShowSelect(true);
    setDropdownVisible(true);
    if (isMobile) {
      setTimeout(() => {
        if (selectRef.current) {
          selectRef.current.focus();
          selectRef.current.size = displayList.length; // Ensure all options are visible
        }
      }, 0);
    }
  };

  return (
    <>
    {isMobile ? (
      <>
        <Form.Group controlId={`formText-field`} style={{ margin: "0" }}>
          <div
            className={`searchable-input-search-wrapper `}
            style={{ fontSize: `${fontSize}px` }}
          >
            <div className="searchable-input-label">{text}</div>
            <div
              className={`searchable-input-search-field-wrapper ${
                disabled ? "input-searchable-disabled" : ""
              } ${noBorderRadius ? "table-border-radius" : ""} ${
                styleSheet != undefined && styleSheet.ultraCompact
                  ? "ultracompactForm"
                  : ""
              }`}
              style={showSelect && isMobile ? { justifyContent: "right" } : {}}
              tabIndex="1"
            >
              <input
                autoComplete="off"
                onFocus={(e) => {
                  setIcon(iconSearchFocus);
                  e.target.select();
                }}
                disabled={disabled}
                className={`searchable-input-search-field`}
                placeholder={`${placeholder}`}
                id={`formInput-${id.current}`}
                style={{
                  fontSize: `${fontSize}px`,
                  display: showSelect ? "none" : "block",
                }}
                onMouseDown={() => {
                  setTimeout(() => {
                    toggleList();
                  }, 100); // Adiciona um pequeno atraso
                }}
                onKeyUp={(e) => typeFunction(e)}
                value={label}
                required={required}
                onBlur={(e) => {
                  outOfFocus(e);
                  setIcon(iconSearchDefault);
                }}
                size={label?.length || 10}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
                onClick={handleInputClick}
                name={name + "Label"}
              />
              <div className="searchable-input-search-icon-wrapper">
                <img
                  className="searchable-input-search-icon"
                  src={icon}
                  alt="search-icon"
                />
              </div>
            </div>
              <>
                <select
                  id={`dropDown-${id.current}`}
                  className={`searchable-dropdown ${
                    isIOSDevice ? "ios-dropdown" : ""
                  }`}
                  ref={selectRef}
                  style={{
                    position: "absolute",
                    zIndex: 1000,
                    fontSize: `${fontSize}px`,
                    display: isDropdownVisible && showSelect ? "block" : "none",
                  }}
                  onChange={(e) => {
                    handleSelect(e);
                    setShowSelect(false); // Hide select after selection
                  }}
                  size={displayList.length} // This ensures all options are visible
                >
                  {displayList.map((option, i) => (
                    <option
                      key={i}
                      value={option.value}
                      className={`searchable-dropdown-option ${
                        i === cursor ? "currentHover" : ""
                      } ${countryList ? "searchable-country-option" : ""} ${
                        styleSheet != undefined && styleSheet.ultraCompact
                          ? "ultracompactDropdownOpt"
                          : ""
                      }`}
                      onMouseDown={(e) => {
                        handleSelect(e);
                        setShowSelect(false); // Hide select after selection
                      }}
                      onMouseEnter={() => {
                        setHovered(option);
                        setCursor(i);
                      }}
                      onMouseLeave={() => {
                        setHovered(undefined);
                        setCursor(0);
                      }}
                    >
                      {option.label === t("general.dataNotFound")
                        ? t("general.dataNotFound")
                        : option.label}
                    </option>
                  ))}
                </select>
              </>
          </div>
          <input type="hidden" name={name} value={selectedValue} />
          <Form.Control.Feedback type="invalid">
            {`O campo field é de preenchimento obrigatório`}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    ) : (
      <>
        <Form.Group
          controlId={`formText-field`}
          style={{ margin: nomargin ? "0" : "" }}
        >
          <div
            className={`searchable-input-search-wrapper `}
            style={{ fontSize: `${fontSize}px` }}
          >
            <div className="searchable-input-label">{text}</div>
            <div
              className={`searchable-input-search-field-wrapper ${
                disabled ? "input-searchable-disabled" : ""
              } ${noBorderRadius ? "table-border-radius" : ""} ${
                styleSheet != undefined && styleSheet.ultraCompact
                  ? "ultracompactForm"
                  : ""
              }`}
              tabIndex="1"
            >
              <input
                autoComplete="off"
                onFocus={(e) => {
                  setIcon(iconSearchFocus);
                  e.target.select();
                }}
                disabled={disabled}
                className={`searchable-input-search-field`}
                placeholder={`${placeholder}`}
                id={`formInput-${id.current}`}
                style={{ fontSize: `${fontSize}px` }}
                onMouseDown={() => {
                  toggleList();
                }}
                onKeyUp={(e) => typeFunction(e)}
                value={label}
                required={required}
                onBlur={(e) => {
                  outOfFocus(e);
                  setIcon(iconSearchDefault);
                }}
                size={label?.length || 10}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
                name={name + "Label"}
              />
              <div className="searchable-input-search-icon-wrapper">
                <img
                  className="searchable-input-search-icon"
                  src={icon}
                  alt="search-icon"
                />
              </div>
            </div>
  
            <div
              id={`dropDown-${id.current}`}
              className="searchable-dropdown"
              ref={outerListRef}
            >
              {displayList.map((option, i) => {
                return option?.label === t("general.dataNotFound") ? (
                  <option id="searchable-dropdown-no-result" key={i}>
                    {t("general.dataNotFound")}
                  </option>
                ) : i === 0 ? (
                  <div
                    key={i}
                    className={
                      "searchable-dropdown-option-wrapper searchable-option-selected"
                    }
                  >
                    {countryList && (
                      <option
                        value={option.value}
                        className={`searchable-country-flag-option searchable-option-selected searchable-${option.value}`}
                        onMouseDown={(e) => handleSelect(e)}
                        key={i}
                        style={{ fontSize: `${fontSize}px` }}
                      />
                    )}
                    <option
                      id={`selected-${id.current}`}
                      key={i}
                      onMouseDown={(e) => {
                        handleSelect(e);
                        setScrollOffset(0);
                        outerListRef.current &&
                          outerListRef.current.scrollTo({
                            left: 0,
                            top: scrollOffset,
                            behavior: "auto",
                          });
                        setCursor(0);
                      }}
                      onMouseEnter={() => {
                        setHovered(option);
                        setCursor(i);
                      }}
                      onMouseLeave={() => {
                        setHovered(undefined);
                        setCursor(0);
                      }}
                      value={option?.value}
                      style={{ fontSize: `${fontSize}px` }}
                      className={`searchable-dropdown-option searchable-option-selected ${
                        countryList ? "searchable-country-option" : ""
                      } ${
                        styleSheet != undefined && styleSheet.ultraCompact
                          ? "ultracompactDropdownOpt"
                          : ""
                      }`}
                    >
                      {option?.label}
                    </option>
                  </div>
                ) : (
                  <div className={"searchable-dropdown-option-wrapper"} key={i}>
                    {countryList && (
                      <option
                        key={i}
                        value={option.value}
                        className={`searchable-country-flag-option searchable-${option.value}`}
                        onMouseDown={(e) => handleSelect(e)}
                        style={{ fontSize: `${fontSize}px` }}
                      />
                    )}
  
                    <option
                      className={`searchable-dropdown-option ${
                        i === cursor ? "currentHover" : ""
                      } ${countryList ? "searchable-country-option" : ""} ${
                        styleSheet != undefined && styleSheet.ultraCompact
                          ? "ultracompactDropdownOpt"
                          : ""
                      }`}
                      key={i}
                      style={{ fontSize: `${fontSize}px` }}
                      onMouseDown={(e) => handleSelect(e)}
                      onMouseEnter={() => {
                        setHovered(option);
                      }}
                      onMouseLeave={() => setHovered(undefined)}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  </div>
                );
              })}
            </div>
          </div>
          <input type="hidden" name={name} value={selectedValue} />
          <Form.Control.Feedback type="invalid">
            {`O campo field é de preenchimento obrigatório`}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    )}
    </>
  );
};
export default withNamespaces()(SearchableDropDown);
