import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Modal } from "react-bootstrap";
import DocTemplate from "./DocTemplate";
import DateTemplate from "./DateTemplate";
import { errorMessageVariable } from "~/store/ducks/udw/actionTypes";

const UpdateModal = (props) => {
  const dispatch = useDispatch();
  const {
    //General modal funcionalities
    createMessageVariableButtons,
    setFocus,
    //Specific modal functionalities
    selectedEmailTemplate,
    showUpdateModal,
    closeUpdateModal,
    //Email template data
    operations,
    selectedOperationId,
    setSelectedDocTypeId,
    subject,
    setSubject,
    message,
    setMessage,
    //Validation Funcionalities
    handleDropdownClassName,
    handleSubmit,
    handleSetSelectedOperationId,
    //validation data
    validated,
    operationError,
    docTypeError,
    //Reset docType dropdown when needed
    resetDocTypeDropdown,
    setResetDocTypeDropdown,
    //track cursor position in textareas
    subjectRef,
    messageRef,
    periodicidadeListDropdown,
    handlePeriodicidadeSelect,
    periodicidade,
    fromTemplate,
    periodicidadeError,
    fromTemplateError,
    datasListDropdown,
    handleDatasSelect,
    datas,
    datasError,
    numberDays,
    setNumberDays,
    resetDropdown,
    setResetDropdown,
    resetDropdownPeriodicidade,
    setResetDropdownPeriodicidade,
    resetValuesDataTemplate,
    dateOwnSubjectRef,
    dateOwnMessageRef,
    dateOthersSubjectRef,
    dateOthersMessageRef,
    subjectOwn,
    messageOwn,
    subjectOthers,
    messageOthers,
    setSubjectOwn,
    setMessageOwn,
    setSubjectOthers,
    setMessageOthers,
    dateForPeriodicidade,
    setDateForPeriodicidade,
    createDateMessageVariableButtons,
    notificationNot,
    emailNotification,
    handleNotificationType,
    regraListDropdown,
    regraError,
    handleRegraSelect,
    resetDropdownRegra,
    setResetDropdownRegra,
    contextValue,
    regra,
    t,
  } = props;

  const asterisk = <i style={{ color: "#dc3545" }}>*</i>;

  return (
    <>
      <Modal show={showUpdateModal} onHide={closeUpdateModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="default-modal-title">
            {t("portalrh.emailTemplate.templateID") +
              " " +
              selectedEmailTemplate?.id}
          </Modal.Title>
        </Modal.Header>
        {contextValue === "1" ? (
          <DocTemplate
            createMessageVariableButtons={createMessageVariableButtons}
            setFocus={setFocus}
            showCreateModal={showUpdateModal}
            closeCreateModal={closeUpdateModal}
            operations={operations}
            selectedOperationId={selectedOperationId}
            setSelectedDocTypeId={setSelectedDocTypeId}
            subject={subject}
            setSubject={setSubject}
            message={message}
            setMessage={setMessage}
            handleDropdownClassName={handleDropdownClassName}
            handleSubmit={handleSubmit}
            handleSetSelectedOperationId={handleSetSelectedOperationId}
            validated={validated}
            operationError={operationError}
            docTypeError={docTypeError}
            resetDocTypeDropdown={resetDocTypeDropdown}
            setResetDocTypeDropdown={setResetDocTypeDropdown}
            subjectRef={subjectRef}
            messageRef={messageRef}
            asterisk={asterisk}
            selectedEmailTemplate={selectedEmailTemplate}
          />
        ) : (
          <DateTemplate
            handleSubmit={handleSubmit}
            createDateMessageVariableButtons={createDateMessageVariableButtons}
            setFocus={setFocus}
            closeCreateModal={closeUpdateModal}
            handleDropdownClassName={handleDropdownClassName}
            validated={validated}
            subjectRef={subjectRef}
            messageRef={messageRef}
            periodicidadeListDropdown={periodicidadeListDropdown}
            handlePeriodicidadeSelect={handlePeriodicidadeSelect}
            periodicidade={periodicidade}
            periodicidadeError={periodicidadeError}
            datasListDropdown={datasListDropdown}
            handleDatasSelect={handleDatasSelect}
            datas={datas}
            datasError={datasError}
            numberDays={numberDays}
            setNumberDays={setNumberDays}
            resetDropdownPeriodicidade={resetDropdownPeriodicidade}
            setResetDropdownPeriodicidade={setResetDropdownPeriodicidade}
            dateOwnSubjectRef={dateOwnSubjectRef}
            dateOwnMessageRef={dateOwnMessageRef}
            dateOthersSubjectRef={dateOthersSubjectRef}
            dateOthersMessageRef={dateOthersMessageRef}
            subjectOwn={subjectOwn}
            messageOwn={messageOwn}
            subjectOthers={subjectOthers}
            messageOthers={messageOthers}
            setSubjectOwn={setSubjectOwn}
            setMessageOwn={setMessageOwn}
            setSubjectOthers={setSubjectOthers}
            setMessageOthers={setMessageOthers}
            dateForPeriodicidade={dateForPeriodicidade}
            setDateForPeriodicidade={setDateForPeriodicidade}
            asterisk={asterisk}
            selectedEmailTemplate={selectedEmailTemplate}
            notificationNot={notificationNot}
            emailNotification={emailNotification}
            handleNotificationType={handleNotificationType}
            regraListDropdown={regraListDropdown}
            regraError={regraError}
            handleRegraSelect={handleRegraSelect}
            resetDropdownRegra={resetDropdownRegra}
            setResetDropdownRegra={setResetDropdownRegra}
            regra={regra}
          />
        )}
      </Modal>
    </>
  );
};
export default withNamespaces()(UpdateModal);
